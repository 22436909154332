import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ContainerScroll } from '../../components/layout/ContainerScroll';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelpItemListCard } from '../../dashboard/containers/HelpItemListCard';
import { HelpItemListCardsContainer } from '../../dashboard/containers/HelpItemListCardsContainer';
import SchemaHelper from '../SchemaHelper';
import { ImportSelectPanel } from '../panels/ImportSelectPanel';
import { getLayoutSchema } from './schema/layoutSchema';

const ImportLayoutStartView: React.FC = () => {
  const { t } = useTranslation('importer');
  const importTemplatePath = '/importer/csv';
  const importTemplateVersion = 'v24.04.001';
  const importTemplateDataType = 'layout';
  const importTemplateNameMetric = `WareBee-WAREHOUSE_NAME-${importTemplateDataType}-${importTemplateVersion}-TEMPLATE`;

  return (
    <ContainerCol overflow>
      <ContainerScroll>
        <ScreenTitle
          title={t`Layout Import`}
          subtitle={t`Map fields`}
          isSticky
          truncate
        />
        <HelperMessage hasMargin hasPadding>
          <HelpItemListCardsContainer>
            <HelpItemListCard className="flex-1" transparent>
              <HelperMessage isLead textSize={'lead'}>
                {t`We need the following data`}
              </HelperMessage>
              <HelperMessage hasBefore textSize={'hero'}>
                {t`We will only ask you for a minimal set of data to build the warehouse model. Providing all the optional fields will allow WareBee to create a more precise digital twin model.`}
              </HelperMessage>
              <ImportSelectPanel
                className="mt-8"
                titleDataset={t`Locations`}
                linkPath={`${importTemplatePath}/${importTemplateNameMetric}.zip`}
              />
            </HelpItemListCard>
            <HelperMessage className="flex-1" hasPadding>
              <HelperMessage isLead className="mt-2 pt-4" textSize={'xl'}>
                {t`Field Guide (Schema)`}
              </HelperMessage>
              <SchemaHelper schema={getLayoutSchema(t)} />
            </HelperMessage>
          </HelpItemListCardsContainer>
        </HelperMessage>
      </ContainerScroll>
    </ContainerCol>
  );
};

export default ImportLayoutStartView;
