import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import { SelectQueryBuilder } from 'kysely';
import { atom, selector } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  getSimulationHqQueryBuilder,
  SimulationHqDataColumn,
  SimulationHqDataRows,
  SimulationHqQueryBuilderParams,
} from '../datasetQueries/simulationHqQueryBuilder';
import { simulationCurrent } from './simulation.state';

const getKey = (postfix: string) => `warebee-simulation-hq-${postfix}`;

export const simulationHqBuilderParams =
  selector<SimulationHqQueryBuilderParams>({
    key: getKey('query-builder-params'),
    get: ({ get }) => {
      const sim = get(simulationCurrent);
      return {
        layout: {
          datasetId: sim?.layout?.id ?? 'dummy',
        },
        assignment: {
          datasetId: sim?.assignment?.id ?? 'dummy',
        },
        itemSet: {
          datasetId: sim?.itemSet?.id ?? 'dummy',
        },
        orderSet: {
          datasetId: sim?.orderSet?.id ?? 'dummy',
        },
        // filterPreset: get(
        //   filterPresetSelected,
        // ) as FilterPreset<ActualityHqDataColumn>,
        // filterConfig: get(simulationEffectiveFilterConfig),
      };
    },
  });

export const simulationHqQueryBuilderBase = selector<{
  b: SelectQueryBuilder<any, any, any>;
}>({
  key: getKey('query-builder-base'),
  get: ({ get }) => {
    const queryParamsBase = get(simulationHqBuilderParams);
    const builder =
      getSimulationHqQueryBuilder(queryParamsBase).selectFrom('hq');
    return { b: builder };
  },
});

export const simulationHqTableData = atom<SimulationHqDataRows>({
  key: getKey('hq-table-data'),
  default: [],
});

export const simulationHqTableTotalCount = atom<number>({
  key: getKey('hq-table-total-count'),
  default: 0,
});

export const simulationHqTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('hq-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const simulationHqTableDataState = atom<
  DataTableState<SimulationHqDataColumn>
>({
  key: getKey('hq-table-data-state'),
  default: {
    sortValues: {
      sku: SortDirection.DESC,
    },
    searchValues: {},
  },
});
