import {
  AppBillingProductCategory,
  AppBillingProductFragment,
  AppBillingProductPriceFragment,
  WarehouseSize,
} from '@warebee/frontend/app-billing-graphql-api';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatCurrencyBest } from '../../common/formatHelper';
import { Button } from '../../components/actions/Button';
import { MailTo } from '../../components/actions/MailTo';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';
import i18n from '../../i18n';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  AppBillingPricingCardsCategoryMode,
  AppBillingPricingCardsLinkMode,
  AppBillingPricingCardsPriceMode,
  AppBillingPricingCardsViewMode,
} from '../AppBillingPricingCards';
import {
  convertStripeAmount,
  getProductTitle,
} from '../store/appBilling.helper';
import { FeatureListPreset } from './AppBillingFeaturesList';
import AppBillingOneTimePriceContent from './AppBillingOneTimePriceContent';
import AppBillingPriceCardContainer from './AppBillingPriceCardContainer';
import AppBillingPriceCardDescription, {
  DisplayPreset,
} from './AppBillingPriceCardDescription';
import AppBillingPriceCardFeaturesList from './AppBillingPriceCardFeaturesList';
import AppBillingSubscriptionPriceContent from './AppBillingSubscriptionPriceContent';

export type AppBillingPriceCardProps = {
  product: AppBillingProductFragment;
  price: AppBillingProductPriceFragment;
  isSelected?: boolean;
  hasCardClickEvent?: boolean;
  purchaseOnContinue?: boolean;
  modePreview?: AppBillingPricingCardsViewMode;
  modeCategory?: AppBillingPricingCardsCategoryMode;
  modePrice?: AppBillingPricingCardsPriceMode;
  modeLink?: AppBillingPricingCardsLinkMode;
  featurePreset: FeatureListPreset;
  displayPreset: DisplayPreset;
  size?: WarehouseSize;
};

const AppBillingPriceCard: React.FC<AppBillingPriceCardProps> = props => {
  const whId = useRecoilValue(warehouseSelectedId);
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const { product, price, size } = props;

  const title = getProductTitle(
    product.operationType,
    product.productCategory,
    t,
  );

  const subtitle =
    product.productCategory === AppBillingProductCategory.SUBSCRIPTION
      ? t`Subscribe to`
      : t`Get`;

  const productCategory = product.productCategory;
  const productType = product.operationType;

  const pricingUrl = `/wh/i/${whId}/pricing/${price.id}`;
  const purchaseUrl = `/wh/i/${whId}/purchase/${price.id}`;
  const publicUrl = `https://warebee.com/contact/?price=${price.id}?cat=${productCategory}?type=${productType}?s=${size}`;

  function onCardClick() {
    switch (props.modePreview) {
      case 'FULL':
        navigate(pricingUrl);
        return;
      case 'PREVIEW':
        return;
      // case 'public':
      //   return;
      // case 'public-no-price':
      //   return;
      // case 'public-subscription':
      //   return;
      // case 'public-subscription-no-price':
      //   return;
      // case 'public-bundle':
      //   return;
      // case 'public-bundle-no-price':
      //   return;
    }
  }

  function onButtonClick(modePreview: string, modePrice: string) {
    switch (modePrice) {
      case 'PRICING_HIDE':
        window.open(publicUrl, '_blank').focus();
        return;
    }

    switch (modePreview) {
      case 'FULL':
        navigate(purchaseUrl);
        return;
      case 'PREVIEW':
        navigate(pricingUrl);
        return;
    }
  }

  const priceWithUnit = formatCurrencyBest(
    convertStripeAmount(price.unitAmount),
    price.currency,
  );

  return (
    <AppBillingPriceCardContainer
      subtitle={subtitle}
      title={title}
      isSelected={props.isSelected}
      onCardClick={props.modeLink === 'APP' ? onCardClick : null}
      onButtonClick={
        props.modeLink === 'APP'
          ? () => onButtonClick(props.modePreview, props.modePrice)
          : () => window.open(publicUrl, '_blank').focus()
      }
      modePreview={props.modePreview}
      modePrice={props.modePrice}
      modeCategory={props.modeCategory}
      modeLink={props.modeLink}
      header={
        props.modePrice === 'PRICING_SHOW' ? (
          <>
            {product.productCategory === AppBillingProductCategory.BUNDLE && (
              <AppBillingOneTimePriceContent
                price={priceWithUnit}
                // description={product.description}
                // featurePreset={props.featurePreset}
                operationType={product.operationType}
                operationCount={product.operationCount}
              />
            )}
            {product.productCategory ===
              AppBillingProductCategory.SUBSCRIPTION && (
              <AppBillingSubscriptionPriceContent
                price={priceWithUnit}
                billingInterval={price.subscriptionDetails?.interval}
                // description={product.description}
                // featurePreset={props.featurePreset}
                operationType={product.operationType}
              />
            )}
          </>
        ) : (
          // props.modePrice === 'PRICING_HIDE'
          <Button
            data-component={`mode-${props.modePrice}`}
            className={classNames(
              'relative mb-0 mt-8 rounded-full px-2 py-4 shadow-2xl',
            )}
            label={t`Talk to an expert`}
            // buttonType="primary"
            buttonType="purchase"
            buttonSize="xxl"
            isDisabled={false}
            full
            // hasIconAfter
            onPress={() => onButtonClick(props.modePreview, props.modePrice)}
            hasIconBefore={
              <span
                className={classNames(
                  'rounded-full p-0',
                  'absolute ltr:left-0',
                  'm-0.5 mx-1',
                  'h-12 w-12',
                  'border-app-panel-dark text-menu-text border-2',
                  'bg-app-panel-dark/60 text-menu-active',
                  'hover:scale-120 transform cursor-pointer transition duration-200 motion-reduce:transform-none',
                )}
              >
                <img
                  alt={t`Chat with us`}
                  className={`object-contain`}
                  src="/theme/img/chat-profile-photo.png"
                ></img>
              </span>
            }
            hasIconAfter={
              i18n.dir(i18n.language) === 'ltr' ? (
                <Icon.CircleArrowRight
                  className={classNames('mx-1 h-8 w-8 fill-current')}
                />
              ) : (
                <Icon.CircleArrowLeft
                  className={classNames('mx-1 h-8 w-8 fill-current')}
                />
              )
            }
          />
        )
      }
      content={
        <>
          <section data-component="pricingCardContent" className="flex-1">
            <AppBillingPriceCardDescription
              key={product.id}
              operationType={product.operationType}
              category={product.productCategory}
              description={product.description}
              displayPreset={props.displayPreset}
            />
            {props.displayPreset === 'short' && (
              <span
                className={classNames(
                  'flex items-center text-base ltr:pl-2 rtl:pr-2',
                )}
              >
                {t`Learn More`}
                <Icon.ArrowRight
                  className={classNames(
                    'h-3 w-3',
                    'fill-current',
                    'ltr:ml-2 rtl:mr-2',
                  )}
                />
              </span>
            )}
            <AppBillingPriceCardFeaturesList
              isSelected={props.isSelected}
              key={
                'AppBillingPriceCardFeaturesList-' + product.id + '-' + price.id
              }
              featurePreset={props.featurePreset}
              operationType={product.operationType}
              billingInterval={price.subscriptionDetails?.interval}
            />
            {props.displayPreset === 'short' && (
              <span
                className={classNames(
                  'flex items-center',
                  'text-base',
                  'ltr:pl-12 rtl:pr-12',
                )}
              >
                {t`Learn More`}
                <Icon.ArrowRight
                  className={classNames(
                    'h-3 w-3',
                    'fill-current',
                    'ltr:ml-2 rtl:mr-2',
                  )}
                />
              </span>
            )}
          </section>
          {props.displayPreset === 'full' && (
            <>
              <Spacer flexspace />
              <footer
                data-component="AppBillingPriceCardHelper"
                className={classNames(
                  'p-4',
                  props.isSelected
                    ? 'text-menu-active-text border-menu border-t'
                    : 'text-menu-active border-menu-active border-t',
                )}
              >
                <Icon.HelpBot className={classNames('h-8 w-8 fill-current')} />
                <div className={classNames('mt-4')}>
                  <span
                  // className={classNames(
                  //   props.isSelected ? 'text-menu-text' : 'text-menu-text/75',
                  // )}
                  >{t`Not sure what is the right subscription for your Warehouse?, Let's chat`}</span>
                  <MailTo
                    email="sales@warebee.com"
                    subject={t`[WAREBEE] Subscription`}
                    body={t`Hello, I need help with purchasing`}
                  >
                    <div>{t`sales@warebee.com`}</div>
                  </MailTo>
                </div>
              </footer>
            </>
          )}
        </>
      }
    />
  );
};

export default AppBillingPriceCard;
