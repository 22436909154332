import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  Filter,
  FilterBase,
  FilterIntersection,
  FilterUnion,
} from './filter.model';

export enum LocationFilterType {
  PLANE = 'PLANE',
  AREA = 'AREA',
  AISLE = 'AISLE',
  BAY = 'BAY',
  RACKING_TYPE = 'RACKING_TYPE',
  LEVEL = 'LEVEL',
  LOCATION = 'LOCATION',
  BAY_LOCATION_ORDER = 'BAY_LOCATION_ORDER',
  SIDE = 'SIDE',
  ACCESS_AISLE = 'ACCESS_AISLE',
  USAGE_TYPE = 'USAGE_TYPE',
  CONGESTION_ZONE = 'CONGESTION_ZONE',
  // LOCATION_WIDTH = 'LOCATION_WIDTH',
  HEIGHT_FROM_FLOOR = 'HEIGHT_FROM_FLOOR',
  MHTYPE = 'MHTYPE',
  DEPTH_POSITION = 'DEPTH_POSITION',
  INDEX_FROM_FRONT = 'INDEX_FROM_FRONT',
  DEPTH_FROM_FRONT = 'DEPTH_FROM_FRONT',
  BAY_TYPE = 'BAY_TYPE',
  PORTAL_X = 'PORTAL_X',
  PORTAL_Y = 'PORTAL_Y',
}

registerEnumType(LocationFilterType, {
  name: 'LocationFilterType',
});

@InputAndObjectType()
export class LocationFilter
  extends FilterBase
  implements Filter<LocationFilterType>
{
  @Field(() => LocationFilterType)
  type: LocationFilterType;
}

@InputAndObjectType()
export class LocationFilterIntersection
  implements FilterIntersection<LocationFilter>
{
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field(() => [LocationFilter])
  allOf: LocationFilter[];
}

@InputAndObjectType()
export class LocationFilterUnion implements FilterUnion<LocationFilter> {
  @Field(() => [LocationFilterIntersection])
  anyOf: LocationFilterIntersection[];
}
