import {
  SkipLimitPageSpec,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import {
  getSimulationHqQueryBuilder,
  SimulationHqQueryBuilderParams,
  SimulationHqSortBy,
} from './simulationHqQueryBuilder';

export type SimulationHqQueryBuilderPagedClientParams = {
  page?: SkipLimitPageSpec;
  sortBy?: SimulationHqSortBy[];
};

export type SimulationHqQueryBuilderPagedParams =
  SimulationHqQueryBuilderParams & SimulationHqQueryBuilderPagedClientParams;

export function getSimulationHqDataRowsQuery(
  params: SimulationHqQueryBuilderPagedParams,
) {
  let query = getSimulationHqQueryBuilder(params)
    .selectFrom('hq')
    .selectAll()
    .offset(params.page.skip ?? 0)
    .limit(params.page.limit ?? 100);

  _.forEach(params.sortBy, sb => {
    query = query.orderBy(
      sb.field as any,
      sb.direction === SortDirection.ASC ? 'asc' : 'desc',
    );
  });
  return query;
}

export function getSimulationHqDataTotalQuery(
  params: SimulationHqQueryBuilderPagedParams,
) {
  let query = getSimulationHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) => [fn.countAll<number>().as('totalCount')]);

  return query;
}
