// const colorBrandPrimary = 'hsl(60, 100%, 60%)';

const themeWareBeeDark = {
  extend: {
    screens: {
      xs: '475px',
      mobile: { max: '640px' },
      tablet: { min: '640px', max: '767px' },
      laptop: { min: '768px', max: '1023px' },
      desktop: { min: '1024px' },
      /* One or more available input mechanism(s)
        can hover over elements with ease */
      'any-hover': {
        raw: '(any-hover: hover)',
      },
      /* One or more available input mechanism(s) can hover,
         but not easily (e.g., many mobile devices emulate
         hovering when the user performs a long tap) */
      'any-hover-on-demand': {
        raw: '(any-hover: on-demand)',
      },
      /* One or more available input mechanism(s) cannot hover
        (or there are no pointing input mechanisms) */
      'any-hover-none': {
        raw: '(any-hover: none)',
      },
      /* At least one input mechanism of the device
         includes a pointing device of limited accuracy. */
      'any-pointer-coarse': {
        raw: '(any-pointer: coarse)',
      },
      /* At least one input mechanism of the devi
         includes an accurate pointing device. */
      'any-pointer-fine': {
        raw: '(any-pointer: fine)',
      },
      /* The device does not include any pointing device. */
      'any-pointer-none': {
        raw: '(any-pointer: none)',
      },
      /* Primary input mechanism system can hover over elements with ease */
      'hover-hover': {
        raw: '(hover: hover)',
      },
      /* hover-none */
      'hover-none': { raw: '(hover: none)' },
      /* The primary input mechanism of the device includes a pointing device of limited accuracy. */
      'pointer-coarse': {
        raw: '(pointer: coarse)',
      },
      /* The primary input mechanism of the device includes an accurate pointing device. */
      'pointer-fine': {
        raw: '(pointer: fine)',
      },
      /* The primary input mechanism of the device does not include a pointing device. */
      'pointer-none': {
        raw: '(pointer: none)',
      },
    },
    backgroundColor: ['active'],
    fontSize: {
      lg: ['1.125rem', 'normal'],
      sm: ['.875rem', '1rem'],
      xs: ['.75rem', 'normal'],
      xxs: ['.67rem', 'normal'],
      minimal: ['.6rem', 'normal'],
    },
    colors: {
      brand: {
        DEFAULT: 'hsl(60, 100%, 60%)', //'hsl(57, 100%, 49%)', // #fced00, rgba(252, 237, 0, 1)
        warebee: 'hsl(60, 100%, 60%)', //'hsl(57, 100%, 49%)', // #fced00, rgba(252, 237, 0, 1)
        draggable: 'hsla(48, 100%, 50%, 0.6)', // #ffce00, rgba(255,206,0,0.6)
        light: 'hsl(48, 100%, 73%)', // #FFE67A, rgba(255, 230, 122, 1)
        hover: 'hsl(65, 100%, 49%)', // #ecff00, rgba(236, 255, 0, 1)
        primary: 'hsl(57, 100%, 49%)', // #fced00, rgba(252, 237, 0, 1)
        active: 'hsl(65, 100%, 49%)', // #ecff00, rgba(236, 255, 0, 1)
        active_start: 'hsl(65, 100%, 49%)', // #ecff00, rgba(236, 255, 0, 1)
        active_end: 'hsl(151, 100%, 49%)', // #00ff8b, rgba(0, 255, 139, 1)
        50: 'hsl(48, 100%, 89%)', // #FFF1B8, rgba(255, 241, 184, 1)
        100: 'hsl(48, 100%, 85%)', // #FFEDA3, rgba(255, 237, 163, 1)
        200: 'hsl(48, 100%, 73%)', // #FFE67A, rgba(255, 230, 122, 1)
        300: 'hsl(49, 100%, 62%)', // #FFDE52, rgba(255, 222, 82, 1)
        400: 'hsl(50, 100%, 52%)', // #FFD629, rgba(255, 214, 41, 1)
        500: 'hsl(57, 100%, 49%)', // #fced00, rgba(252, 237, 0, 1)
        600: 'hsl(51, 100%, 39%)', // #C7A100, rgba(199, 161, 0, 1)
        700: 'hsl(51, 100%, 28%)', // #8F7300, rgba(143, 115, 0, 1)
        800: 'hsl(51, 100%, 17%)', // #574600, rgba(87, 70, 0, 1)
        900: 'hsl(50, 100%, 6%)', // #1F1900, rgba(31, 25, 0, 1)
        accent: 'hsl(208, 100%, 41%)', // #0074d4, rgba(0, 116, 212, 1)
        // blue: 'hsl(228, 100%, 29%)', // #00308E, rgba(0, 48, 142, 1)
        chat: 'hsla(210, 100%, 57%, 0.85)', // #2295ff, rgba(34,149,255,0.85)
        purchase: 'hsl(225, 100%, 35%)', // #003cb2, rgba(0, 60, 178,1)
        cctv: 'hsl(193, 100%, 52%)', // #00b8d3, rgba(0, 184, 211, 1)
      },
      app: {
        DEFAULT: 'hsl(60, 100%, 60%)',
        header: 'rgb(29,31,34)',
        // header: 'hsla(206, 9%, 18%, 1)',
        menu: 'hsla(205, 10%, 23%, 1)',
        'header-menu': 'hsl(205, 15%, 30%)',
        'header-text': 'hsl(210, 100%, 99%)',
        'header-divide': 'hsl(209, 17%, 25%)',
        background: 'rgb(29,31,34)',
        main: 'hsla(205, 10%, 23%, 1)',
        card: 'hsla(206, 10%, 25%, 1)',
        input: 'hsla(210, 9%, 8%, 1)',
        panel: 'hsla(203, 10%, 34%, 1)',
        'panel-text': 'hsla(210, 100%, 97%,1)',
        'panel-light': 'hsla(203, 65%, 90%, 1)',
        'panel-map': 'hsla(212, 23%, 84%, 1)',
        'panel-map-area': 'hsla(212, 23%, 84%, 1)',
        'panel-map-bay': 'hsla(206, 9%, 18%, 1)',
        'panel-light-text': 'hsla(210, 100%, 97%,1)',
        'panel-dark': 'hsla(205, 10%, 8%, 1)',
        'panel-dark-text': 'hsla(212, 25%, 90%, 1))',
        'panel-stat': 'hsla(206, 9%, 18%, 1)',
        'policy-well': 'hsla(210, 16%, 6%,1)',
        stat: 'hsla(203, 10%, 34%, 1)',
        'card-title': 'hsla(210, 100%, 97%,1)',
        title: 'hsla(210, 100%, 97%,1)',
        section: 'hsla(206, 9%, 18%, 1)',
      },
      sidebar: {
        DEFAULT: 'hsl(57, 100%, 49%)',
        menu: 'rgb(29,31,34)',
        button: '#d4dfdf',
        'button-text': 'hsl(210, 25%, 94%)',
        main: 'hsla(205, 10%, 13%, 1)',
        // main: 'hsla(206, 9%, 18%, 1)',
        toolbar: 'hsla(203, 10%, 34%, 1)',
        header: 'hsla(203, 10%, 34%, 1)',
        title: 'hsla(206, 9%, 18%, 1)', // 'hsla(205, 10%, 26%, 1)',
        footer: 'hsla(206, 9%, 18%, 1)',
        'header-text': 'hsl(60, 100%, 60%)',
      },
      menu: {
        DEFAULT: 'hsla(205, 10%, 23%, 1)',
        disabled: 'hsla(195, 10%, 24%,1)',
        'disabled-text': 'hsla(195, 10%, 50%, 1)',
        // action: 'hsl(208, 100%, 41%)',
        action: 'hsl(201, 100%, 79%)',
        active: 'hsla(60, 100%, 60%, 1)',
        'active-text': 'hsl(0, 0%, 7%)',
        'active-chat': 'hsl(208, 100%, 41%)',
        hover: 'hsla(60, 60% ,50%,1)',
        // hover: 'hsl(60, 100%, 80%)',
        inverse: 'hsl(0, 0%, 100%)',
        header: 'hsl(60, 100%, 60%)',
        button: 'hsla(202, 9%, 67%, 1)',
        secondary: 'hsla(203, 10%, 34%, 1)',
        delete: 'hsl(0, 100%, 50%)',

        title: 'hsl(60, 100%, 60%)',
        h1: 'hsl(60, 100%, 60%)',
        h2: 'hsl(60, 100%, 60%)',
        h3: 'hsla(210, 100%, 97%,0.75)',
        h4: 'hsla(210, 100%, 97%,0.65)',
        h5: 'hsla(210, 100%, 97%,0.5)',
        h6: 'hsla(210, 100%, 97%,1)',

        icon: 'hsla(210, 100%, 97%,1)',
        text: 'hsl(0, 0%, 100%)',
        stat: 'hsla(210, 100%, 97%,1)',
        app: '#24303a',
        // app: 'hsla(220, 8%, 35%, 1)',

        'level-0': 'hsl(198, 10%, 36%)',
        'level-1': 'hsl(196, 11%, 24%)',
        'level-2': 'hsl(196, 8%, 17%)',
        'level-3': 'hsl(196, 7%, 14%)',
        'level-4': 'hsl(196, 6%, 12%)',
        'level-5': 'hsl(196, 8%, 18%)',

        50: 'hsla(205, 10%, 72%, 1)',
        100: 'hsla(202, 9%, 67%, 1)',
        200: 'hsla(204, 9%, 56%, 1)',
        300: 'hsla(204, 10%, 45%, 1)',
        400: 'hsla(203, 10%, 34%, 1)',
        500: 'hsla(205, 10%, 23%, 1)',
        600: 'hsla(206, 9%, 18%, 1)',
        700: 'hsla(205, 10%, 13%, 1)',
        800: 'hsla(210, 9%, 8%, 1)',
        900: 'hsla(210, 11%, 3%, 1)',
      },
      table: {
        header: 'hsla(203, 10%, 34%, 1)',
        // header: 'hsla(210, 20%, 85%, 1)',
        'header-text': 'hsla(210, 20%, 85%, 1)',
        row: 'hsla(206, 9%, 18%, 1)',
        'row-text': 'hsla(210, 20%, 85%, 1)',
        footer: 'hsla(203, 10%, 34%, 1)',
        'footer-text': 'hsla(210, 20%, 85%, 1)',
      },
      chart: {
        ticks: 'hsla(210, 20%, 85%, 1)',
        legend: 'hsla(210, 20%, 85%, 1)',
        grid: 'hsla(203, 10%, 34%, 1)',
        empty: 'hsla(203, 10%, 34%, 0.5)',
        nodata: 'hsla(203, 10%, 14%, 0.5)',
        crosshair: 'hsla(204, 9%, 56%, 1)',
        chart: 'hsla(210, 20%, 85%, 1)',
        text: 'hsla(210, 20%, 85%, 1)',
      },
      text: {
        light: 'hsl(0, 0%, 100%)',
        dark: 'hsla(210, 9%, 8%, 1)',
      },
      scrollbar: {
        thumb: 'hsla(204, 9%, 56%, 1)',
        track: 'hsla(0, 0%, 0%, 1)',
        active: 'hsla(60, 100%, 60%, 1)',
        hover: 'hsla(60, 100%, 60%, 1)',
      },
      timeline: {
        even: 'hsla(203, 10%, 54%, 0.4)',
        odd: 'hsla(203, 10%, 34%, 0.5)',
        hover: 'hsla(60, 100%, 50%, 0.8)',
        selected: 'hsla(60, 100%, 50%, 0.9)',
        selectedBorder: 'hsl(60, 100%, 50%)',
        inactive: 'hsl(0, 0%, 0%)',
        inactiveText: 'hsl(0, 0%, 100%)',
        label: 'hsla(0, 0%, 100%, 0.7)',
        unknown: 'hsla(204, 9%, 56%, 1)',
        underperform: 'hsl(328, 100%, 42%)',
        overperform: 'hsl(106, 100%, 52%)',
        shift: 'hsla(203, 10%, 24%, 0.9)',
      },

      // menu: {
      //   DEFAULT: '#363D42',
      //   white: '#FFFFFF',
      //   sub: '#4F5A61',
      //   text: '#FFFFFF',
      //   50: 'hsla(205, 10%, 72%, 1)',
      //   100: 'hsla(202, 9%, 67%, 1)',
      //   200: 'hsla(204, 9%, 56%, 1)',
      //   300: 'hsla(204, 10%, 45%, 1)',
      //   400: 'hsla(203, 10%, 34%, 1)',
      //   500: 'hsla(205, 10%, 23%, 1)',
      //   600: 'hsla(206, 9%, 18%, 1)',
      //   700: 'hsla(205, 10%, 13%, 1)',
      //   800: 'hsla(210, 9%, 8%, 1)',
      //   900: 'hsla(210, 11%, 3%, 1)',
      //   // Menu Levels
      //   level0: 'hsl(198, 10%, 36%)', // #4d575c, rgb(77, 87, 92)
      //   level1: 'hsl(196, 11%, 24%)', // #394246, rgb(57, 66, 70)
      //   level2: 'hsl(196, 8%, 17%)', // #2b3034, rgb(43, 48, 52)
      //   level3: 'hsl(196, 7%, 14%)', // #22272a, rgb(34, 39, 42)
      //   level4: 'hsl(196, 6%, 12%)', // #1c2022, rgb(28, 32, 34)
      //   level5: 'hsl(196, 8%, 18%)', // #272D2F, rgb(39, 45, 47)
      //   // States
      //   disabled: 'hsl(196, 10%, 24%)', // #394246, rgb(57, 66, 70)
      //   delete: 'hsl(0, 100%, 50%)', // #ff0b0b, rgb(255, 11, 11)
      //   active: 'hsl(62, 100%, 50%)',
      //   hover: 'hsl(60, 100%, 60%)', // #FFDE52, rgb(255, 222, 82)
      //   app: '#1D1F22', // #1F2326, rgb(31, 35, 38)
      // },
      'menu-buttons': {
        DEFAULT: '#5A666D',
        50: '#7A8991',
        100: '#76858E',
        200: '#6F7E86',
        300: '#68767E',
        400: '#616E75',
        500: '#5A666D',
        600: '#4E595F',
        700: '#434C51',
        800: '#373F43',
        900: '#2C3235',
      },
      'cape-cod': {
        DEFAULT: '#3C4549',
        50: '#708087',
        100: '#6A7980',
        200: '#5F6C73',
        300: '#535F65',
        level0: '#485257',
        level1: '#3C4549',
        level2: '#353D40',
        level3: '#2E3538',
        level4: '#272D2F',
        level5: '#202527',
      },
      // app: {
      //   DEFAULT: '#1D1F22',
      //   // DEFAULT: '#2B2F32',
      //   50: '#A6ADB3',
      //   100: '#97A0A6',
      //   200: '#79848D',
      //   300: '#5E686F',
      //   400: '#454B51',
      //   500: '#2B2F32',
      //   600: '#24272A',
      //   700: '#1D1F22',
      //   800: '#161819',
      //   900: '#0F1011',
      // },
      guides: {
        DEFAULT: 'hsl(216, 10%, 13%)', // #1D1F22
        stroke: 'hsla(165, 100%, 50%, 0.7)', // #00FFC2
        area: 'hsla(165, 100%, 50%, 0.8)', // #00FFC280
        control_border: 'hsla(165, 100%, 50%, 0.25)', // #00FFC240
        control_anchor: 'hsl(216, 10%, 13%)', // #1D1F22
      },
      floor: {
        DEFAULT: 'hsl(216, 10%, 13%)', // #1D1F22
        label: 'hsla(46, 100%, 50%, 0.5)', // #FFCE0080
        guide: 'hsla(120, 100%, 50%, 0.5)', // #00FFC280
        overlap: 'hsla(337, 100%, 35%, 0.55)', // #b600e091
        intersection: 'hsl(303, 100%, 50%)', // #e000dc
      },
      area: {
        DEFAULT: 'hsl(216, 10%, 13%)', // #1D1F22
        label: 'hsla(65, 100%, 49%, 0.5)', // #FFF20075
        guide: 'hsla(120, 100%, 50%, 0.5)', // #00FFC280
        control_border: 'hsla(337, 100%, 31%, 0.6)', // #df00e090
        control_border_disabled: 'hsl(0, 0%, 67%)', // #aaaaaa
        control_anchor: 'hsla(65, 100%, 49%, 0.8)', // #FFCE0090
        link: 'hsla(337, 100%, 31%, 0.7)',
        link_source: 'hsla(303, 100%, 34%, 0.8)',
        link_target: 'hsla(303, 100%, 44%, 0.5)',
        link_both: 'hsla(303, 100%, 44%, 0.6)',
        link_arrow: 'hsla(0, 0%, 100%, 0.8)',
        link_hover: 'hsla(303, 100%, 54%, 1)',
        link_active: 'hsla(303, 100%, 64%, 1)',
        overlap: 'hsla(337, 100%, 31%, 0.44)', // #df00e070
        intersection: 'hsl(303, 100%, 44%)', // #e000dc
        intersection_preview: 'hsla(303, 100%, 44%, 0.2)', // #e000dc30
        selected: 'hsla(303, 100%, 44%, 0.5)', // #e000dc30
        not_selected: 'hsla(303, 100%, 44%, 0)', // #e000dc00 (Transparent)
        converter_overlay: 'hsla(120, 100%, 50%, 0.07)', // #00FFC210
        // overlap: '#b600e091',
        // converter_overlay: '#c8d8db10',
      },
      bay: {
        // DEFAULT: 'hsl(202, 90%, 30%)', // #005696

        DEFAULT: 'hsl(200, 80%, 25%)', // #005696

        // DEFAULT: 'hsl(195, 45%, 20%)', // #005696
        hover: 'hsl(208, 100%, 12%)', // #012A3F
        active: 'hsl(60, 100%, 50%)', // #FFFF00
        selected2: 'hsl(192, 100%, 50%)', // #00DBFF
        selected: 'hsl(193, 100%, 16%)', // #003A51
        unreachable: 'hsl(278, 100%, 50%, 0.7)', // #FF00C5B5
        passable: 'hsl(200, 20%, 30%, 0.5)', // #0097F4
        // passable: 'hsl(200, 100%, 29%)', // #0097F4
        hasPass: 'hsla(180, 99%, 37%, 0.3)', // #1f74aa95
        navigable: 'hsl(195, 25%, 50%)', // #1f74aa95
        // tunnel: 'hsl(199, 37%, 33%)', // #376786
        // empty: 'hsla(165, 100%, 52%, 0.6)', // rgba(10,255,204,0.6)
        // unused: 'hsla(164, 72%, 74%, 0.5)', // #0AFFCC
        // disabled: 'hsla(164, 100%, 50%, 0.2)', // #0AFFCC

        tunnel: 'hsl(199, 10%, 50%)', // #376786
        empty: 'hsla(165, 30%, 50%, 0.6)', // rgba(10,255,204,0.6)
        unused: 'hsla(165, 30%, 75%, 0.5)', // #0AFFCC
        disabled: 'hsla(165, 40%, 50%, 0.2)', // #0AFFCC

        shelf: 'hsl(47, 100%, 55%)', // #FFD629
        ground: 'hsla(206, 13%, 60%, 0.65)', // #899FAD
        groundText: 'hsl(206, 13%, 30%)',
        selectedBorder: 'hsl(191, 100%, 50%)', // #00C9FF
        hoverBorder: 'hsla(46, 100%, 50%, 0.5)', // #FFCE0080
        border: 'hsl(191, 100%, 50%)', // #00C9FF
        text: 'hsl(180, 100%, 56%)', // #8FFFFF
        action: 'hsla(332, 100%, 42%, 1)', // #D60065
        actionHovered: 'hsl(35, 100%, 58%)', // #F1A84C
        actionBg: 'hsla(0, 0%, 100%, 0.53)', // #FFFFFF88
      },
      aisle: {
        DEFAULT: 'hsl(204, 17%, 65%)', // #899FAE
        hover: 'hsl(200, 21%, 54%)', // #7190A2, rgba(113, 144, 162,1)
        test: 'hsl(200, 21%, 54%)', // #7190A2
        selected: 'hsl(200, 18%, 47%)', // #627D8D
        unreachable: 'hsl(60, 100%, 50%)', // #FFFF00
        disabled: 'hsla(200, 14%, 35%, 0.5)', // rgba(73, 93, 104, 0.5)
        block: 'hsla(200, 14%, 35%, 0.75)', // rgba(73, 93, 104, 0.75)
        blockSelected: 'hsla(46, 100%, 50%, 0.3)', // rgba(255, 206, 0, 0.3)
      },
      location: {
        DEFAULT: 'hsla(170, 20%, 65%, 0.5)', // #92C8C4, rgba(146, 200, 196, 0.8)
        hover: 'hsla(167, 40%, 44%, 0.5)', // rgba(0, 222, 175, 0.5)
        selected: 'hsl(46, 100%, 50%)', // #FFCE00
        selectedBorder: 'hsla(0, 0%, 0%, 0.5)', // #00000080
        selectedBorderDash: 'hsl(46, 100%, 50%)', // #FFCE00
        filtered: 'hsl(46, 100%, 50%)', // #FFCE00
        disabled: 'hsla(174, 29%, 44%, 0.4)', // rgba(89, 163, 159, 0.4)
        unreachable: 'hsl(278, 100%, 50%)', // #A600FF
        empty: 'hsla(165, 100%, 52%, 0.7)', // rgba(10, 255, 204, 0.7)
        unused: 'hsla(265, 40%, 85%, 1)', // #F8CDFD
        analyzing: 'hsla(68, 100%, 44%, 0.7)', // rgba(0, 222, 170, 0.2)
        optimizing: 'hsla(167, 100%, 44%, 0.6)', // rgba(0, 222, 170, 0.3)
        textEmpty: 'hsl(0, 0%, 0%)', // #000000
        textUnused: 'hsl(0, 0%, 0%)', // #000000
        srcLocations: 'hsl(312, 98%, 67%)', // #F421DF
        destLocations: 'hsl(108, 98%, 44%)', // #74F421
        reassignSwap: 'hsl(60, 100%, 50%)',
        title: 'hsl(202, 100%, 29%)', // #005696
        inaccessible: 'hsla(195, 100%, 61%, 0.5)', // rgba(68, 218, 255, 0.5)
        unreachableStart: 'hsla(12, 100%, 45%, 0.92)', // #E63A00EB
        unreachableEnd: 'hsla(24, 100%, 42%, 0.83)', // #FF6600D3
        unreachableStartAndEnd: 'hsla(284, 510%, 45%, 0.75)', // #A800E6BE
      },
      events: {
        preHandling: 'hsl(165, 87%, 29%)', // #0CB78F
        handlingPreparation: 'hsl(195, 100%, 59%)', // #45D6FF
        handlingExecution: 'hsl(217, 100%, 45%)', // #036EFF
        handlingCompletion: 'hsl(195, 100%, 59%)', // #44D6FE
        postHandling: 'hsl(165, 95%, 33%)', // #0CB78F
        travellingHorizontal: 'hsla(106, 100%, 52%,1)', // #73FF09, rgba(115, 255, 9, 0.6)
        travellingHorizontalStart: 'hsla(143, 93%, 58%, 1)', // #55FF09, rgba(85, 255, 9, 0.6)
        travellingHorizontalEnd: 'hsla(125, 96%, 59%, 1)', // #55FF09, rgba(85, 255, 9, 0.6)
        travellingVertical: 'hsl(77, 63%, 54%)', // #BDDF26
        handlingReordering: 'hsl(26, 87%, 58%)', // #F46D43
        // handlingExecution: 'hsl(240, 100%, 50%)',  // #2E3EFF
        // handlingExecution: 'hsl(142, 42%, 53%)',  // #44BF70
        // handlingCompletion: 'hsl(161, 59%, 38%)',  // #22A884
        // travellingHorizontal: 'hsl(194, 55%, 29%)',  // #2A788E
        // travellingVertical: 'hsl(210, 49%, 33%)',  // #355F8D
      },
      eventsAlt: {
        preHandling: 'hsla(172, 100%, 28%, 0.52)', // #0cb78f85
        handlingPreparation: 'hsla(201, 100%, 57%, 0.52)', // #45d6ff85
        handlingExecution: 'hsla(214, 100%, 42%, 0.52)', // #036eff85
        handlingCompletion: 'hsla(200, 100%, 67%, 0.52)', // #44d6fe85
        postHandling: 'hsla(172, 100%, 28%, 0.52)', // #0cb78f85
        travellingHorizontal: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        travellingHorizontalStart: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        travellingHorizontalEnd: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        travellingVertical: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        handlingReordering: 'hsla(18, 100%, 58%, 0.52)', // #f46d4385
      },
      eventGroups: {
        handling: 'hsl(172, 100%, 28%)', // #0cb78f
        picking: 'hsl(214, 100%, 42%)', // #036eff
        travellingHorizontal: 'hsl(134, 99%, 45%)', // #73ff09
        travellingVertical: 'hsl(101, 100%, 47%)', // #bddf26
        // travellingEndpoint: 'hsl(71, 100%, 50%)', // #adff09
        travellingEndpoint: 'hsl(72, 100%, 50%)', // #adff09
        reordering: 'hsl(18, 100%, 58%)', // #f46d43
        idle: 'hsla(300, 95%, 27%, 0.85)', // rgba(131, 6, 134, 0.85)
        overtime: 'hsl(342, 100%, 50%)', // #FF0066
        total: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        labourTotal: 'hsl(249, 62%, 40%)', // #4f32bd
        breaks: 'hsl(163, 41%, 52%)', // #66c2a5
        meetings: 'hsl(130, 34%, 72%)', // #abdda4
        training: 'hsl(78, 53%, 78%)', // #e6f598
        recharge: 'hsl(60, 100%, 75%)', // #ffffbf
        maintenance: 'hsl(45, 100%, 70%)', // #fee08b
        misc: 'hsl(32, 100%, 63%)', // #fdae61
      },
      eventGroupsTxt: {
        handling: 'hsl(172, 100%, 28%)', // #0cb78f
        picking: 'hsl(211, 79%, 45%)', // #1c7eff
        travellingHorizontal: 'hsl(98, 100%, 52%)', // #73ff09
        travellingVertical: 'hsl(80, 100%, 50%)', // #bddf26
        travellingEndpoint: 'hsl(74, 100%, 50%)', // #aadf00
        reordering: 'hsl(18, 100%, 58%)', // #f46d43
        idle: 'hsl(300, 95%, 54%)', // #f461ff
        overtime: 'hsl(328, 100%, 42%)', // #d60065
        total: 'hsla(64, 100%, 50%, 0.52)', // #f3f32085
        labourTotal: 'hsl(206, 55%, 40%)', // #3288bd
        breaks: 'hsl(163, 41%, 52%)', // #66c2a5
        meetings: 'hsl(130, 34%, 72%)', // #abdda4
        training: 'hsl(78, 53%, 78%)', // #e6f598
        recharge: 'hsl(60, 100%, 75%)', // #ffffbf
        maintenance: 'hsl(45, 100%, 70%)', // #fee08b
        misc: 'hsl(32, 100%, 63%)', // #fdae61
      },
      picklistLines: {
        full: 'hsl(106, 100%, 52%)', // #73FF09, rgba(115, 255, 9, 1)
        partial: 'hsl(83, 100%, 52%)', // #CEFF09, rgba(206, 255, 9, 1)
        ignored: 'hsl(101, 16%, 63%)', // #92B18E, rgba(146, 177, 142, 1)
      },
      shift: {
        1: 'hsl(193.17deg 13.01% 43.3%)',
        2: 'rgba(88, 152, 170, 0.33)',
        3: 'rgba(88, 152, 170, 0.33)',
        4: 'rgba(88, 152, 170, 0.33)',
        5: 'rgba(88, 152, 170, 0.33)',
      },
      workforceForecastExperiment: {
        // 50: '#E1EEFE',
        // 100: '#D3E7FD',
        // 200: '#B9DBF8',
        // 300: '#98CDF1',
        // 400: '#72BEE4',
        // 500: '#43ABD0',
        // 600: '#4BACDD',
        // 700: '#50ABE7',
        // 800: '#60ADF0',
        // 900: '#6DAEF8',
        //        "50": "#F0EBFE",
        // "100": "#E5E2FE",
        // "200": "#D0D4FB",
        // "300": "#B3C6F5",
        // "400": "#87B9E8",
        // "500": "#43ABD0",
        // "600": "#69A8E2",
        // "700": "#86A5EE",
        // "800": "#98A0F5",
        // "900": "#AEA2FB"
        50: '#C8D8F4',
        100: '#B3CBEF',
        200: '#89B4E6',
        300: '#5FA2DD',
        400: '#3595D5',
        500: '#2A95CA',
        600: '#2792BA',
        700: '#238EA9',
        800: '#208898',
        900: '#1C8087',
      },
      workforceForecast: {
        default: '#CBFBDE',
        1: '#91F2CD',
        2: '#4FE3CB',
        3: '#2BCBD4',
        4: '#43ABD0',
        5: '#24ABB2',
        6: '#19A38C',
        7: '#129B67',
        8: '#0C9744',
        9: '#208898',
        10: '#2792BA',
        11: '#19879f',
        12: '#2897bf',
        13: '#3595D5',
        14: '#5FA2DD',
        15: '#89B4E6',
        16: '#B3CBEF',
        17: '#C8D8F4',
        18: '#eff9bd',
        19: '#d5eeb3',
        20: '#a9ddb7',
        21: '#73c9bd',
        22: '#45b4c2',
        23: '#037877',
        // 900: '#1C8087',
        // 0: '#ffffd9',
        // default: '#b6c1c9',
      },

      performance: {
        140: '#037877',
        130: '#078976',
        120: '#27a078',
        110: '#29b260',
        100: '#33AD0B',
        90: '#3fff00',
        80: '#89eb00',
        70: '#b1d600',
        60: '#cfc000',
        50: '#ffe400',
        40: '#ffbc00',
        30: '#ff9200',
        20: '#ff6100',
        10: '#ff0909',
        5: '#f8324b',
        4: '#ff7568',
        3: '#fd977f',
        0: '#ae0053',
      },
      heatmap2: {
        100: '#d0300e',
        90: '#ec5b18',
        80: '#e77f35', // 80: '#fac183',
        70: '#f1a84c',
        // ———————————> middle
        60: '#a7f772',
        50: '#74f421',
        40: '#45d60f',
        30: '#29b260',
        20: '#27a078',
        10: '#078976',
        0: '#00569690',
      },
      heatmap: {
        // 100: '#d02274',
        1000: '#d60065',
        100: '#ae0053',
        90: '#f8324b',
        80: '#ff7568',
        70: '#fd977f',
        60: '#f8b77f',
        50: '#d4bd6b',
        // ———————————> middle
        40: '#deec21',
        30: '#afce29',
        20: '#53aa43',
        10: '#27a078',
        0: '#56728c90',
        // 0: '#a2b4ba80',
        // empty: '#0affcc80',
        // unused: '#0affcc',
        // disabled: '#a2b4ba80',
      },

      heatmap3: {
        100: '#e04805', // 100: '#ca4f19',
        90: '#fb7809', // 90: '#e66c37',
        80: '#f3993e', // 80: '#f4a083',
        70: '#fbbc57',
        60: '#ffd798',
        50: '#9cc0a2',
        40: '#578760',
        30: '#578760',
        20: '#578760',
        10: '#578760',
        0: '#00569690', // 0: '#3984bb',
      },
      heatmapText: {
        0: '#000000',
        10: '#000000',
        20: '#000000',
        30: '#000000',
        40: '#000000',
        50: '#000000',
        60: '#FFFFFF',
        70: '#FFFFFF',
        80: '#FFFFFF',
        90: '#FFFFFF',
        100: '#FFFFFF',
      },
      heatmap_analytics: {
        D: '#FFE67A',
        0: '#FFE67A',
        10: '#f9ffd5',
        20: '#ffffbf',
        30: '#e6f598',
        C: '#e6f598',
        40: '#eafc52',
        50: '#fac727',
        60: '#f99230',
        B: '#ffb400',
        70: '#ee7918',
        80: '#ea6002',
        90: '#E30303',
        A: '#ff6935',
        100: '#99037c',
        // 'A+': '#ff008a',
        'A+': '#c226bd',
      },
      categoryABC: {
        // 100: '#d02274',
        1000: '#d60065',
        100: '#ae0053',
        S: '#d02274',
        // S: '#c226bd',
        // A: '#aff05b',
        A: '#ff6935',
        B: '#ffb400',
        // C: '#e6f598',
        // C: '#79a169',
        C: '#c2f7a9', //#c2f7a9
        D: '#d8e4f3', //'#dcfff5', // '#a2b4ba80',
        // D: '#FFE67A', // '#a2b4ba80',
        E: '#ff00c5b5',
      },
      categoryABCText: {
        S: '#FFFFFF',
        A: '#FFFFFF',
        B: '#FFFFFF',
        C: '#000000',
        D: '#000000',
      },
      categoryABCD: {
        1000: 'hsla(331, 100%, 41%, 1)', // #D60065
        100: 'hsla(331, 100%, 34%, 1)', // #AE0053
        S: 'hsla(331, 71%, 47%, 1)', // A+ // #D02274
        A: 'hsla(15, 100%, 60%, 1)', // #FF6935
        B: 'hsla(42, 100%, 50%, 1)', // #FFB400
        C: 'hsla(100, 82%, 81%, 1)', // #C2F7A9
        D: 'hsla(163, 45%, 78%, 1)', // Deadstock // #AFE1D3
        E: 'hsla(313, 100%, 50%, 1)', // #FF00C5B5
      },

      categoryABCXYZ: {
        1000: 'hsla(331, 100%, 41%, 1)', // #D60065
        100: 'hsla(331, 100%, 34%, 1)', // #AE0053
        S: 'hsla(331, 71%, 47%, 1)', // A+ // #D02274
        A: 'hsla(15, 100%, 60%, 1)', // #FF6935
        B: 'hsla(42, 100%, 50%, 1)', // #FFB400
        C: 'hsla(100, 82%, 81%, 1)', // #C2F7A9
        D: 'hsla(163, 45%, 78%, 1)', // Deadstock // #AFE1D3
        X: 'hsla(15, 100%, 60%, 1)', // #FF6935
        Y: 'hsla(42, 100%, 50%, 1)', // #FFB400
        Z: 'hsla(100, 82%, 81%, 1)', // #C2F7A9
        R: 'hsla(163, 45%, 78%, 1)',
        strategic: 'hsla(331, 100%, 60%, 1)',
        critical: 'hsla(15, 100%, 60%, 1)',
        regular: 'hsla(42, 100%, 60%, 1)',
        review: 'hsla(8, 100%, 90%, 1)',
        nonCritical: 'hsla(100, 82%, 80%, 1)',
        deadStock: 'hsla(160, 50%, 80%, 1)',
        unknown: 'hsl(263, 35%, 43%)',
      },

      categoryOccupancy: {
        unknown: 'hsl(263, 35%, 43%)', // #6e40aa, rgb(110, 64, 170)
        empty: 'hsla(164, 100%, 52%, 0.7)', // rgba(10, 255, 204, 0.7)
        // 150: '#8F88D2',
        // 150: '#f369a3',
        150: '#ff00ff',
        100: '#33AD0B',
        90: '#3fff00',
        80: '#89eb00',
        70: '#b1d600',
        60: '#cfc000',
        50: '#ffe400',
        40: '#ffbc00',
        30: '#ff9200',
        20: '#ff6100',
        10: '#ff0909',
        0: '#0affcc',
        // 50: '#e5a800',
        // 40: '#f48e00',
        // 30: '#fe7300',
        // 20: '#ff570f',
        // 10: '#ff3833',
      },
      categoryOccupancyText: {
        unknown: '#FFFFFF',
        0: '#000000',
        10: '#000000',
        20: '#000000',
        30: '#000000',
        40: '#000000',
        50: '#000000',
        60: '#FFFFFF',
        70: '#FFFFFF',
        80: '#FFFFFF',
        90: '#FFFFFF',
        100: '#FFFFFF',
      },

      heatmap_analytics_weight_2: {
        // 0: '#71F498',
        // 10: '#85F99C',
        // 20: '#B4F985',
        // 30: '#E2F985',
        // 40: '#F6FA8F',
        // 50: '#F9EB8A',
        // 60: '#F9C777',
        // 70: '#F8AE6D',
        // 80: '#f98f62',
        // 90: '#fb6f4c',
        // 100: '#ff2b0a',

        0: '#6CF495',
        10: '#89F59F',
        20: '#ACFBB7',
        30: '#CFFABC',
        40: '#E7FFA3',
        50: '#F4F8A5',
        60: '#F4ED99',
        70: '#F8AE6D',
        80: '#F89368',
        90: '#FB6641',
        100: '#EB2B00',
      },
      heatmap_analytics_green: {
        DEFAULT: '#71F4A3',
        0: '#71F4A3',
        10: '#65F39C',
        20: '#4EF18F',
        30: '#36EF82',
        40: '#1EEE76',
        50: '#11E16C',
        60: '#10C963',
        70: '#0EB259',
        80: '#0C9A4F',
        90: '#0A8244',
        100: '#09763E',
      },

      heatmap_analytics_weight: {
        0: '#FFFFFF',
        10: '#FFFFFF',
        20: '#FFFFFF',
        30: '#FDFEEC',
        40: '#FCF9D9',
        50: '#FBEFC6',
        60: '#F9E1B3',
        70: '#F8CFA0',
        80: '#f7ac76',
        90: '#f46d43',
        100: '#fb4913',
      },
      priority: {
        top: '#00FFFF', //'hsl(191, 100%, 45%)', // #00C3E5, rgba(0, 195, 229, 1)
        bottom: '#ff6831', //'hsl(251, 100%, 70%)', // #826AFF, rgba(130, 106, 255, 1)
        middle: 'hsl(69, 100%, 50%)', // #FFCE00, rgba(255, 206, 0, 1)
        none: 'hsl(206, 8%, 66%)', // #B3BBC1, rgba(179, 187, 193, 1)
      },
      priorityText: {
        top: '#FFFFFF',
        none: '#FFFFFF',
        bottom: '#FFFFFF',
        middle: '#FFFFFF',
      },
      heatmap_text: {
        dark: '#000000',
        light: '#FFFFFF',
        empty: '#000000',
        unknown: '#FFFFFF',
      },
      heatmap_compliance: {
        black: 'hsl(0, 0%, 0%)', // #000000
        nonCompliant: 'hsl(300, 100%, 50%)', // #FF00FF
        nonCompliantText: 'hsl(0, 0%, 100%)', // #FFFFFF
        compliant: 'hsl(106, 100%, 50%)', // #3FFF00
        compliantText: 'hsl(0, 0%, 0%)', // #000000
        issue: 'hsl(9, 100%, 52%)', // #FF4B0A
        issueText: 'hsl(0, 0%, 100%)', // #FFFFFF
        floor: 'hsl(161, 37%, 56%)', // #66C2A5
        unknown: 'hsl(260, 38%, 45%)', // #6E40AA
        unknownText: 'hsl(0, 0%, 100%)', // #FFFFFF
        empty: 'hsla(170, 100%, 52%, 0.7)', // rgba(10,255,204,0.7)
        emptyText: 'hsl(0, 0%, 0%)', // #000000
        100: 'hsl(338, 92%, 31%)', // #9E0142
        90: 'hsl(356, 68%, 43%)', // #D53E4F
        80: 'hsl(26, 87%, 58%)', // #F46D43
        70: 'hsl(34, 88%, 66%)', // #FDAE61
        60: 'hsl(45, 100%, 71%)', // #FEE08B
        50: 'hsl(80, 68%, 56%)', // #AFF05B
        40: 'hsl(89, 100%, 53%)', // #7FF658
        30: 'hsl(101, 92%, 51%)', // #52F667
        20: 'hsl(128, 81%, 56%)', // #30EF82
        10: 'hsl(159, 71%, 56%)', // #1DDFA3
        // 0: '#225ea8',
        // 10: '#3288bd',
        // 20: '#66c2a5',
        // 30: '#abdda4',
        // 40: '#e6f598',
        // 50: '#ffffbf',
      },
      compliance: {
        nonCompliant: 'hsl(300, 100%, 50%)', // #FF00FF, rgba(255, 0, 255, 1)
        nonCompliantText: 'hsl(0, 0%, 100%)', // #FFFFFF, rgba(255, 255, 255, 1)
        compliant: 'hsl(106, 100%, 50%)', // #3FFF00, rgba(63, 255, 0, 1)
        compliantText: 'hsl(0, 0%, 100%)', // #FFFFFF, rgba(255, 255, 255, 1)
        issue: 'hsl(28, 100%, 45%)', // #E75C00, rgba(231, 92, 0, 1)
        // compliant: '#7ff658',
        // compliant: '#30ef82',
        // nonCompliant: '#8810e2',
        // nonCompliant: '#9e0142',
      },
      congestion: {
        DEFAULT: '#8810e2',
      },
      routing: {
        directionBackground: 'hsl(192, 7%, 9%)', // #141618, rgba(20, 22, 24, 1)
        directionBackgroundActive: 'hsl(49, 100%, 50%)', // #FFCC00, rgba(255, 204, 0, 1)
        directionBackgroundHover: 'hsl(192, 7%, 9%)', // #141618, rgba(20, 22, 24, 1)
        directionBackgroundDisabled: 'hsla(194, 21%, 75%, 0.21)', // #B1CEE0, rgba(177, 206, 224, 0.21)
        directionDisabled: 'hsla(0, 0%, 100%, 0.5)', // #FFFFFF, rgba(255, 255, 255, 0.5)
        directionActive: 'hsl(192, 7%, 9%)', // #141618, rgba(20, 22, 24, 1)
        directionHover: 'hsl(49, 100%, 50%)', // #FFCC00, rgba(255, 204, 0, 1)
        directionNorth: 'hsl(298, 63%, 22%)', // #6A176E, rgba(106, 23, 110, 1)
        directionSouth: 'hsl(328, 65%, 31%)', // #932667, rgba(147, 38, 103, 1)
        directionEast: 'hsl(342, 59%, 42%)', // #BC3754, rgba(188, 55, 84, 1)
        directionWest: 'hsl(12, 68%, 46%)', // #DD513A, rgba(221, 81, 58, 1)
      },
      route: {
        DEFAULT: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        waypoint: 'hsla(98, 100%, 50%, 0.46)', // #73FF09, rgba(115, 255, 9, 0.46)
        selected: 'hsla(283, 100%, 46%, 0.9)', // #9F00EC, rgba(159, 0, 236, 0.9)
        selectedBorder: 'hsl(61, 100%, 50%)', // #DCFF00, rgba(220, 255, 0, 1)
        selectedBorderDash: 'hsla(143, 55%, 14%, 0.8)', // #003216, rgba(0, 50, 22, 0.8)
        invalid: 'hsla(160, 100%, 77%, 0.71)', // #00FFC5, rgba(0, 255, 197, 0.71)
        hover: 'hsla(58, 100%, 61%, 0.56)', // #F3F320, rgba(243, 243, 32, 0.56)
        active: 'hsl(49, 100%, 50%)', // #FFCE00, rgba(255, 204, 0, 1)
        activeUp: 'hsl(49, 100%, 50%)', // #FFCE00, rgba(255, 204, 0, 1)
        activeDown: 'hsla(58, 100%, 61%, 0.56)', // #FFCE00, rgba(255, 204, 0, 1)
        label: 'hsl(0, 0%, 0%)', // #000000, rgba(0, 0, 0, 1)
        tag: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        labelBg: 'hsla(297, 62%, 20%, 0.56)', // #69036F, rgba(105, 3, 111, 0.56)
        start: 'hsl(83, 100%, 39%)', // #7AC600, rgba(122, 198, 0, 1)
        end: 'hsl(65, 100%, 46%)', // #EBFF09, rgba(235, 255, 9, 1)
        arrow: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        path: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        line: 'hsl(276, 100%, 33%)', // #A600FF, rgba(166, 0, 255, 1)
        line_full: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        line_start: 'hsla(139, 96%, 34%, 0.75)', // #06AE01, rgba(6, 174, 1, 0.75)
        line_end: 'hsl(98, 100%, 50%)', // #73FF09, rgba(115, 255, 9, 1)
        line_partial: 'hsl(97, 100%, 52%)', // #CEFF09, rgba(206, 255, 9, 1)
        line_ignored: 'hsl(109, 15%, 62%)', // #92B18E, rgba(146, 177, 142, 1)
      },
      portal: {
        DEFAULT: 'hsla(300, 100%, 50%, 0.31)', // #FF00FF, rgba(255, 0, 255, 0.31)
        selected: 'hsl(283, 100%, 46%)', // #9F00EC, rgba(159, 0, 236, 1)
        invalid: 'hsla(0, 100%, 50%, 0.42)', // #FF0000, rgba(255, 0, 0, 0.42)
        hover: 'hsl(49, 100%, 50%)', // #FFCE00, rgba(255, 204, 0, 1)
        border: 'hsl(49, 100%, 50%)', // #FFCE00, rgba(255, 204, 0, 1)
        waypoint: 'rgba(181,209,226,0.25)',
        entrypoint: 'rgba(239,255,1,1)',
      },
      issues: {
        warn: 'hsl(28, 100%, 45%)', // #E75C00,  rgba(231, 92, 0, 1),
        warnText: 'hsl(0, 0%, 100%)', // #FFFFFF,  rgba(255, 255, 255, 1),

        alert: 'hsl(300, 90%, 41%)', // #C70AC7,  rgba(199, 10, 199, 1),
        alertText: 'hsl(0, 0%, 100%)', // #FFFFFF,  rgba(255, 255, 255, 1),

        unknown: 'hsl(278, 93%, 57%)', // #BA29F9,  rgba(186, 41, 249, 1),
        unknownText: 'hsl(0, 0%, 100%)', // #FFFFFF,  rgba(255, 255, 255, 1),
      },
      alerts: {
        error: 'hsla(5, 98%, 35%, 0.75)', // #D40202,  rgba(212, 2, 2, 0.9)
        errorText: 'hsl(0, 0%, 100%)',
        alert: 'hsl(278, 83%, 57%)', // #BA29F9,  rgba(186, 41, 249, 1)
        alertText: 'hsl(0, 0%, 100%)',
        issue: 'hsl(300, 100%, 50%)', // #FF3DFF,  rgba(255, 61, 255, 1)
        unknown: 'hsl(275, 84%, 38%)', // #8810E2,  rgba(136, 16, 226, 1)
        warning: 'hsl(28, 100%, 45%)', // #E75C00,  rgba(231, 92, 0, 1)
        warningText: 'hsl(0, 0%, 100%)',
        warn: 'hsl(32, 88%, 49%)', // #EC820D,  rgba(236, 130, 13, 1)
        info: 'hsl(220, 100%, 34%)', // #003EAF,  rgba(0, 62, 175, 1)
        okay: 'hsla(142, 100%, 30%, 1)', // #078E05,  rgba(7, 142, 5, 1)
        compliant: 'hsl(104, 100%, 50%)', // #3FFF00,  rgba(63, 255, 0, 1)
        disabled: 'hsl(0, 0%, 87%)', // #DDDDDD,  rgba(221, 221, 221, 1)
        overlap: 'hsla(337, 100%, 55%, 0.55)', // #00FFC5,  rgba(0, 255, 197, 0.71)
        unmapped: 'hsla(239, 20%, 40%, 1)', // #00FFC5,  rgba(0, 255, 197, 0.71)
      },
      state: {
        draft: 'hsl(201, 9%, 70%)', // Hex: #B3BBC1, RGBA: rgba(179, 187, 193, 1)
        imported: 'hsl(201, 9%, 70%)', // Hex: #B3BBC1, RGBA: rgba(179, 187, 193, 1)
        analyzing: 'hsl(49, 100%, 50%)', // Hex: #FFCE00, RGBA: rgba(255, 206, 0, 1)
        analyzed: 'hsl(59, 100%, 50%)', // Hex: #FFCE00, RGBA: rgba(255, 206, 0, 1)
        allocating: 'hsl(69, 100%, 50%)',
        allocated: 'hsl(69, 100%, 50%)',
        computed: 'hsl(69, 100%, 50%)',
        optimizing: 'hsl(164, 100%, 45%)', // Hex: #00E4B0, RGBA: rgba(0, 228, 176, 1)
        optimized: 'hsl(164, 100%, 45%)', // Hex: #00E4B0, RGBA: rgba(0, 228, 176, 1)
        reassinged: 'hsl(164, 100%, 45%)', // Hex: #00E4B0, RGBA: rgba(0, 228, 176, 1)
        disabled: 'hsl(0, 0%, 87%)', // Hex: #DDDDDD, RGBA: rgba(221, 221, 221, 1)
        error: 'hsl(275, 84%, 38%)', // Hex: #8810E2, RGBA: rgba(136, 16, 226, 1)
        unknown: 'hsla(239, 20%, 40%, 1)',
      },
      wave: {
        unscheduled: 'hsl(343, 74%, 42%)', // Hex: #D61C4E, RGBA: rgba(214, 28, 78, 1)
      },
      signal: {
        light: 'hsl(0, 0%, 100%)',
        dark: 'hsla(210, 9%, 8%, 1)',
        bar: 'hsla(204, 10%, 45%, 1)',
        10: 'hsl(338, 92%, 31%)', // #9E0142
        20: 'hsl(356, 68%, 43%)', // #D53E4F
        30: 'hsl(26, 87%, 58%)', // #F46D43
        40: 'hsl(34, 88%, 66%)', // #FDAE61
        50: 'hsl(45, 100%, 71%)', // #FEE08B
        60: 'hsl(80, 68%, 56%)', // #AFF05B
        70: 'hsl(89, 100%, 53%)', // #7FF658
        80: 'hsl(101, 92%, 51%)', // #52F667
        90: 'hsl(128, 81%, 56%)', // #30EF82
        100: 'hsl(159, 71%, 56%)', // #1DDFA3
      },
      dimensionsColors: {
        1: '#2192FF',
        2: '#38E54D',
        3: '#9cff2e',
        4: '#aed987',
        5: '#FAC213',
        6: '#0C9744',
        7: '#4b4b8f',
        // 7: '#D61C4E',
        8: '#21E1E1',
        9: '#3B9AE1',
        10: '#e4604e',
        11: '#39A388',
        12: '#FF97C1',
        13: '#003eaf',
        // 13: '#B3B3B3',
        // 13: '#D61C4E',
        14: '#F3950D',
        15: '#F4E185',
        16: '#e4604e',
        // 16: '#9A1663',
        17: '#1C7947',
        18: '#3969AC',
        19: '#98f959',
        // 19: '#CD1818',
        20: '#f7a258',
        // 21: '#E0144C',
        22: '#009dd4',
        // 23: '#FF5858',
        // 24: '#00cac9',
        // 25: '#00ddac',
        // 26: '#24ed85',
        27: '#98f959',
        28: '#ebff26',
        29: '#00876c',
        30: '#00b5d7',
        // 42: '#d43d51',
        43: '#7F3C8D',
        44: '#11A579',
        45: '#3969AC',
        46: '#F2B701',
        // 47: '#E73F74',
        48: '#80BA5A',
        49: '#E68310',
        50: '#008695',
        // 51: '#CF1C90',
        52: '#f97b72',
        53: '#4b4b8f',
        54: '#A5AA99',
        55: '#66C5CC',
        56: '#F6CF71',
        57: '#F89C74',
        58: '#DCB0F2',
        59: '#87C55F',
        60: '#9EB9F3',
        61: '#FE88B1',
        62: '#C9DB74',
        63: '#8BE0A4',
        64: '#B497E7',
        65: '#D3B484',
        66: '#B3B3B3',
        71: '#3d9c73',
        72: '#63b179',
        73: '#88c580',
        74: '#aed987',
        75: '#d6ec91',
        76: '#ffff9d',
        77: '#fee17e',
        78: '#fcc267',
        79: '#f7a258',
        80: '#ef8250',
        81: '#e4604e',
      },

      policyColors2: {
        1: '#38e54d',
        2: '#a593f9',
        3: '#c7cc00',
        4: '#34c3ea',
        5: '#f4430c',
        6: '#ED8E8E',
        7: '#50e29c',
        8: '#d6f941',
        9: '#54F8FC',
        10: '#e28150',
        11: '#48ffb3',
        12: '#FFD23E',
        13: '#9c74ff',
        14: '#38e54d',
        15: '#ff8e74',
        16: '#10cdf9',
        17: '#c14a4a',
        18: '#bacf66',
        19: '#ce66bb',
        20: '#74ff9c',
        21: '#4b82c2',
        22: '#82ab6c',
        23: '#c14a4a',
        24: '#f7a258',
        25: '#6ac879',
        26: '#516ecf',
        27: '#b497e7',
        28: '#a2a149',
        29: '#ea6b7a',
        30: '#73c8a9',
        31: '#956ff0',
        32: '#ff6ea6',
        33: '#c69f5c',
        34: '#38e54d',
        35: '#bacf66',
        36: '#dcf25d',
        37: '#fe88b1',
        38: '#52986c',
        39: '#7f3c8d',
        40: '#b180d3',
        41: '#48ffb3',
        42: '#8be0a4',
        43: '#cced4b',
        44: '#6f5ac2',
        45: '#f97b72',
        46: '#00c2cb',
        47: '#ff6da5',
        48: '#73c8a9',
        49: '#d3b484',
        50: '#7e9cf3',
      },
      policyColors1: {
        1: '#6F90F0',
        2: '#FFD23E',
        3: '#EA5275',
        4: '#9C74FF',
        5: '#6EF582',
        6: '#3EC0E8',
        7: '#FF6EA6',
        8: '#8CC464',
        9: '#FF9F5B',
        10: '#FF476E',
        11: '#7F3C8D',
        12: '#55B8EA',
        13: '#FFA046',
        14: '#5AC854',
        15: '#F36D81',
        16: '#6F8DE8',
        17: '#FFE659',
        18: '#FF295B',
        19: '#6A2673',
        20: '#4AA3D0',
        21: '#FF8939',
        22: '#2E9D8E',
        23: '#E84C5E',
        24: '#5FA7E9',
        25: '#FF9C36',
        26: '#8DC452',
        27: '#FF7354',
        28: '#4C8BE8',
        29: '#F9B428',
        30: '#E2445E',
        31: '#2D82C7',
        32: '#FF8A00',
        33: '#56C9A9',
        34: '#F74C3C',
        35: '#3165AC',
        36: '#C8E8A8',
        37: '#FF6540',
        38: '#6190CC',
        39: '#ED4E64',
        40: '#4FA3A3',
        41: '#9DE84D',
        42: '#FF4C00',
        43: '#2B6AAE',
        44: '#FF8E30',
        45: '#5999D0',
        46: '#E93D55',
        47: '#4C7FE8',
        48: '#FF6B3B',
        49: '#6D96E8',
        50: '#FF8600',
      },
    },
    fontFamily: {
      title: ['"NeoSans-Light"', 'sans-serif'],
      strong: ['"NeoSans-Bold"', 'sans-serif'],
      active: ['"NeoSans-Regular"', 'sans-serif'],
      headline: ['"NeoSans-Regular"', 'sans-serif'],
      // body: ['"Patron-Medium"', "sans-serif"],
      body: ['"NeoSans-Regular"', 'sans-serif'],
    },
    padding: {
      fix: '2px',
      nav: '3rem',
      screen: '2rem',
      default: '1rem',
      vertical: '1rem',
      horizontal: '0.5rem',
      'screen-sm': '2rem',
      'screen-md': '2rem',
      'screen-lg': '2rem',
      'screen-xl': '2rem',
    },
    ringWidth: {
      DEFAULT: '0',
    },
    height: {
      18: '4.5rem',
      19: '4.75rem',
      header: '2.25rem',
      header_screen: '4.5rem',
      header_title: '4.5rem',
      topmenu: '68px',
      tablemax: 'calc(100vh - 24rem)',
      bayMap: '50rem',
      stretch: 'stretch',
      fill: '-webkit-fill-available',
      trialcard: '12rem',
    },
    top: {
      header_screen: '4.5rem',
      header_title: '4.5rem',
    },
    inset: {
      menu: '24px',
    },
    width: {
      line: '1px',
      18: '4.5rem',
      120: '32rem',
      140: '64rem',
      sidebar: '32rem',
      'sidebar-xxs': '15rem', // w-64
      'sidebar-xs': '18rem', // w-64
      'sidebar-sm': '20rem', // w-80
      'sidebar-md': '24rem', // w-96
      'sidebar-lg': '32rem', // w-120
      'sidebar-xl': '40vw', // w-140
      'sidebar-full': '100%',
      'sidebar-half': '45vw',
      'sidebar-fit': 'fit-content',
      'sidebar-collapsed': '2.5rem', //w-10
      'sidebar-minimised-xs': '3.5rem',
      'sidebar-minimised-sm': '3.75rem',
      'sidebar-minimised-md': '4rem',
      'sidebar-minimised': '5rem', //w-20
      'sidebar-minimised-icons': '7rem', //w-28
      collapsed: '2.5rem',
      fit: 'fit-content',
      tooltip: '8rem',
    },
    translate: {
      'sidebar-xs': '16rem', // w-64
      'sidebar-sm': '20rem', // w-80
      'sidebar-md': '24rem', // w-96
      'sidebar-lg': '32rem', // w-120
      'sidebar-xl': '64rem', // w-140
      'sidebar-full': '100%',
      'sidebar-half': '50%',
      'sidebar-fit': 'fit-content',
      'sidebar-collapsed': '2.5rem', //w-10
      'sidebar-minimised': '5rem', //w-20
      'sidebar-minimised-icons': '7rem', //w-28
    },
    scake: {
      130: '1.30',
      135: '1.35',
      140: '1.40',
    },
    maxHeight: {
      tablemax: 'calc(100vh - 24rem)',
      '1/2': '50vh',
      '1/4': '33vh',
      '1/3': '33%',
      4: '4rem',
      5: '5rem',
      6: '6rem',
      10: '10rem',
      20: '20rem',
      map: '20rem',
      bayMap: '30rem',
    },
    minHeight: {
      panel: 'calc(100vh - 12rem)',
      '1/2': '50vh',
      '1/4': '33vh',
      4: '4rem',
      5: '5rem',
      6: '6rem',
      10: '10rem',
      20: '20rem',
      map: '20rem',
      bayMap: '33vh',
    },
    minWidth: {
      0: '0',
      'card-sm': '100%',
      'card-md': '49%',
      'card-lg': '32%',
      'card-xl': '23%',
      'card-2xl': '21%',
      half: '50%',
      third: '33%',
      quarter: '25%',
      '1/5': '20%',
      '1/6': '16.66%',
      '1/8': '12.5%',
      '3/4': '75%',
      '3/7': '42%',
      1: '0.25rem',
      1.5: '0.375rem',
      2: ' 0.5rem',
      2.5: '0.625rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      32: '8rem',
      40: '10rem',
      64: '16rem',
      80: '20rem',
      96: '24rem',
      min: '16rem',
      max: '24rem',
      map: '24rem',
      full: '100%',
      tooltip: '6rem',
      thlong: '22ch',
      collapsed: '2.5rem',
      fit: 'fit-content',
    },
    maxWidth: {
      thlong: '22ch',
      'ch-5': '5h',
      'ch-10': '10ch',
      'ch-12': '10ch',
      'ch-15': '15ch',
      'ch-20': '20ch',
      'ch-25': '25ch',
      'ch-30': '30ch',
      0: '0',
      'card-sm': '100%',
      'card-md': '49%',
      'card-lg': '32%',
      'card-xl': '23%',
      'card-2xl': '21%',
      '1/2': '50%',
      '1/3': '33%',
      '1/4': '25%',
      '1/5': '20%',
      '1/6': '16.66%',
      '1/8': '12.5%',
      '3/4': '75%',
      collapsed: '2rem',
      tooltip: '8rem',
      2: '2rem',
      5: '5rem',
      10: '10rem',
      15: '15rem',
      20: '20rem',
      25: '25rem',
      30: '30rem',
      min: '16rem',
      64: '16rem',
      80: '20rem',
      96: '24rem',
      max: '20rem',
      map: '20rem',
      maximus: '24rem',
      full: '100%',
      titlescreen: 'calc(100vw - 120px)',
      fit: 'fit-content',
    },
    boxShadow: {
      'border-bottom': '0 1px 0 0 #2B3034',
      'border-top': ' 0 -1px 0 0 #2B3034',
      'border-left': '-1px 0 0 0 #2B3034',
      'border-right': '1px 0 0 0 #2B3034',
      'border-all': '0 0 0 1px #2B3034',
    },
    zIndex: {
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      60: 60,
      70: 70,
      80: 80,
      90: 90,
      100: 100,
      200: 200,
      300: 300,
      400: 500,
      1000: 1000,
      tabs: 2402,
      footer: 2401,
      statgroup: 2400,
      dropdown: 400,
      dropdown_menu: 2410,
      sidebar: 2500,
      sidebar_help: 2500,
      header: 500,
      header_screen: 2500,
      menu: 2000,
      container: 1900,
      alert: 5000,
      toolbar: 5001,
      privacy: 10000,
    },
    spacing: {
      'stripe-sm': '10px', // Small stripe size
    },
    backgroundImage: {
      'barber-pole':
        'repeating-linear-gradient(-45deg, transparent, transparent 10px, #000 10px, #000 20px)',
      'barber-pole-sm':
        'repeating-linear-gradient(-45deg, transparent, transparent 5px, #000 5px, #000 10px)',
      'barber-pole-xs':
        'repeating-linear-gradient(-45deg, transparent, transparent 2px, #000 2px, #000 4px)',
      // 'map-area-stripes':
      //   'repeating-linear-gradient(-45deg, transparent, transparent 2px, hsla(214, 20%, 75%, 1) 2px, hsla(214, 20%, 75%, 1) 4px)',
      // 'map-bay-stripes':
      //   'repeating-linear-gradient(-45deg, transparent, transparent 1px, hsla(198, 31%, 88%,1) 1px,  hsla(198, 31%, 88%,1) 2px)',
      // 'map-designer-striped':
      //   'repeating-linear-gradient(-45deg, transparent, transparent 3px, hsla(198, 31%, 88%,1) 3px,  hsla(198, 31%, 88%,1) 6px)',
    },
    backgroundSize: {
      'barber-size-xs': '7px 7px', // sqrt(2) * 20px for a 45 degree angle
      'barber-size-sm': '14.14px 14.14px', // sqrt(2) * 20px for a 45 degree angle
      'barber-size': '28.28px 28.28px', // sqrt(2) * 20px for a 45 degree angle
      'barber-size-xl': '56.56px 56.56px',
    },
    scale: {
      '-1': '-1',
      flip: '-1',
      101: '1.01',
      102: '1.02',
      103: '1.03',
    },
    visibility: ['hover', 'focus', 'group-hover'],
    transitionDuration: {
      0: '0ms',
      400: '400ms',
      600: '600ms',
      2000: '2000ms',
    },
    animation: {
      slide: 'slide 4s linear infinite',
      slideRainbow: 'slideRainbow 5s linear infinite',
      barberpole: 'barberpole 4s linear infinite',
      spinny: 'spinny 100s linear infinite',
      'ants-left': 'ants-left 10s linear infinite',
      'ants-right': 'ants-right 10s linear infinite',
      'fade-in-down': 'fade-in-down 0.5s ease-out',
      'fade-out-down': 'fade-out-down 0.5s ease-out',
      'fade-in-up': 'fade-in-up 0.5s ease-out',
      'fade-out-up': 'fade-out-up 0.5s ease-out',
      'rotate-border': 'rotate-border 20s linear infinite',
      'rotate-border-360': 'rotate-360 3s linear infinite',
      'rotate-border-180': 'rotate-180 2.5s linear infinite',
      'rotate-border-90': 'rotate-90 2s linear infinite',
      appear: 'makeVisible 0ms linear 200ms forwards',
    },
    keyframes: {
      360: {
        '50%': { transform: 'rotate(-360deg)' },
      },
      wiggle: {
        '50%': { transform: 'rotate(3deg)' },
      },
      slide: {
        '0%': { opacity: '0', transform: 'translateX(15vw)' },
        '20%': { opacity: '1', transform: 'translateX(9vw)' },
        '80%': { opacity: '1', transform: 'translateX(-9vw)' },
        '100%': { opacity: '0', transform: 'translateX(-15vw)' },
      },
      slideRainbow: {
        to: {
          transform: 'translateX(50%)',
        },
        // from: {
        //   transform: 'translateX(-50%)',
        // },
      },
      spinny: {
        '0%': { backgroundPosition: '0% 0%' },
        '100%': { backgroundPosition: '100% 100%' },
      },
      barberpole: {
        '0%': {
          'background-position-x': '0',
        },
        '100%': {
          'background-position-x': '200px',
        },
      },
      'ants-left': {
        '0%': {
          'background-position-x': '0',
        },
        '100%': {
          /* Assuming --stripe is 10px, hence total pattern is 20px */
          'background-position-x': '20px',
        },
      },
      'ants-right': {
        '0%': {
          'background-position-x': '0',
        },
        '100%': {
          'background-position-x': '-20px',
        },
      },
      'rotate-border': {
        to: {
          '--angle': '360deg',
        },
      },
      'rotate-180': {
        to: {
          '--angle': '180deg',
        },
      },
      'rotate-90': {
        to: {
          '--angle': '180deg',
        },
      },
      'fade-in-down': {
        '0%': {
          opacity: '0',
          transform: 'translateY(-10px)',
        },
        '100%': {
          opacity: '1',
          transform: 'translateY(0)',
        },
      },
      'fade-out-down': {
        from: {
          opacity: '1',
          transform: 'translateY(0px)',
        },
        to: {
          opacity: '0',
          transform: 'translateY(10px)',
        },
      },
      'fade-in-up': {
        '0%': {
          opacity: '0',
          transform: 'translateY(10px)',
        },
        '100%': {
          opacity: '1',
          transform: 'translateY(0)',
        },
      },
      'fade-out-up': {
        from: {
          opacity: '1',
          transform: 'translateY(0px)',
        },
        to: {
          opacity: '0',
          transform: 'translateY(10px)',
        },
      },
      makeVisible: {
        '0%': {
          visibility: 'hidden',
        },
        '100%': {
          visibility: 'visible',
        },
      },
    },
    triangles: {
      // defaults to {}
      left: {
        direction: 'left', // one of 'left', 'right', 'up', 'down', 'left-up', 'left-down', 'right-up', and 'right-down'
        size: '20px', // defaults to defaultSize
        height: '10px', // defaults to half the size; has no effect on the diagonal directions (e.g. 'left-up')
        // color: '#08090A', // defaults to defaultColor
      },
    },
  },
};
export default themeWareBeeDark;
