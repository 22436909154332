import { LayoutImportLocation } from '@warebee/frontend/data-access-api-graphql';
import { getLocationKey } from '@warebee/shared/data-access-layout-import-converter';

export function getDefaultLocation(area: string): LayoutImportLocation {
  const locationId = `${area}-L-01`;
  const warehouseArea = area;
  const locationAisle = 'A-01';
  const locationBay = 1;
  const baySide = 'LEFT';
  const defaultValue = 'DEFAULT';

  const locationBayId = `${warehouseArea}-${locationAisle}-${baySide}-${locationBay}`;
  const loc: Omit<LayoutImportLocation, 'locationKey'> = {
    locationId,
    locationOrder: '0',
    locationStatus: true,
    locationLevel: 1,
    locationLength: 100,
    locationWidth: 100,
    locationHeight: 100,
    locationWeight: 1000,
    locationAisle,
    locationBayId,
    locationBay: 1,
    locationBayPosition: 1,
    locationDepthPosition: 1,
    locationUsageType: defaultValue,
    locmhtype: defaultValue,
    locationRackingType: defaultValue,
    bayType: defaultValue,
    warehouseArea,
    baySide: baySide,
  };
  return { ...loc, locationKey: getLocationKey(loc) };
}
