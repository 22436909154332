import {
  LoadOrderSetDataDocument,
  OrderLineSearchFilter,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  OrderSetConverterConfig,
  OrderSetExportJobParams,
  OrderSetRow,
  generateExportFilename,
  getOrderSetTableRows,
  orderSetExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter, numberFilter } from '../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
} from '../simulation/store/abc/simulation.ABC.helper';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';
import useLoadOrderSetData from './hooks/useLoadOrderSetData';
import {
  orderSetItemsData,
  orderSetItemsDataState,
  orderSetItemsDataStatus,
} from './store/orderSet.state';
import useOrderSetDataTableConfig from './useOrderSetDataTableConfig';

const OrderSetDataView: React.FC<DatasetDataViewProps<OrderSetRow>> = props => {
  const { t } = useTranslation('dataset');
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const data = useRecoilValue(orderSetItemsData);
  const status = useRecoilValue(orderSetItemsDataStatus);
  const [dataState, setDataState] = useRecoilState(orderSetItemsDataState);
  const columnsConfig = useOrderSetDataTableConfig();
  const [loadCallback, cancelLoad] = useLoadOrderSetData();
  const [runExportCSV] = useRunExportJobMutation();

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filter: OrderLineSearchFilter = {
      sku: containsFilter(searchValues['sku']),
      consignee: containsFilter(searchValues['consignee']),
      orderId: containsFilter(searchValues['orderId']),
      orderLine: numberFilter(searchValues['orderLine']),
      quantity: numberFilter(searchValues['quantity']),
      customer: containsFilter(searchValues['customer']),
      deliveryRoute: containsFilter(searchValues['deliveryRoute']),
      dock: containsFilter(searchValues['dock']),
      stagingArea: containsFilter(searchValues['stagingArea']),
      waveId: containsFilter(searchValues['waveId']),
      shipmentId: containsFilter(searchValues['shipmentId']),
      // loadByDate:  containsFilter(searchValues['loadByDate']),
    };

    cancelLoad();
    loadCallback({
      orderSetId: props.id,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props?.id, dataState.searchValues]);

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  const productCategories = getProductCategories(t);

  const converterConfig: OrderSetConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
    },
  };

  const flattenData = getOrderSetTableRows(data, converterConfig);

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`dataset-orderset`,
      brandTitleState,
      warehouse,
      warehouseIdState,
    });

    const variables: OrderSetExportJobParams = {
      ...orderSetExportJobParams,
      query: LoadOrderSetDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        orderSetId: props.id,
        page: null,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;
  const totalCount = dataState?.totalCount ?? 0;

  return (
    <DatasetTable
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t(`{{totalCount}} Orders`, {
        totalCount,
      })}
      title={props.title}
      isSticky
      id={props?.id}
      columnsConfig={columnsConfig}
      keyFields={['id', 'orderLine']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      onStartExportClick={startExportCSV}
    />
  );
};

export default OrderSetDataView;
