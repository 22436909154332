import { InferResult, QueryCreator, sql } from 'kysely';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { DatasetDatabase } from '../../../feed/store/datasetQueries/queryBuilder/datasetQueryBuilder';
import {
  DATASET_QUERY_VALUE_CONCATENATION,
  DatasetQueryBuilderParams,
} from './datasetQueryBuilder.types';

export const ITEM_EXTRA_FIELD_PREFIX = 'item_extra';
export type ItemDataRows = InferResult<ReturnType<typeof getItemsQueryBuilder>>;
export type ItemDataRow = ItemDataRows[number];
export type ItemsDataColumn = keyof ItemDataRow;

export function getItemsQueryBuilder<T extends DatasetDatabase>(
  params: DatasetQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___item_set___')
    .where(({ eb }) => eb('datasetObjectId', '=', params.datasetId as any)) //TODO: Kysely generic db issue
    .selectAll()
    .select(({ fn }) => [
      sql<string>`${sql.ref('consignee')} || '${sql.raw(DATASET_QUERY_VALUE_CONCATENATION)}' || ${sql.ref('sku')} `.as(
        'skuKey',
      ),
      ..._.map(params.extraFields, fd =>
        sql<
          number | string | Date | boolean
        >`json_value(${sql.ref('raw_data')}, 'strict $."${sql.raw(fd.name)}"' returning ${sql.raw(fd.type?.[0])})`.as(
          (fd.alias ?? `itemExtra${nanoid(8)}`) as `itemExtra${string}`,
        ),
      ),
    ]);
  return at;
}
