import {
  QueryDatasetDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  DatasetQueryConverterConfig,
  DatasetQueryExportJobParams,
  generateExportFilename,
  getDatasetQueryDataRows,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import DatasetTable from '../components/DatasetTable';
import {
  AnalyzeGatesStatsRow,
  getAnalyzeGatesStatsQuery,
} from '../feed/store/datasetQueries/analyzeGatesStatsQuery';
import { analyzeEndpointsStats } from '../simulation/store/analyze.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

import useAnalyzeResultEndpointDistributionTableConfig from './useAnalyzeResultEndpointDistributionTableConfig';

export type AnalyzedProductsTableProps = {
  analyzeId: string;
};

const AnalyzeResultEndpointDistributionTable: React.FC<
  AnalyzedProductsTableProps
> = props => {
  const { t } = useTranslation('simulation');
  const { analyzeId } = props;

  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const warehouseId = useRecoilValue(warehouseSelectedId);
  const endpoints = useRecoilValue(analyzeEndpointsStats);
  // const [tableState, setTableState] = useRecoilState(
  //   analyzeLocationProductsTableState,
  // );

  const [runExportCSV] = useRunExportJobMutation();
  const columnsConfig = useAnalyzeResultEndpointDistributionTableConfig();

  const converterConfig: DatasetQueryConverterConfig<AnalyzeGatesStatsRow> = {
    columnsConfig,
  };
  const query = getAnalyzeGatesStatsQuery({
    analyzeId,
  }).compile();

  const filename = generateExportFilename({
    exportTitle: t`analyse-endpoints`,
    brandTitleState,
    warehouse,
    warehouseIdState,
    sim,
  });

  async function startExportCSV() {
    const variables: DatasetQueryExportJobParams<AnalyzeGatesStatsRow> = {
      converterId: 'DATASET_QUERY',
      query: QueryDatasetDocument.loc.source.body,
      config: converterConfig,
      variables: {
        warehouseId,
        query: query.sql,
        params: query.parameters,
      },
      filename: `${filename}.csv`,
    };

    const { data, errors } = await runExportCSV({
      variables,
    });
    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = false;
  const itemsCount = _.size(endpoints);
  const totalCount = _.size(endpoints);

  const flattenData = getDatasetQueryDataRows(endpoints, converterConfig);

  return (
    <DatasetTable
      id={'analyze-result-endpoint-distribution-table'}
      hideScreenTitle
      isSticky
      isActionable
      columnsConfig={columnsConfig as any}
      keyFields={['locationId']}
      data={flattenData}
      onLoadNext={_.noop}
      onSearch={_.noop}
      sortBy={{}}
      onSort={_.noop}
      totalCount={totalCount}
      searchValues={{}}
      isLoading={isLoading}
      onRowSelect={_.noop}
      onStartExportClick={startExportCSV}
      hasCounter
    />
  );
};

export default AnalyzeResultEndpointDistributionTable;
