import { LayoutBayLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getDefaultMeasure, getMeasureTitle } from '../../common/measureHelper';
import useFormatter from '../../common/useFormatter';
import { cn } from '../../common/utils';
import PanelContainer from '../../containers/PanelContainer';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { WarehouseNavigator } from '../WarehouseNavigator';
import { WarehouseNavigatorItem } from '../WarehouseNavigatorItem';
import DropdownSelector from '../actions/DropdownSelector';
import { Container } from '../layout/ContainerFlex';
import TitleSection from '../layout/TitleSection';
import { StatGroup } from '../stats/StatGroup';
import { StatListItem } from '../stats/StatListItem';

export type LocationPropertiesPanelProps = PropsWithChildren & {
  location: LayoutBayLocationFragment;
};

const LocationPropertiesPanel: React.FC<
  LocationPropertiesPanelProps
> = props => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('app');

  const ms = useRecoilValue(warehouseMeasurementSystem);

  const { location } = props;
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(ms, 'size'),
    tMeasures,
  );

  const formatter = useFormatter();

  const locationVolumeCalculatedRaw =
    location.locationWidth * location.locationHeight * location.locationLength;

  const locationVolumeCalculated = formatter.formatVolume(
    locationVolumeCalculatedRaw,
  );

  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem
          name={t`Level`}
          value={location.locationLevel}
          active
        />
        <WarehouseNavigatorItem
          name={t`Location`}
          value={location.locationId}
          active
        />
      </WarehouseNavigator>
      <Container col fullHeight flex1 hasOverflowY>
        <PanelContainer
          data-component="panel-object-property-location"
          id="panel-object-property-location"
          title={t`Location Properties`}
          collapsible
          hasPadding={false}
        >
          <TitleSection inPanelView title={t`Status`} className={cn('my-2')} />
          <DropdownSelector
            className={cn('my-2 text-xs')}
            panelMode
            label={t`Status`}
            value={location.locationStatus ? t`Enabled` : t`Disabled`}
            values={['Disabled', 'Enabled']}
            disabled={true}
          />
          <TitleSection
            inPanelView
            title={t`Properties`}
            className={cn('mb-2 mt-4')}
          />
          <StatListItem
            title={t`Location Profile`}
            value={location.locationRackingType ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
            multiline
          />
          <StatListItem
            title={t`Location Index from front`}
            value={location.locationIndexFromFront ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
            multiline
          />
          <StatListItem
            title={t`X`}
            value={location.portals?.[0]?.position?.x ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
            multiline
          />
          <StatListItem
            title={t`Y`}
            value={location.portals?.[0]?.position?.y ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
            multiline
          />
          {!_.isNil(location.congestionZone) && (
            <StatListItem
              title={t`Work Zone`}
              value={location.congestionZone}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              multiline
            />
          )}
          {!_.isNil(location.locationUsageType) && (
            <StatListItem
              title={t`Usage Type`}
              value={location.locationUsageType}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              multiline
            />
          )}
          <StatListItem
            title={t`Picking Order`}
            value={location.locationOrder ?? '—'}
            titleClass={'text-xs'}
            valueClass={'text-xxs'}
            multiline
          />
          <TitleSection
            inPanelView
            title={t`Size`}
            className={cn('mb-2 mt-4')}
          />

          <StatGroup className="">
            <StatListItem
              titleSelectable
              title={t`Width`}
              value={location.locationWidth}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Height`}
              value={location.locationHeight}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Length`}
              value={location.locationLength}
              unitOfMeasure={sizeMeasureValue}
              styleInput
            />
            <StatListItem
              titleSelectable
              title={t`Volume`}
              value={locationVolumeCalculated.value}
              valueRaw={locationVolumeCalculated.raw}
              unitOfMeasure={locationVolumeCalculated.unit}
              styleInput
            />
            {location.locationHeightFromFloor > 0 && (
              <StatListItem
                titleSelectable
                title={t`HFF.`}
                value={location.locationHeightFromFloor}
                styleInput
                // unitOfMeasure={locationVolume.unit}
              />
            )}
          </StatGroup>
        </PanelContainer>
        {props.children}
      </Container>
    </>
  );
};

export default LocationPropertiesPanel;
