import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { executeDatasetQuery } from '../../feed/store/feed.helper';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  getSimulationHqDataRowsQuery,
  getSimulationHqDataTotalQuery,
  SimulationHqQueryBuilderPagedParams,
} from '../datasetQueries/simulationHqDataRows';
import {
  simulationHqTableData,
  simulationHqTableDataLoadStatus,
  simulationHqTableDataState,
  simulationHqTableTotalCount,
} from '../store/simulation.hq.state';

export type LoadSimulationHqTableDataParams =
  SimulationHqQueryBuilderPagedParams & {
    isAppend?: boolean;
  };

function useLoadSimulationHqTableData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Simulation Hq Data`;

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(simulationHqTableDataLoadStatus);
    reset(simulationHqTableData);
    reset(simulationHqTableDataState);
    reset(simulationHqTableTotalCount);
  });

  const initLoading = useRecoilCallback(
    ({ set, reset }) =>
      async (params: LoadSimulationHqTableDataParams) => {
        set(simulationHqTableDataLoadStatus, AsyncLoadStatus.Loading);
        if (!params.isAppend) {
          reset(simulationHqTableData);
          reset(simulationHqTableTotalCount);
        }
      },
  );

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadSimulationHqTableDataParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(simulationHqTableData);
        let totalCount = await snapshot.getPromise(simulationHqTableTotalCount);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(simulationHqTableDataLoadStatus, AsyncLoadStatus.Error);
        }

        if (!params.isAppend) {
          const compiledQueryTotal =
            getSimulationHqDataTotalQuery(params).compile();

          executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,
            comment: '[simulation] Hq total rows',
          }).then(([totalResult]) => {
            totalCount =
              totalResult && totalResult.length > 0
                ? totalResult[0].totalCount
                : 0;
            set(simulationHqTableTotalCount, totalCount);
          });
        }

        let rows = [];
        const compiledQuery = getSimulationHqDataRowsQuery(params).compile();

        [rows] = await executeDatasetQuery({
          warehouseId,
          compiledQuery,
          comment: '[simulation] Hq table data',
        });

        if (params.isAppend) {
          rows = [...current, ...rows];
        }
        set(simulationHqTableData, rows);
        set(simulationHqTableDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function call(params: LoadSimulationHqTableDataParams) {
    await initLoading(params);
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  return [call, reset] as const;
}

export default useLoadSimulationHqTableData;
