import {
  LoadWorkforceDataDocument,
  LoadWorkforceDataQuery,
  LoadWorkforceDataQueryVariables,
  PicklistEventType,
  ResourceUsageSummaryFragment,
  ResourceUsageSummaryGroupBy,
  ResourceUsageSummaryKey,
} from '@warebee/frontend/data-access-api-graphql';
import { addSeconds, getDay, isEqual, startOfDay, startOfWeek } from 'date-fns';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { TwTheme } from '../../../Tw';
import { secureClient } from '../../GraphQLClient';
import { getBestContrastColor } from '../../common/color.helper';
import {
  DayOfWeek,
  daysOfWeek,
  toDateFromLocaleStringDate,
  toDateFromLocaleStringTime,
  toLocaleDateString,
  toLocaleDateTimeString,
} from '../../common/dateTimeHelper';
import {
  formatDurationHoursShort,
  formatInteger,
  formatTime,
  formatToPrecision,
} from '../../common/formatHelper';
import { getIndexedTitle } from '../../common/utils';
import {
  AgentSettingsWithMeta,
  agentLabourEvents,
  agentLabourMetadataPropertyName,
} from '../../resourcePolicy/agentData/agent.types';
import { EventGroupExtendedValues } from '../panels/OptimisationEventsComparePanel';
import { getLabourEventTitle } from './pickingPolicy/pickingPolicy.helper';
import {
  getLabourEventColor,
  getLabourEventTextColor,
} from './resourcePolicy.helper';
import {
  EventGroupKeys,
  EventGroupMap,
  getEventGroupColor,
  getEventGroupColorText,
  getEventGroupTitle,
} from './simulation.helper';
import {
  AgentForecastWorkWindow,
  AgentWorkforceDrillDownData,
  AgentWorkforceEventsAggregatedMap,
  AgentWorkforceEventsByPeriod,
  AgentWorkforcePeriodType,
  AgentWorkforceSimulationSettings,
  AgentWorkforceSimulationSettingsItem,
  DRILL_KEY_IDLE,
  DRILL_KEY_OVERTIME,
  DRILL_KEY_TOTAL,
  DRILL_KEY_WORK_CUSTOM_TOTAL,
  DRILL_KEY_WORK_TOTAL,
  WorkforceDailyWorkWindows,
  WorkforceDrillType,
  WorkforceKpiType,
  WorkforceLabourDrillKey,
  WorkforceShiftSettings,
  WorkforceSimulationsType,
  WorkforceSpecialDrillKey,
  getLabourDrillKey,
  getLabourEventKey,
  isLabourDrillKey,
  isWorkforceSpecialDrillKey,
  scaleEvents,
  sumEvents,
} from './workforce.types';

const forecastColors = TwTheme.extend.colors.workforceForecast;
const eventGroupsColor = TwTheme.extend.colors.eventGroups;
const eventGroupTextColor = TwTheme.extend.colors.eventGroupsTxt;
const dimensionsColors = _.values(TwTheme.extend.colors.dimensionsColors);
let policyColorIndex = 0;

const dimensionColorIndex = 0;

const dimensionsColorsCount = dimensionsColors.length;

export type LoadWorkforceDataParams = {
  analyzeId;
  drillKeys: ResourceUsageSummaryGroupBy[];
  agentIds?: string[];
  eventTypeFilter?: PicklistEventType[];
  from?: Date;
  to?: Date;
};

export async function loadWorkforceData(params: LoadWorkforceDataParams) {
  try {
    const response = await secureClient.query<
      LoadWorkforceDataQuery,
      LoadWorkforceDataQueryVariables
    >({
      query: LoadWorkforceDataDocument,
      variables: {
        analyzeId: params.analyzeId,
        agentsId: params.agentIds,
        drill: [ResourceUsageSummaryGroupBy.PICK_BY_DATE, ...params.drillKeys],
        eventType: params.eventTypeFilter,
        from: _.isNil(params.from) ? null : toLocaleDateTimeString(params.from),
        to: _.isNil(params.from) ? null : toLocaleDateTimeString(params.to),
      },
    });

    if (!_.isEmpty(response.errors)) {
      console.error('Cannot load resource usage summary', response.errors);
      throw new Error('Cannot load resource usage summary');
    }
    return response.data.analyzeResult?.resourceUsageSummary;
  } catch (ex) {
    console.error('Cannot load resource usage summary', ex);
    throw new Error('Cannot load resource usage summary');
  }
}

export function getSimulationSettings(
  event: AgentWorkforceEventsByPeriod,
  simulationSettings: AgentWorkforceSimulationSettings,
) {
  // current simple implementation,
  // we pick first simulation settings,
  // witch absolute range include  event's start time

  const setting = simulationSettings.find(
    setting =>
      setting.period.absolute.start <= event.periodStart.getTime() &&
      setting.period.absolute.end >= event.periodStart.getTime(),
  );
  return setting;
}

export function getEventGroupingDate(
  event: AgentWorkforceEventsByPeriod,
  periodType: AgentWorkforcePeriodType,
) {
  switch (periodType) {
    case 'daily':
      return startOfDay(event.periodStart);
    case 'weekly':
      return startOfWeek(event.periodStart);
  }
  return event.periodStart;
}

export function applySimulationSettings(
  periodData: AgentWorkforceEventsByPeriod,
  dailyAggregatedSettings: WorkforceDailyWorkWindows,
  agent: AgentSettingsWithMeta,
): AgentWorkforceEventsByPeriod {
  const timeTotalPlanned = dailyAggregatedSettings?.totalDuration ?? 0;
  const agentAmount =
    dailyAggregatedSettings?.totalSecondsOperation > 0
      ? (dailyAggregatedSettings?.totalDuration ?? 0) /
        dailyAggregatedSettings?.totalSecondsOperation
      : 0;
  const costPerSecond =
    dailyAggregatedSettings?.totalDuration > 0
      ? (dailyAggregatedSettings?.totalCost ?? 0) /
        dailyAggregatedSettings?.totalDuration
      : 0;
  const timeTotalCurrent = periodData.totalDuration;

  const labourEventsDurations = _(agentLabourEvents)
    .filter(
      event => agent.metadata?.[agentLabourMetadataPropertyName]?.[event] > 0,
    )
    .reduce(
      (acc, event) => ({
        ...acc,
        [getLabourDrillKey(event)]:
          agentAmount *
          agent.metadata?.[agentLabourMetadataPropertyName]?.[event],
      }),
      {} as Record<WorkforceLabourDrillKey, number>,
    );

  const labourEventsTotal = _.sum(_.values(labourEventsDurations));
  const total = timeTotalCurrent + labourEventsTotal;
  const duration: Record<WorkforceSpecialDrillKey | string, number> = {
    ...periodData.duration,
    ...labourEventsDurations,
    [DRILL_KEY_WORK_TOTAL]: timeTotalCurrent,
    [DRILL_KEY_WORK_CUSTOM_TOTAL]: labourEventsTotal,
    [DRILL_KEY_TOTAL]: total,
    [DRILL_KEY_IDLE]: Math.max(0, timeTotalPlanned - total),
    [DRILL_KEY_OVERTIME]: Math.max(0, total - timeTotalPlanned),
  };
  const cost = scaleEvents(duration, costPerSecond);

  return {
    ...periodData,
    agentAmount: agentAmount,
    costPerSecond: costPerSecond,
    secondsOperation: dailyAggregatedSettings?.totalSecondsOperation ?? 0,
    duration,
    cost,
  };
}

export function getAggregatedEvents(params: {
  events: AgentWorkforceEventsByPeriod[];
  simulationSettings: AgentWorkforceSimulationSettings;
  periodType: AgentWorkforcePeriodType;
  agent: AgentSettingsWithMeta;
  defaultAgentSettings: AgentWorkforceSimulationSettingsItem;
  dailyWorkWindows: WorkforceDailyWorkWindows[];
}): AgentWorkforceEventsAggregatedMap {
  // for each event by Period
  // find simulation settings
  // apply simulation settings to re-calculate idle, overtime,  cost etc.
  // find destination grouping date interval
  // add event values to grouped

  const sorted = _.sortBy(params.simulationSettings, s => s.sortIndex);

  let registeredShifts: Record<string, Object> = {};

  return params.events.reduce((acc, periodEvent) => {
    const settings: AgentWorkforceSimulationSettingsItem =
      getSimulationSettings(periodEvent, sorted) ?? params.defaultAgentSettings;

    const eventDay = periodEvent.periodStart;
    const groupDate = getEventGroupingDate(periodEvent, params.periodType);
    const groupDateKey = groupDate.toString();
    const periodDayKey = startOfDay(periodEvent.periodStart).toString();

    const shouldAddShift = !_.has(registeredShifts, [
      groupDateKey,
      periodDayKey,
    ]);
    registeredShifts = {
      ...registeredShifts,
      [groupDateKey]: {
        ...(registeredShifts[groupDateKey] ?? {}),
        [periodDayKey]: true,
      },
    };

    const current = acc[groupDateKey] ?? {
      totalShiftsDuration: 0,
      totalShiftsCost: 0,
      aggregatedShift: 0,
      aggregatedShiftWithoutLabourEvents: 0,
      cost: {} as EventGroupExtendedValues,
      duration: {} as EventGroupExtendedValues,
      picklists: {},
      forecastIds: [],
      dimensions: {},
    };

    const dailyAggregatedSettings: WorkforceDailyWorkWindows = _.find(
      params.dailyWorkWindows,
      s => isEqual(s.day, eventDay),
    );
    const simulatedEventsByPeriod = applySimulationSettings(
      periodEvent,
      dailyAggregatedSettings,
      params.agent,
    );

    const secondsPlannedTotal =
      simulatedEventsByPeriod.secondsOperation *
      simulatedEventsByPeriod.agentAmount;
    const costPlannedTotal =
      secondsPlannedTotal * simulatedEventsByPeriod.costPerSecond;
    const avgFactor =
      simulatedEventsByPeriod.agentAmount > 0
        ? 1 / simulatedEventsByPeriod.agentAmount
        : 0;

    const labourEventAvg =
      simulatedEventsByPeriod.agentAmount > 0
        ? simulatedEventsByPeriod.duration[DRILL_KEY_WORK_CUSTOM_TOTAL] /
          dailyAggregatedSettings.avgAgentAmount
        : 0;

    return {
      ...acc,
      [groupDateKey]: {
        periodStart: groupDate,
        periodType: params.periodType,
        aggregatedShift:
          current.aggregatedShift +
          (shouldAddShift ? simulatedEventsByPeriod.secondsOperation : 0),
        aggregatedShiftWithoutLabourEvents:
          current.aggregatedShiftWithoutLabourEvents +
          (shouldAddShift
            ? simulatedEventsByPeriod.secondsOperation - labourEventAvg
            : 0),
        totalShiftsDuration:
          current.totalShiftsDuration +
          (shouldAddShift ? secondsPlannedTotal : 0),
        totalShiftsCost:
          current.totalShiftsCost + (shouldAddShift ? costPlannedTotal : 0),
        picklists: sumEvents(
          current.picklists,
          simulatedEventsByPeriod.picklists,
        ),
        cost: sumEvents(current.cost, simulatedEventsByPeriod.cost),
        duration: sumEvents(current.duration, simulatedEventsByPeriod.duration),
        durationAvg: sumEvents(
          current.duration,
          scaleEvents(simulatedEventsByPeriod.duration, avgFactor),
        ),
        forecastIds: [...current.forecastIds, settings.id],
        dimensions: {
          ...current.dimensions,
          ...simulatedEventsByPeriod.dimensions,
        },
      },
    };
  }, {} as AgentWorkforceEventsAggregatedMap);
}

export function getAgentSimulationDefaults(params: {
  agent: AgentSettingsWithMeta;
  analyzePeriod: [Date, Date];
  id?: string;
  title: string;
  sortIndex: number;
  isDefault?: boolean;
}): AgentWorkforceSimulationSettingsItem {
  const shift = getWorkforceShiftSettingsDefault(params.agent);
  console.info('agent::', params.agent);

  return {
    isDefault: params.isDefault,
    shiftSettings: {
      [shift.id]: shift,
    },

    id: params.id ?? nanoid(),
    agentId: params.agent.id,
    title: params.title,
    labourSettings:
      params.agent.metadata?.[agentLabourMetadataPropertyName] ?? {},
    period: {
      absolute: {
        start: params.analyzePeriod[0].getTime(),
        end: params.analyzePeriod[1].getTime(),
      },
    },
    sortIndex: params.sortIndex,
    color: forecastColors[params.sortIndex] ?? forecastColors.default,
  };
}

export function getWorkforceSimulationTypeTitle(
  simulationType: WorkforceSimulationsType,
  t: TFunction<'simulation'>,
) {
  switch (simulationType) {
    case 'simple':
      return t('Simple', { ns: 'simulation' });
    case 'advanced':
      return t('Advanced', { ns: 'simulation' });
  }
}

export function getWorkforceAggregationPeriodTitle(
  periodType: AgentWorkforcePeriodType,
  t: TFunction<'simulation'>,
) {
  switch (periodType) {
    case 'daily':
      return t('Working Days', { ns: 'simulation' });
    case 'dailyCalendar':
      return t('Calendar Days', { ns: 'simulation' });
    case 'weekly':
      return t('Weekly Work', { ns: 'simulation' });
  }
}

export function getWorkforceKpiTitle(
  kpi: WorkforceKpiType,
  t: TFunction<'simulation'>,
) {
  switch (kpi) {
    case 'avg':
      return t('Agent Average', { ns: 'simulation' });
    case 'sum':
      return t('Agents Total', { ns: 'simulation' });
    case 'fte':
      return t('Agent FTE', { ns: 'simulation' });
    case 'picklists':
      return t('Picklists', { ns: 'simulation' });
  }
}

export function getWorkforceSpecialDrillValueTitle(
  drill: WorkforceSpecialDrillKey,
  t: TFunction<'simulation'>,
) {
  switch (drill) {
    case DRILL_KEY_WORK_TOTAL:
      return t('Picking Total', { ns: 'simulation' });
    case DRILL_KEY_TOTAL:
      return t('Total', { ns: 'simulation' });
    case DRILL_KEY_IDLE:
      return t('Idle', { ns: 'simulation' });
    case DRILL_KEY_OVERTIME:
      return t('Overtime', { ns: 'simulation' });
    case DRILL_KEY_WORK_CUSTOM_TOTAL:
      return t('Custom Events', { ns: 'simulation' });
  }
}

export function getWorkforceDrillDownTitle(
  drill: WorkforceDrillType,
  t: TFunction<'simulation'>,
) {
  switch (drill) {
    case ResourceUsageSummaryGroupBy.EVENT_TYPE:
      return t('Event', { ns: 'simulation' });
    case ResourceUsageSummaryGroupBy.ORDER_LINE_RULE:
      return t('Policy', { ns: 'simulation' });
    case ResourceUsageSummaryGroupBy.PLANE:
      return t('Floor', { ns: 'simulation' });
    case ResourceUsageSummaryGroupBy.PICKLIST_GROUP_KEY:
      return t('Rule Group', { ns: 'simulation' });
    case ResourceUsageSummaryGroupBy.PICK_BY_DATE:
      return t('Deadline', { ns: 'simulation' });
  }
}

export function formatMetricValue(
  v: number,
  metric: WorkforceKpiType,
  tMeasure: TFunction<'measures'>,
) {
  switch (metric) {
    case 'avg':
    case 'sum':
      return formatDurationHoursShort(tMeasure, v * 1000);
    //return formatDurationHours(v * 1000);
    case 'fte':
      return formatToPrecision(v ?? 0, 1, 100, 1);
    case 'picklists':
      return formatInteger(v ?? 0);
  }
}

export function getDimensionTitle(
  dimensionKey: string,
  drillType: WorkforceDrillType,
  pickingRuleMap: Record<string, string>,
  planeMap: Record<string, string>,
  pickingGroupMap: Record<string, string>,
  t: TFunction<'simulation'>,
) {
  if (isLabourDrillKey(dimensionKey)) {
    return getLabourEventTitle(getLabourEventKey(dimensionKey), t);
  }

  if (isWorkforceSpecialDrillKey(dimensionKey)) {
    return getWorkforceSpecialDrillValueTitle(dimensionKey, t);
  }

  switch (drillType) {
    case ResourceUsageSummaryGroupBy.EVENT_TYPE:
      return getEventGroupTitle(dimensionKey as any, t);
    case ResourceUsageSummaryGroupBy.ORDER_LINE_RULE:
      return pickingRuleMap?.[dimensionKey];
    case ResourceUsageSummaryGroupBy.PLANE:
      return planeMap?.[dimensionKey];
    case ResourceUsageSummaryGroupBy.PICKLIST_GROUP_KEY:
      return pickingGroupMap?.[dimensionKey];
    default:
      return dimensionKey;
  }
}

export function reduceAgentEvents(
  agentEvents: ResourceUsageSummaryFragment[],
  drillDown: ResourceUsageSummaryGroupBy,
): Record<string, AgentWorkforceDrillDownData> {
  switch (drillDown) {
    case ResourceUsageSummaryGroupBy.EVENT_TYPE:
      return reduceByEventGroup(agentEvents);
    case ResourceUsageSummaryGroupBy.ORDER_LINE_RULE:
      return reduceByPicklistProp(agentEvents, key => key.orderLineRuleId);
    case ResourceUsageSummaryGroupBy.PLANE:
      return reduceByPicklistProp(agentEvents, key => key.planeId);
    case ResourceUsageSummaryGroupBy.PICKLIST_GROUP_KEY:
      return reduceByPicklistProp(agentEvents, key => key.picklistGroupKey);
    case ResourceUsageSummaryGroupBy.PICK_BY_DATE:
      return reduceByPicklistProp(agentEvents, key =>
        formatTime(toDateFromLocaleStringDate(key.pickByDate)),
      );
    default:
      throw new Error('Unsupported drill down type ');
  }
}

export function reduceByEventGroup(
  agentEvents: ResourceUsageSummaryFragment[],
): Record<string, AgentWorkforceDrillDownData> {
  return agentEvents.reduce(
    (acc, e) => {
      const period = toLocaleDateString(
        startOfDay(toDateFromLocaleStringDate(e.key.pickByDate)),
      );
      const defaultGroupData = {
        period,
        totalOrders: 0,
        totalPicklists: 0,
        totalDuration: 0,
        dimensions: {},
      };
      const periodData = acc[period] ?? defaultGroupData;
      const groupKey = EventGroupMap[e.key.eventType];
      return {
        ...acc,
        [period]: {
          ...periodData,
          //totalOrders: Math.max(periodData.totalOrders, e.totalOrders),
          totalPicklists: Math.max(periodData.totalPicklists, e.totalPicklists),
          totalDuration: periodData.totalDuration + e.totalDuration,
          dimensions: {
            ...periodData.dimensions,
            [groupKey]: null,
          },
          duration: {
            ...(acc[period]?.duration ?? {}),
            [groupKey]:
              (acc[period]?.duration?.[groupKey] ?? 0) + e.totalDuration,
          },
          picklists: {
            ...(acc[period]?.picklists ?? {}),
            [groupKey]: Math.max(
              acc[period]?.picklists?.[groupKey] ?? 0,
              e.totalPicklists,
            ),
          },
        },
      };
    },
    {} as Record<string, AgentWorkforceDrillDownData>,
  );
}

export function reduceByPicklistProp(
  agentEvents: ResourceUsageSummaryFragment[],
  getDimensionValue: (eventKey: ResourceUsageSummaryKey) => string,
): Record<string, AgentWorkforceDrillDownData> {
  return agentEvents.reduce(
    (acc, e) => {
      const period = toLocaleDateString(
        startOfDay(toDateFromLocaleStringDate(e.key.pickByDate)),
      );
      const dimension = getDimensionValue(e.key); //e.key?.[getKey];
      return {
        ...acc,
        [period]: {
          period,
          totalOrders: 0, //  e.totalOrders + (acc[period]?.totalOrders ?? 0),
          totalPicklists: e.totalPicklists + (acc[period]?.totalPicklists ?? 0),
          totalDuration: e.totalDuration + (acc[period]?.totalDuration ?? 0),
          dimensions: {
            ...(acc[period]?.dimensions ?? {}),
            [dimension]: null,
          },
          duration: {
            ...(acc[period]?.duration ?? {}),
            [dimension]:
              (acc[period]?.duration?.[dimension] ?? 0) + e.totalDuration,
          },
          // orders: {
          //   ...(acc[period]?.cost ?? {}),
          //   [drillValue]:
          //     (acc[period]?.orders?.[drillValue] ?? 0) + e.totalOrders,
          // },
          picklists: {
            ...(acc[period]?.picklists ?? {}),
            [dimension]:
              (acc[period]?.picklists?.[dimension] ?? 0) + e.totalPicklists,
          },
        },
      };
    },
    {} as Record<string, AgentWorkforceDrillDownData>,
  );
}

export function getLabourDimensionColor(
  dimensionKey: WorkforceLabourDrillKey,
): [string, string] {
  const key = getLabourEventKey(dimensionKey);
  return [getLabourEventColor(key), getLabourEventTextColor(key)];
}

export const getDimensionColor = _.memoize(function (
  dimensionKey: string,
): [string, string] {
  if (dimensionKey === DRILL_KEY_IDLE)
    return [eventGroupsColor.idle, eventGroupTextColor.idle];
  if (dimensionKey === DRILL_KEY_OVERTIME)
    return [eventGroupsColor.overtime, eventGroupTextColor.overtime];
  if (dimensionKey === DRILL_KEY_TOTAL)
    return [eventGroupsColor.total, eventGroupTextColor.total];
  if (dimensionKey === DRILL_KEY_WORK_CUSTOM_TOTAL)
    return [eventGroupsColor.labourTotal, eventGroupTextColor.labourTotal];
  if (isLabourDrillKey(dimensionKey)) {
    return getLabourDimensionColor(dimensionKey);
  }
  if (_.includes(EventGroupKeys, dimensionKey)) {
    return [
      getEventGroupColor(dimensionKey as any),
      getEventGroupColorText(dimensionKey as any),
    ];
  }
  return [
    dimensionsColors[policyColorIndex++ % dimensionsColorsCount],
    getBestContrastColor(
      dimensionsColors[policyColorIndex++ % dimensionsColorsCount],
    ),
  ];
});

// export const getPolicyColor = _.memoize(function (
//   dimensionKey: string,
// ): [string, string] {
//   if (dimensionKey === DRILL_KEY_IDLE)
//     return [eventGroupsColor.idle, eventGroupTextColor.idle];
//   if (dimensionKey === DRILL_KEY_OVERTIME)
//     return [eventGroupsColor.overtime, eventGroupTextColor.overtime];
//   if (dimensionKey === DRILL_KEY_TOTAL)
//     return [eventGroupsColor.total, eventGroupTextColor.total];
//   if (dimensionKey === DRILL_KEY_WORK_CUSTOM_TOTAL)
//     return [eventGroupsColor.labourTotal, eventGroupTextColor.labourTotal];
//   if (isLabourDrillKey(dimensionKey)) {
//     return getLabourDimensionColor(dimensionKey);
//   }
//   if (_.includes(EventGroupKeys, dimensionKey)) {
//     return [
//       getEventGroupColor(dimensionKey as any),
//       getEventGroupColorText(dimensionKey as any),
//     ];
//   }
//   return [policyColors[policyColorIndex++ % policyColorsCount], 'white'];
// });

export function getForecastShifts(
  day: Date,
  forecastSettings: AgentWorkforceSimulationSettings,
): WorkforceDailyWorkWindows {
  const weekday = getDay(day);
  const defaultForecast = _.find(forecastSettings, f => f.isDefault);
  const forecastSetting =
    forecastSettings.find(
      setting =>
        !setting.isDefault &&
        setting.period.absolute.start <= day.getTime() &&
        setting.period.absolute.end >= day.getTime(),
    ) ?? defaultForecast;

  const shiftSettings: WorkforceShiftSettings[] = _.filter(
    forecastSetting?.shiftSettings,
    shift => _.has(shift.dayOfWeek, weekday),
  );

  if (_.isEmpty(shiftSettings)) {
    return null;
  }

  const currentDay = toLocaleDateString(day);
  const workWindowsBase: AgentForecastWorkWindow[] = _.map(
    shiftSettings,
    shift => {
      const from = toDateFromLocaleStringTime(shift.startTime, currentDay);
      const to = addSeconds(from, shift.secondsOperation);
      return {
        id: nanoid(),
        from,
        to,
        agentAmount: shift.agentAmount,
        secondsOperation: shift.secondsOperation,
        costPerSecond: shift.costPerSecond,
        shifts: [shift],
      };
    },
  );

  const times = _(workWindowsBase)
    .map(ww => [ww.from, ww.to])
    .flatten()
    .uniqBy(t => t.getTime())
    .sort(t => t.getTime())
    .value();

  let totalDuration = 0;
  let totalCost = 0;
  let totalSecondsOperation = 0;

  const workWindows: AgentForecastWorkWindow[] = [];

  for (let i = 0; i < times.length - 1; i++) {
    const from = times[i];
    const to = times[i + 1];
    const fitWorkWindows = _(workWindowsBase).filter(
      ww => from >= ww.from && from < ww.to,
    );
    const fitShifts = fitWorkWindows
      .map(w => w.shifts)
      .flatten()
      .value();

    if (_.isEmpty(fitShifts)) continue;

    const duration = (to.getTime() - from.getTime()) / 1000;
    const agentAmount = _.sumBy(fitShifts, w => w.agentAmount);
    const cost = _.sumBy(
      fitShifts,
      w => duration * w.agentAmount * w.costPerSecond,
    );

    totalDuration += agentAmount * duration;
    totalCost += cost;
    totalSecondsOperation += duration;

    workWindows.push({
      id: nanoid(),
      from,
      to,
      agentAmount: agentAmount,
      secondsOperation: duration,
      costPerSecond: cost / duration,
      shifts: fitShifts,
      shiftWindows: fitWorkWindows.value(),
    });
  }
  return {
    day,
    totalDuration,
    totalCost,
    totalSecondsOperation,
    avgAgentAmount:
      totalSecondsOperation > 0 ? totalDuration / totalSecondsOperation : 0,
    workWindows,
  };
}

export function getShiftColor(shift: WorkforceShiftSettings) {
  return getDimensionColor(shift.id);
  return ['green', 'white'];
}

export function getWorkforceShiftSettingsDefault(
  agent: AgentSettingsWithMeta,
  titles?: string[],
): WorkforceShiftSettings {
  const id = nanoid();
  const title = getIndexedTitle(new Set(titles ?? []), `Shift #`);

  return {
    id,
    title,
    agentAmount: agent.utilisation.agentAmount,
    costPerSecond: agent.cost?.costPerSecond ?? 0,
    secondsOperation: agent.utilisation.secondsOperation,
    dayOfWeek: _.keyBy(daysOfWeek) as Record<DayOfWeek, unknown>,
    startTime: '08:00',
  };
}
