import Flatten from '@flatten-js/core';
import {
  LayoutImportConverterAreaFragment,
  LayoutImportConverterDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AreaBuilderSettings,
  AreaLinkPointStatus,
  AreaLinkPointType,
  BaysAlignOption,
  BaysOrderInAisleOption,
  BayTypeSettings,
  LocationTypeSettings,
  OrderTypeOption,
  ShelfSettings,
  SpacerAreaSettings,
  SpacerPositionOption,
  SpacerTypeOption,
} from '@warebee/shared/data-access-layout-import-converter';
import { TFunction } from 'i18next';
import { AsyncLoadStatus } from '../../../../common/types';

export type GuidelineMode = 'box' | 'full';
export const areaActionsModes = [
  'none',
  'links',
  'add-area',
  'add-location',
] as const;

export type AreaActionsMode = (typeof areaActionsModes)[number];

export function getGuidelineModes(
  t: TFunction<'designer'>,
): Record<GuidelineMode, string> {
  return {
    box: t(`Basic Guides (Area Outlines)`, { ns: 'designer' }),
    full: t(`Advanced Guides`, { ns: 'designer' }),
  };
}
export function getSpacerTypeOptionMap(t: TFunction<'designer'>) {
  return new Map<SpacerTypeOption, string>([
    [SpacerTypeOption.Aisle, t(`Aisle`, { ns: 'designer' })],
    [SpacerTypeOption.Block, t(`Block`, { ns: 'designer' })],
  ]);
}

export function getSpacerPositionOptionMap(t: TFunction<'designer'>) {
  return new Map<SpacerPositionOption, string>([
    [SpacerPositionOption.Left, t(`Left`, { ns: 'designer' })],
    [SpacerPositionOption.Right, t(`Right`, { ns: 'designer' })],
  ]);
}

export function getBaysAlignOptionMap(t: TFunction<'designer'>) {
  return new Map<BaysAlignOption, string>([
    [BaysAlignOption.Bottom, t(`Bottom`, { ns: 'designer' })],
    [BaysAlignOption.Top, t(`Top`, { ns: 'designer' })],
  ]);
}

export function getOrderOptionsMap(t: TFunction<'designer'>) {
  return new Map<OrderTypeOption, string>([
    [OrderTypeOption.Default, t(`Default Order`, { ns: 'designer' })],
    [OrderTypeOption.Reverse, t(`Reverse Order`, { ns: 'designer' })],
    [OrderTypeOption.Shifted, t(`Shifted Order`, { ns: 'designer' })],
    [OrderTypeOption.Custom, t(`Custom Order`, { ns: 'designer' })],
  ]);
}

export function getBaysOrderInAisleOptionMap(t: TFunction<'designer'>) {
  return new Map<BaysOrderInAisleOption, string>([
    [BaysOrderInAisleOption.Parallel, t(`Parallel`, { ns: 'designer' })],
    [
      BaysOrderInAisleOption.ReverseParallel,
      t(`Reverse Parallel`, { ns: 'designer' }),
    ],
    [BaysOrderInAisleOption.RoundLeft, t(`Round Left`, { ns: 'designer' })],
    [BaysOrderInAisleOption.RoundRight, t(`Round Right`, { ns: 'designer' })],
  ]);
}

export type ConverterAreaIntersection = {
  area1Id: string;
  area2Id: string;
  shape: Flatten.Polygon;
};

export type ConverterBuilderState = {
  status: AsyncLoadStatus;
  updatedAt: Date;
};

export type LayoutImportConverterData = LayoutImportConverterDataFragment & {
  areas: LayoutImportConverterAreaFragment[];
};

export type LocationMeta = {
  locationId: string;
  areaId: string;
  bayId: string;
  level: number;
};

export type AreaLinkPoint = {
  id: string;
  areaId: string;
  type: AreaLinkPointType;
  status: AreaLinkPointStatus;
  connectedAreasIds: string[];
  x: number;
  y: number;
};

export type AreaBox = {
  areaId: string;
  box: Flatten.Box;
  points: AreaLinkPoint[];
};

export type ConvertLayoutParams = {
  areas: LayoutImportConverterAreaFragment[];
  areaBuilders: Record<string, AreaBuilderSettings>;
  bayTypes: Record<string, BayTypeSettings>;
  locationTypes: Record<string, LocationTypeSettings>;
  shelvesSettings: Record<string, Record<number, ShelfSettings>>;
  spacerAreas: Record<string, SpacerAreaSettings>;
};
