import { DatasetExtraFieldSettings } from '@warebee/frontend/data-access-api-graphql';
import { ItemSetDataTableColumn } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useAssignmentDataTableConfig from '../../../assignment/useAssignmentDataTableConfig';
import { ColumnConfig } from '../../../components/DatasetTable';
import useItemSetDataTableConfig from '../../../itemSet/useItemSetDataTableConfig';
import useLayoutDataTableConfig from '../../../layout/useLayoutDataTableConfig';
import { SimulationHqDataRow } from '../../datasetQueries/simulationHqQueryBuilder';

function useSimulationHqDataTableConfig(): ColumnConfig<SimulationHqDataRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const activityExtraFields: DatasetExtraFieldSettings[] = []; // = useRecoilValue(feedDatasetExtraFields);

  const itemsFieldSkip: Set<ItemSetDataTableColumn> = new Set([]);
  const locationFieldSkip: Set<string> = new Set(['locationId']);
  const assignmentFieldSkip: Set<string> = new Set([
    'consignee',
    'sku',
    'locationId',
  ]);

  const itemSetFields = useItemSetDataTableConfig().filter(
    f => !itemsFieldSkip.has(f.field),
  ) as ColumnConfig<Partial<SimulationHqDataRow>>[];

  const locationFields = useLayoutDataTableConfig().filter(
    f => !locationFieldSkip.has(f.field),
  ) as any as ColumnConfig<SimulationHqDataRow>[];

  const assignmentFields = useAssignmentDataTableConfig().filter(
    f => !assignmentFieldSkip.has(f.field),
  ) as any as ColumnConfig<SimulationHqDataRow>[];

  const feedExtraFields: ColumnConfig<SimulationHqDataRow>[] = _.map(
    activityExtraFields,
    f => ({
      field: f.alias as any,
      title: f.title ?? f.name,
      hasFilter: true,
      hasSearch: true,
    }),
  );

  const columnsConfig: ColumnConfig<SimulationHqDataRow>[] = [
    ...itemSetFields,
    ...locationFields,
    ...assignmentFields,
  ];

  return columnsConfig;
}
export default useSimulationHqDataTableConfig;
