import {
  FindLocationsStatsFilterDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  generateExportFilename,
  LocationsStatsConverterConfig,
  LocationsStatsExportJobParams,
  locationsStatsExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { DataPageParams } from '../common/types';
import InboxZero from '../components/InboxZero';
import { PanelHeaderProps } from '../components/designer/panels/PanelHeader';
import { viewerAislesMeta } from '../layout/viewer/store/viewer.state';
import { assignmentComplianceSelectedIdentity } from '../simulation/store/assignmentCompliance.state';
import { getLocationFilterMatchings } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyDocument,
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import {
  policyFilteredLocationsStatsSearchValues,
  policyLocationsStatsDimension,
} from '../simulation/store/policyFilter.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import LocationsStatsByRuleTableView, {
  LocationsStatsByRuleTableViewProps,
} from '../simulation/tableViews/LocationsStatsByRuleTableView';
import {
  getLocationsStatsDimensions,
  getLocationsStatsTableConfigDefault,
  locationStatsFixedDimensions,
  useLocationCapacitySpec,
} from '../simulation/tableViews/locationsStatsTable.config';
import { brandName } from '../store/global.state';
import {
  warehouseMeasurementSystem,
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

import useLoadAssignmentPolicyLocationsStatsByRule from './useLoadAssignmentPolicyLocationsStatsByRule';

export type AssignmentPolicyLocationsStatsByRuleTableViewProps = {
  panelHeader?: PanelHeaderProps;
};

const AssignmentPolicyLocationsStatsByRuleTableView: React.FC<
  AssignmentPolicyLocationsStatsByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const ms = useRecoilValue(warehouseMeasurementSystem);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const policySelectedDetails = useRecoilValue(
    assignmentPolicySelectedIdentity,
  );
  const complianceSelectedDetails = useRecoilValue(
    assignmentComplianceSelectedIdentity,
  );
  const searchValues = useRecoilValue(policyFilteredLocationsStatsSearchValues);
  const aislesMap = useRecoilValue(viewerAislesMeta);
  const selectedDetails = policySelectedDetails ?? complianceSelectedDetails;
  const rule = useRecoilValue(assignmentPolicyRule(selectedDetails?.ruleId));
  const dimensionId = useRecoilValue(policyLocationsStatsDimension);
  const [loadCallback, cancelLoad] =
    useLoadAssignmentPolicyLocationsStatsByRule();
  const [runExportCSV] = useRunExportJobMutation();
  const locationCapacitySpec = useLocationCapacitySpec();

  function callDataLoad(page?: DataPageParams) {
    cancelLoad();
    if (
      _.isNil(selectedDetails?.ruleId) ||
      selectedDetails?.filterType !== 'locations'
    )
      return;

    loadCallback({
      policy,
      selectedDetails,
      page,
    });
  }

  // update data when policy changed
  useEffect(() => {
    callDataLoad();
  }, [policy, selectedDetails, dimensionId]);

  // cancel requests when unmount
  useEffect(() => {
    return () => cancelLoad();
  }, []);

  if (!selectedDetails?.ruleId)
    return (
      <InboxZero
        selfCenter
        hasIcon
        message={t`No Rule selected`}
        // message_helper="Select Orders Dataset"
      />
    );

  const dimensions = getLocationsStatsDimensions(t);

  const matching = getLocationFilterMatchings(policy, selectedDetails);

  const defaultColumnConfig = getLocationsStatsTableConfigDefault(
    dimensionId,
    t,
    ms,
  );

  const converterConfig: LocationsStatsConverterConfig = {
    columnsConfig: defaultColumnConfig,
    dictionaries: {
      aisleTitleMap: aislesMap,
    },
    searchValues,
    sortColumnField: dimensions[dimensionId]?.field,
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`assignment-policy`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const variables: LocationsStatsExportJobParams = {
      ...locationsStatsExportJobParams,
      query: FindLocationsStatsFilterDocument.loc.source.body,
      config: converterConfig,
      variables: {
        layoutId: sim.layout?.id,
        input: matching,
        summary: {
          assignmentId: sim.assignment?.id,
          groupBy: [
            ...dimensions[dimensionId].groupBy,
            ...locationStatsFixedDimensions,
          ],
          uoms: _.map(locationCapacitySpec, spec => _.omit(spec, 'title')),
        },
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
      filename: `${filename}.csv`,
    };
  }

  const keyFields = _.map(
    [dimensionId, ...locationStatsFixedDimensions],
    d => dimensions[d].field,
  );

  const viewConfig: LocationsStatsByRuleTableViewProps = {
    id: rule?.id,
    title: rule?.title,
    onChange: callDataLoad,
    startExportCSV: startExportCSV,
    keyFields,
  };

  return <LocationsStatsByRuleTableView {...viewConfig} />;
};

export default AssignmentPolicyLocationsStatsByRuleTableView;
