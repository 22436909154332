import {
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ASSIGNMENT_MAPPING_SCHEMA,
  ITEM_SET_MAPPING_SCHEMA,
  LAYOUT_LOCATION_MAPPING_SCHEMA,
} from '@warebee/shared/import-converter';
import _ from 'lodash';
import { FilterFieldConfigBase } from '../../common/types';
import { KeysMatching } from '../../common/utility.types';
import { datasetQueryBuilder } from '../../feed/store/datasetQueries/queryBuilder/datasetQueryBuilder';
import { FilterPreset } from '../../filterPreset/store/filterPreset.types';
import {
  AssignmentDataRow,
  getAssignmentQueryBuilder,
} from './datasets/assignmentsQueryBuilder';
import { DatasetQueryBuilderParams } from './datasets/datasetQueryBuilder.types';
import {
  getItemsQueryBuilder,
  ItemDataRow,
} from './datasets/itemsQueryBuilder';
import {
  getLocationQueryBuilder,
  LocationDataRow,
} from './datasets/locationsQueryBuilder';

export type SimulationHqDataRowBase = LocationDataRow &
  ItemDataRow &
  AssignmentDataRow & { assignmentItem: string };

export type SimulationHqDataRow = Omit<
  SimulationHqDataRowBase,
  'datasetObjectId' | 'importJobId' | 'raw_data'
>;

export type SimulationHqDataRows = SimulationHqDataRow[];
export type SimulationHqDataColumn = keyof SimulationHqDataRow;

export type SimulationHqSortBy = {
  direction?: SortDirection;
  field: SimulationHqDataColumn;
};

export type SimulationHqFieldWithStringFilter = KeysMatching<
  SimulationHqDataRow,
  string
>;

export type SimulationHqFilterBy = Partial<
  Record<SimulationHqFieldWithStringFilter, StringSearchFilter>
>;

export type SimulationHqQueryBuilder = ReturnType<
  typeof getSimulationHqQueryBuilder
>;

export type SimulationHqQueryBuilderParams = {
  layout: DatasetQueryBuilderParams;
  itemSet: DatasetQueryBuilderParams;
  assignment: DatasetQueryBuilderParams;
  orderSet: DatasetQueryBuilderParams;
  filterPreset?: FilterPreset<SimulationHqDataColumn>;
  filterConfig?: FilterFieldConfigBase<SimulationHqDataColumn>[];
  filterBy?: SimulationHqFilterBy;
};

export function getSimulationHqQueryBuilder(
  params: SimulationHqQueryBuilderParams,
) {
  let builder = datasetQueryBuilder
    .with(
      cte => cte('is'),
      db => {
        return getItemsQueryBuilder(params.itemSet, db).where(
          ({ eb, or, ref }) =>
            or([
              eb('lowerUom', 'is', null),
              eb('lowerUom', '=', ''),
              eb('lowerUom', '=', ref('uom')),
            ]),
        );
      },
    )
    .with(
      cte => cte('l'),
      db => {
        return getLocationQueryBuilder(params.layout, db);
      },
    )
    .with(
      cte => cte('a'),
      db => {
        return getAssignmentQueryBuilder(params.assignment, db);
      },
    )
    .with(
      cte => cte('hqRaw'),
      db => {
        return db
          .selectFrom('is')
          .fullJoin('a', join => join.onRef('a.skuKey', '=', 'is.skuKey'))
          .fullJoin('l', join =>
            join.onRef('a.locationId', '=', 'l.locationId'),
          )
          .select(({ fn }) => [
            fn.coalesce('a.locationId', 'l.locationId').as('locationId'),
            fn.coalesce('a.skuKey', 'is.skuKey').as('skuKey'),
            fn.coalesce('a.consignee', 'is.consignee').as('consignee'),
            fn.coalesce('a.sku', 'is.sku').as('sku'),
            'l.locationId as layoutLocationId',
            'assignmentItem',
          ])
          .select(
            _(ITEM_SET_MAPPING_SCHEMA.fields)
              .map(f => f.name)
              .filter(c => !_.includes(['consignee', 'sku'], c))
              .value(),
          )
          .select(
            _(ASSIGNMENT_MAPPING_SCHEMA.fields)
              .map(f => f.name)
              .filter(c => !_.includes(['consignee', 'sku', 'locationId'], c))
              .value(),
          )
          .select(
            _(LAYOUT_LOCATION_MAPPING_SCHEMA.fields)
              .map(f => f.name)
              .filter(c => c !== 'locationId')
              .value(),
          );
      },
    )
    .with(
      cte => cte('hq'),
      db => {
        let builder = db.selectFrom('hqRaw').selectAll();

        if (params.filterBy) {
          builder = _.reduce(
            params.filterBy,
            (q, value, key) => {
              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                value?.value === 'undefined'
              ) {
                return q.where(key as any, 'is', null);
              }

              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                !_.isEmpty(value?.value)
              ) {
                return q.where(key as any, 'like', `%${value.value}%`);
              }

              return q;
            },
            builder,
          );
        }

        // if (!_.isEmpty(params.filterPreset?.filterUnion?.anyOf)) {
        //   const [filterBuilder, exp] = applyFilterUnion(
        //     builder,
        //     getActualityFilterDescSet2(params.filterConfig),
        //     params.filterPreset.filterUnion,
        //   );

        //   builder = filterBuilder.where(exp);
        // }

        return builder;
      },
    );

  return builder;
}
