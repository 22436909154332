import {
  MeasurementSystem,
  PicklistEventType,
  ResourceUsageSummaryFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import {
  formatInteger,
  FormattedValueWithUnit,
  formatToPrecision,
} from '../../common/formatHelper';
import { Formatter } from '../../common/useFormatter';
import { AgentSettingsWithMeta } from '../../resourcePolicy/agentData/agent.types';
import {
  AgentPerformanceAggregate,
  AgentPerformanceKPI,
} from '../analyze.types';

export const agentSeriePostfix = '__after';
export function getAgentIdFromKey(key: string) {
  return key?.replace(agentSeriePostfix, '');
}

const travellingEvents = new Set<PicklistEventType>([
  PicklistEventType.TRAVELLING_HORIZONTAL,
  PicklistEventType.TRAVELLING_HORIZONTAL_START,
  PicklistEventType.TRAVELLING_HORIZONTAL_END,
]);

export function getAggregatedAgentPerformanceData(
  data: ResourceUsageSummaryFragment[],
): Record<string, AgentPerformanceAggregate> {
  return _.reduce(
    data,
    (acc, row) => {
      const groupKey = `${row.key.pickByDate}-${row.key.agent}`;
      const current = acc[groupKey] ?? {
        travellingDuration: 0,
        duration: 0,
        picklistLines: 0,
      };
      return {
        ...acc,
        [groupKey]: {
          date: row.key.pickByDate,
          agent: row.key.agent,
          travellingDuration:
            current.travellingDuration +
            (travellingEvents.has(row.key.eventType) ? row.totalDuration : 0),
          duration: current.duration + row.totalDuration,
          picklistLines: Math.max(
            current.picklistLines,
            row.totalPicklistLines,
          ),
        },
      };
    },
    {},
  );
}

export function getAgentPerformanceKpiValue(
  agentData: AgentPerformanceAggregate,
  kpi: AgentPerformanceKPI,
  agentMeta: AgentSettingsWithMeta,
  ms: MeasurementSystem,
): number {
  const distanceMultiplier = ms === MeasurementSystem.METRIC ? 100000 : 63360;
  const distance =
    agentMeta.specs.horizontalTravelling.speed.speedLaden *
    agentData.travellingDuration;
  const durationInHours = agentData.duration / (60 * 60);
  const durationInSeconds = agentData.duration;

  switch (kpi) {
    case 'distance':
      return distance;
    case 'picklistLines':
      return agentData.picklistLines;
    case 'linesPerDistance':
      return distance > 0
        ? (agentData.picklistLines * distanceMultiplier) / distance
        : null;
    case 'linesPerHour':
      return agentData.duration > 0
        ? (agentData.picklistLines * 3600) / agentData.duration
        : null;
    // case 'linesPerHour':
    //   return durationInHours > 0
    //     ? agentData.picklistLines / durationInHours
    //     : null;
    case 'secondsPerLine':
      return agentData.picklistLines > 0
        ? durationInSeconds / agentData.picklistLines
        : 0;
    default:
      return 0; // Return 0 for unrecognized KPIs
  }
}

export function getAgentPerformanceValueFormatted(
  value: number,
  kpi: AgentPerformanceKPI,
  formatter: Formatter,
  ms: MeasurementSystem,
  t: TFunction<'simulation'>,
): FormattedValueWithUnit {
  const distanceUnit =
    ms === MeasurementSystem.METRIC
      ? t('km', { ns: 'simulation' })
      : t('mi', { ns: 'simulation' });

  switch (kpi) {
    case 'distance':
      return formatter.formatDistance(value);
    case 'picklistLines':
      return {
        raw: value,
        unit: 'lines',
        unitCode: 'lines',
        value: formatInteger(value),
        fullString: `${formatInteger(value)} lines`,
      };
    case 'linesPerDistance':
      return {
        raw: value,
        unit: `lines/${distanceUnit}`,
        unitCode: `lines/${distanceUnit}`,
        value: formatToPrecision(value, 2),
        fullString: `${formatToPrecision(value, 2)} lines/${distanceUnit}`,
      };
    case 'linesPerHour':
      return {
        raw: value,
        unit: 'lines/hour',
        unitCode: 'lines/hour',
        value: formatToPrecision(value, 2),
        fullString: `${formatToPrecision(value, 2)} lines/hour`,
      };
    case 'secondsPerLine':
      return {
        raw: value,
        unit: 'sec/line',
        unitCode: 'sec/line',
        value: formatToPrecision(value, 2),
        fullString: `${formatToPrecision(value, 2)} sec/line`,
      };
  }
}
