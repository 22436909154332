import { AreaBuilderSettings } from '@warebee/shared/data-access-layout-import-converter';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { Alert } from '../../../components/alerts/Alerts';
//@ts-ignore
import ConverterWorker from './area-converter.worker?worker';
import { ConvertedAreaFeature } from './converter.serializable.model';
import {
  converterAllAreasBuilder,
  converterBayTypesBuilder,
  converterCalculatedLayout,
  converterCalculationStatus,
  converterLayoutData,
  converterLocationTypesBuilder,
  converterShelvesSettingsBuilder,
  converterSpacerAreaSettings,
} from './store/converter.state';
import { ConvertLayoutParams } from './store/converter.types';

export type ConvertLayoutResult = {
  areas: ConvertedAreaFeature[];
};

export const ConverterWatcher: React.FC = () => {
  const { t } = useTranslation('designer');
  const layoutData = useRecoilValue(converterLayoutData);
  const areaBuilders = useRecoilValue(converterAllAreasBuilder);
  const locationTypes = useRecoilValue(converterLocationTypesBuilder);
  const bayTypes = useRecoilValue(converterBayTypesBuilder);
  const shelvesSettings = useRecoilValue(converterShelvesSettingsBuilder);
  const [converterAreaLayout, setConverterAreaLayout] = useRecoilState(
    converterCalculatedLayout,
  );
  const [calcStatus, setCalcStatus] = useRecoilState(
    converterCalculationStatus,
  );
  const spacerAreas = useRecoilValue(converterSpacerAreaSettings);

  const [latestAreaBuilders, setLatestAreaBuilders] =
    useState<Record<string, AreaBuilderSettings>>(null);

  const createWorker = (): Worker => {
    console.log('Creating new worker');
    const w = new ConverterWorker();
    w.onmessage = event => {
      const result: ConvertLayoutResult = event.data;

      if (converterAreaLayout) {
        const existedAreasIds = new Set(
          _.map(converterAreaLayout?.areas, area => area.id),
        );

        const newAreas = _.filter(
          result.areas,
          area => !existedAreasIds.has(area.id),
        );

        const updatedAreas = converterAreaLayout.areas.map(a => {
          const newData = result.areas.find(area => a.id === area.id);
          return newData ?? a;
        });
        setConverterAreaLayout({
          areas: [...updatedAreas, ...newAreas],
        });
      } else {
        setConverterAreaLayout(result);
      }
      setCalcStatus(AsyncLoadStatus.Ok);
      setLatestAreaBuilders(areaBuilders);
    };
    w.onerror = e => {
      setCalcStatus(AsyncLoadStatus.Error);
    };
    return w;
  };

  const worker = useRef<Worker>(null);

  useEffect(() => {
    if (_.isNil(layoutData)) return;

    // kill previous calculations
    console.log('kill worker ', worker.current);
    worker.current?.terminate();

    worker.current = createWorker();
    const changedAreasConfig = _(areaBuilders)
      .filter(v => v !== latestAreaBuilders?.[v.id])
      .map(a => a.id);

    const areasToConvert = changedAreasConfig.isEmpty()
      ? layoutData.areas //all areas
      : layoutData.areas.filter(a => changedAreasConfig.includes(a.area));

    console.log(
      'Re-calculating areas: ' + areasToConvert.map(a => a.area).join(', '),
    );
    const workerData: ConvertLayoutParams = {
      areas: areasToConvert,
      areaBuilders,
      bayTypes,
      locationTypes,
      shelvesSettings,
      spacerAreas,
    };

    worker.current.postMessage(workerData);
    setCalcStatus(AsyncLoadStatus.Loading);
  }, [
    areaBuilders,
    bayTypes,
    layoutData,
    locationTypes,
    shelvesSettings,
    spacerAreas,
  ]);

  if (calcStatus === AsyncLoadStatus.Loading) {
    return <Alert hasStatusLoading={true} message={t`Calculating...`} />;
  }

  if (calcStatus === AsyncLoadStatus.Error) {
    return <Alert hasStatusError message={t`Error re-calculating`} />;
  }

  return <span className={'text-menu-100 text-xs ltr:mr-4 rtl:ml-4'}></span>;
};

export default ConverterWatcher;
