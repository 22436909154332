import {
  AnalyzeOrderStatus,
  AssignmentDiffItemFragment,
  AssignmentDiffItemFullFragment,
  SizeComplianceLocationDetailsFragment,
  SizeComplianceStatus,
  SortDirection,
  WeightComplianceBayDetailsFragment,
  WeightComplianceLocationDetailsFragment,
  WeightComplianceStatus,
} from '@warebee/frontend/data-access-api-graphql';
import { AnalyzeProductMetric } from '../../metrics/analyzeProduct/analyzeProductMetric.types';
import { KPI } from './simulation.helper';
import { WorkforceScheduleMap } from './workforce.types';

export type AnalyzeResultType = 'initial' | 'allocate';
export type AnalyzeResultCompareType = 'before' | 'after' | 'compare' | 'more';

export type AnalyzeHeatmapTypeDescriptor = {
  id: AnalyzeProductMetric;
  title: string;
  actionTitle?: string;
  icon?: React.FC;
};

export type HeatmapFilter = {
  hideZeros?: boolean;
  hideEmpty?: boolean;
  hideDisabled?: boolean;
  hiddenBuckets?: Record<string, boolean>;
};

export enum OptimisationViewMode {
  Original,
  Optimised,
  Compare,
}

export type AssignmentDiff = {
  totalCount: number;
  content: AssignmentDiffItemFragment[];
};

export type AssignmentDiffFull = {
  totalCount: number;
  content: AssignmentDiffItemFullFragment[];
};

export type OrderSearchFieldId = 'orderId' | 'sku';
export type OrderSearchValue = Partial<Record<OrderSearchFieldId, string>>;
export type AnalyzeOrdersListState = {
  searchValues?: OrderSearchValue;
  totalCount: number;
  sortBy: KPI;
  status?: AnalyzeOrderStatus;
};

export type WeightComplianceDataState<T extends string> = {
  searchValues: Partial<Record<T, string>>;
  sortValues: Partial<Record<T, SortDirection>>;
};

export type WeightComplianceBucketConfig = {
  id: string;
  from: number;
  to: number;
  title: string;
  color: string;
  textColor: string;
  status: WeightComplianceStatus;
};
export type WeightComplianceBaysData = {
  totalCount: number;
  content: WeightComplianceBayDetailsFragment[];
};
export type WeightComplianceLocationsData = {
  totalCount: number;
  content: WeightComplianceLocationDetailsFragment[];
};

export type SizeComplianceBucketConfig = {
  id: string;
  title: string;
  color: string;
  textColor: string;
  status: SizeComplianceStatus;
};

export type SizeComplianceLocationsData = {
  totalCount: number;
  content: SizeComplianceLocationDetailsFragment[];
};

export type SizeComplianceDataState<T extends string> = {
  searchValues: Partial<Record<T, string>>;
  sortValues: Partial<Record<T, SortDirection>>;
};

export type DataSetTableType =
  | 'layout'
  | 'assignment'
  | 'order-set'
  | 'orders'
  | 'item-set'
  | 'items'
  | 'locations-stats-by-rule'
  | 'locations-stats-by-category'
  | 'locations-stats-all'
  | 'analyzed-location-products'
  | 'optimized-location-products'
  | 'feed'
  | 'allocation-locations-stats-by-rule'
  | 'analyzed-endpoint-distribution'
  | 'simulation-hq';

export type RoutingDisplayMode = 'none' | 'active' | 'restricted';

export type SimulationExtraSettings = {
  workforceSettings: {
    schedules: WorkforceScheduleMap;
    version: string;
  };
};

// Policies Tabs
// ———————————————————————————————————
export const policyTabIds = [
  'tab-policy-picking',
  'tab-policy-storage',
  'tab-policy-resource',
  'tab-policy-allocate',
  'tab-policy-stacking',
  'tab-policy-routing',
] as const;

export type PoliciesTabId = (typeof policyTabIds)[number];

// Analyze Tabs
// ———————————————————————————————————
export const analyseTabIds = [
  'tab-analyze-summary',
  'tab-analyze-process',
  'tab-analyze-resources',
  'tab-analyze-workforce',
  'tab-analyze-items',
  'tab-analyze-orders',
  'tab-analyze-heatmaps',
  'tab-analyze-allocate',
  'tab-analyze-more',
] as const;

export type AnalyseTabId = (typeof analyseTabIds)[number];

// Compliance Tabs
// ———————————————————————————————————
export const complianceTabIds = [
  'tab-compliance-policy',
  'tab-compliance-volume',
  'tab-compliance-weight',
  'tab-compliance-size',
  'tab-compliance-heatmaps',
  'tab-analyze-heatmaps',
] as const;

export type ComplianceTabId = (typeof complianceTabIds)[number];

// Routing Tabs
// ———————————————————————————————————
export const routingTabIds = [
  'tab-routing-policy',
  'tab-waypoint-policy',
] as const;

export type RoutingTabId = (typeof routingTabIds)[number];

// Allocate Tabs
// ———————————————————————————————————
export const allocateTabIds = [
  'tab-allocate-policy-allocation',
  'tab-allocate-policy-deallocation',
  'tab-allocate-status',
  'tab-allocate-summary',
  'tab-allocate-jobs',
] as const;

export type AllocateTabId = (typeof allocateTabIds)[number];

// Optimize Tabs
// ———————————————————————————————————
export const optimisationPolicyTabIds = [
  'tab-optimisation-settings',
  'tab-optimisation-policy-swap',
  'tab-optimisation-policy-load-balancing',
  'tab-optimisation-resource-policy',
  'tab-optimisation-status',
] as const;

export type OptimisationPolicyTabId = (typeof optimisationPolicyTabIds)[number];

// Assignment Diff Modes
// ———————————————————————————————————
export const assignmentDiffViewMode = ['all', 'removed', 'added'] as const;
export type AssignmentDiffViewMode = (typeof assignmentDiffViewMode)[number];

export type AnalyzeTotals = {
  cost: number;
  distance: number;
  duration: number;
};

export type StepTabItemConfig<T extends string> = {
  id: T;
  title: string;
  getContent?: () => React.ReactNode;
  getTable?: () => React.ReactNode;
  getLocationLayer?: () => React.ReactNode;
  getToolbars?: () => React.ReactNode;
};

export enum MenuViewMode {
  GUIDED = 'guided',
  PRO = 'pro',
  ALLOCATE_ONLY = 'allocate-only',
  ANALYSE_ONLY = 'analyse-only',
  OPTIMISE_ONLY = 'optimise-only',
}

// Add view mode label mapping
//   const viewModeLabels: Record<MenuViewMode, string> = {
//     [MenuViewMode.GUIDED]: t('Guided (Full)'),
//     [MenuViewMode.PRO]: t('Advanced (All)'),
//     [MenuViewMode.ANALYSE_ONLY]: t('Analyse (Only)'),
//     [MenuViewMode.ALLOCATE_ONLY]: t('Allocate (Only)'),
//     [MenuViewMode.OPTIMISE_ONLY]: t('Optimise (Only)'),
//   };
