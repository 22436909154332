import {
  FindLocationsStatsFilterDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  generateExportFilename,
  LocationsStatsConverterConfig,
  LocationsStatsExportJobParams,
  locationsStatsExportJobParams,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useLoadLocationsStatsByRule from '../assignmentPolicy/useLoadLocationsStatsByRule';
import { DataPageParams } from '../common/types';
import { PanelHeaderProps } from '../components/designer/panels/PanelHeader';
import { viewerAislesMeta } from '../layout/viewer/store/viewer.state';
import { brandName } from '../store/global.state';
import {
  warehouseMeasurementSystem,
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

import {
  policyFilteredLocationsStatsSearchValues,
  policyLocationsStatsDimension,
} from './store/policyFilter.state';
import { simulationCurrent } from './store/simulation.state';
import LocationsStatsByRuleTableView, {
  LocationsStatsByRuleTableViewProps,
} from './tableViews/LocationsStatsByRuleTableView';
import {
  getLocationsStatsDimensions,
  getLocationsStatsTableConfigDefault,
  locationStatsFixedDimensions,
  useLocationCapacitySpec,
} from './tableViews/locationsStatsTable.config';

export type AssignmentPolicyLocationsStatsByRuleTableViewProps = {
  panelHeader?: PanelHeaderProps;
};

const DatasetLocationsStatsTableView: React.FC<
  AssignmentPolicyLocationsStatsByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);
  const searchValues = useRecoilValue(policyFilteredLocationsStatsSearchValues);
  const aislesMap = useRecoilValue(viewerAislesMeta);
  const dimensionId = useRecoilValue(policyLocationsStatsDimension);
  const [loadCallback, cancelLoad] = useLoadLocationsStatsByRule();
  const [runExportCSV] = useRunExportJobMutation();
  const locationCapacitySpec = useLocationCapacitySpec();

  function callDataLoad(page?: DataPageParams) {
    cancelLoad();

    loadCallback({
      includeMatching: null,
    });
  }

  // update data when policy changed
  useEffect(() => {
    callDataLoad();
  }, [dimensionId]);

  // cancel requests when unmount
  useEffect(() => {
    return () => cancelLoad();
  }, []);

  const dimensions = getLocationsStatsDimensions(t);

  const defaultColumnConfig = getLocationsStatsTableConfigDefault(
    dimensionId,
    t,
    ms,
  );

  const converterConfig: LocationsStatsConverterConfig = {
    columnsConfig: defaultColumnConfig,
    dictionaries: {
      aisleTitleMap: aislesMap,
    },
    searchValues,
    sortColumnField: dimensions[dimensionId]?.field,
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`stats-locations`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const variables: LocationsStatsExportJobParams = {
      ...locationsStatsExportJobParams,
      query: FindLocationsStatsFilterDocument.loc.source.body,
      config: converterConfig,
      variables: {
        layoutId: sim.layout?.id,
        input: {
          includeMatching: null,
        },
        summary: {
          assignmentId: sim.assignment?.id,
          groupBy: [
            ...dimensions[dimensionId].groupBy,
            ...locationStatsFixedDimensions,
          ],
          uoms: _.map(locationCapacitySpec, spec => _.omit(spec, 'title')),
        },
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const keyFields = _.map(
    [dimensionId, ...locationStatsFixedDimensions],
    d => dimensions[d].field,
  );

  const viewConfig: LocationsStatsByRuleTableViewProps = {
    id: 'layout-locations-stats-all',
    title: ``,
    onChange: callDataLoad,
    startExportCSV: startExportCSV,
    keyFields,
  };

  return <LocationsStatsByRuleTableView {...viewConfig} />;
};

export default DatasetLocationsStatsTableView;
