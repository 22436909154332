import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../../components/HelperMessage';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { HelpItemListCard } from '../../dashboard/containers/HelpItemListCard';
import { HelpItemListCardsContainer } from '../../dashboard/containers/HelpItemListCardsContainer';
import SchemaHelper from '../SchemaHelper';
import { ImportSelectPanel } from '../panels/ImportSelectPanel';
import { getActivityFeedSchema } from './schema/activityFeedSchema';

const ImportActivityFeedStartView: React.FC = () => {
  const { t } = useTranslation('importer');
  const importTemplatePath = '/importer/csv';
  const importTemplateVersion = 'v24.04.001';
  const importTemplateDataType = 'activityFeed';
  const importTemplateNameMetric = `WareBee-WAREHOUSE_NAME-${importTemplateDataType}-${importTemplateVersion}-TEMPLATE`;

  return (
    <Container col hasOverflowY overflow>
      <ScreenTitle
        title={t`Activity Feed Import`}
        subtitle={t`Map fields`}
        isSticky
        truncate
      />
      <HelperMessage hasMargin hasPadding>
        <HelpItemListCardsContainer>
          <HelpItemListCard
            className={classNames('flex-1', 'sticky top-0')}
            transparent
          >
            <HelperMessage isLead textSize={'lead'}>
              {t`We need the following data`}
            </HelperMessage>

            <ImportSelectPanel
              className={classNames('mt-8')}
              titleDataset={t`Activity Feed`}
              linkPath={`${importTemplatePath}/${importTemplateNameMetric}.zip`}
            />
          </HelpItemListCard>
          <HelperMessage className={classNames('flex-1')} hasPadding>
            <HelperMessage
              isLead
              className={classNames('mt-2 pt-4')}
              textSize={'xl'}
            >
              {t`Field Guide (Schema)`}
            </HelperMessage>
            <SchemaHelper schema={getActivityFeedSchema(t)} />
          </HelperMessage>
        </HelpItemListCardsContainer>
      </HelperMessage>
    </Container>
  );
};

export default ImportActivityFeedStartView;
