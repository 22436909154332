import { InferResult, QueryCreator, sql } from 'kysely';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { DatasetDatabase } from '../../../feed/store/datasetQueries/queryBuilder/datasetQueryBuilder';
import { DatasetQueryBuilderParams } from './datasetQueryBuilder.types';

export const LOCATION_EXTRA_FIELD_PREFIX = 'location_extra';
export type LocationDataRows = InferResult<
  ReturnType<typeof getLocationQueryBuilder>
>;
export type LocationDataRow = LocationDataRows[number];
export type LocationDataColumn = keyof LocationDataRow;

export function getLocationQueryBuilder<T extends DatasetDatabase>(
  params: DatasetQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___layout_location___')
    .where('datasetObjectId', '=', params.datasetId as any)
    .selectAll()
    .select(({ fn }) => [
      ..._.map(params.extraFields, fd =>
        sql<
          number | string | Date | boolean
        >`json_value(${sql.ref('raw_data')}, 'strict $."${sql.raw(fd.name)}"' returning ${sql.raw(fd.type?.[0])})`.as(
          (fd.alias ?? `locationExtra${nanoid(8)}`) as `locationExtra${string}`,
        ),
      ),
    ]);
  return at;
}
