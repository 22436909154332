import { APCAcontrast, sRGBtoY } from 'apca-w3';
import { color, hsl, rgb, RGBColor } from 'd3-color';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import { TwTheme } from '../../Tw';
import {
  booleanColors,
  booleanComplianceColors,
  defaultQualitativeColors,
  dimensionColors,
  policyColors,
  tagsColors,
  waypointEndColors,
  waypointStarColors,
} from './qualitativePalettes';
import {
  categoryHotToColdColor,
  defaultSequentialColors,
  elevationSortColors,
  heatmapAnalytics,
  stackingBottomColors,
  stackingTopColors,
} from './sequentialPalettes';

const defaultTextColor = 'white';
const defaultQualitativePaletteKey = 'qualitative-default';
export const qualitativePaletteIds = [
  'dimension',
  'policy',
  'waypointStart',
  'waypointEnd',
  'boolean',
  'booleanCompliance',
  'tags',
] as const;

export const sequentialPaletteIds = [
  'elevation-sort',
  'stacking-top',
  'stacking-bottom',
  'heatmap-analytics',
  'velocity-analytics',
] as const;

export type QualitativePaletteId = (typeof qualitativePaletteIds)[number];
export type SequentialPaletteId = (typeof sequentialPaletteIds)[number];

const qualitativePaletteMap: Record<QualitativePaletteId, string[]> = {
  dimension: dimensionColors,
  policy: policyColors,
  waypointStart: waypointStarColors,
  waypointEnd: waypointEndColors,
  boolean: booleanColors,
  booleanCompliance: booleanComplianceColors,
  tags: tagsColors,
};

const sequentialPaletteMap: Record<SequentialPaletteId, string[]> = {
  'elevation-sort': elevationSortColors,
  'stacking-top': stackingTopColors,
  'stacking-bottom': stackingBottomColors,
  'heatmap-analytics': heatmapAnalytics,
  'velocity-analytics': categoryHotToColdColor,
};

const qualitativePaletteIndexes: Partial<
  Record<QualitativePaletteId | typeof defaultQualitativePaletteKey, number>
> = {};

/**
 * creates gradient by provided paletteId
 * return function that provide color value by numeric value in range [0, 100]
 */
const getGradientColor = _.memoize((paletteId: SequentialPaletteId) => {
  const colors = sequentialPaletteMap[paletteId] ?? defaultSequentialColors;
  const base = _.size(colors);
  const domain = _.range(base).map(i => Math.floor((i * 100) / (base - 1)));
  return scaleLinear()
    .domain(domain)
    .range(colors as any);
});

export function getSequentialColor(
  v: number,
  paletteId: SequentialPaletteId,
): [string, string] {
  const color = getGradientColor(paletteId)(v)?.toString();
  return [color, defaultTextColor];
}

// Helper function to calculate luminance
function getLuminance(colorValue: string): number {
  // Assuming color() parses to {r, g, b} object with 0-255 values
  const col = color(colorValue); // This needs to be defined or replaced with correct parsing logic
  if (!col) return 0;

  const rgbColor: RGBColor = rgb(col); // Ensure this function correctly normalizes RGB values
  const [r, g, b] = [rgbColor.r, rgbColor.g, rgbColor.b].map(channel => {
    const normalized = channel / 255;
    return normalized <= 0.03928
      ? normalized / 12.92
      : Math.pow((normalized + 0.055) / 1.055, 2.4);
  });

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  // console.log(`Luminance of ${colorValue}: ${luminance}`); // Debugging line
  return luminance;
}

// Helper function to calculate contrast ratio
function getContrastRatio(colorA: string, colorB: string): number {
  const luminanceA = getLuminance(colorA) + 0.05;
  const luminanceB = getLuminance(colorB) + 0.05;

  return luminanceA > luminanceB
    ? luminanceA / luminanceB
    : luminanceB / luminanceA;
}

// Function to determine best contrast color (black or white)
export function getBestContrastColor2(colorValue: string): string {
  const textDefaultColors = TwTheme.extend.colors.text;

  const whiteContrast = getContrastRatio(colorValue, textDefaultColors.light);
  const blackContrast = getContrastRatio(colorValue, textDefaultColors.dark);

  console.log(
    `${colorValue} Contrast with white: ${whiteContrast}, meets WCAG: ${whiteContrast >= 4.5}`,
  );
  console.log(
    `${colorValue} Contrast with black: ${blackContrast}, meets WCAG: ${blackContrast >= 4.5}`,
  );

  return whiteContrast > blackContrast
    ? textDefaultColors.light
    : textDefaultColors.dark;
}

// Function to convert any color input to RGB and then calculate APCA contrast score
function calculateApcaContrast(color1: string, color2: string): number {
  const color1RGB = rgb(color(color1)).formatHex();
  const color2RGB = rgb(color(color2)).formatHex();
  const luminance1 = sRGBtoY([
    parseInt(color1RGB.slice(1, 3), 16),
    parseInt(color1RGB.slice(3, 5), 16),
    parseInt(color1RGB.slice(5, 7), 16),
  ]);
  const luminance2 = sRGBtoY([
    parseInt(color2RGB.slice(1, 3), 16),
    parseInt(color2RGB.slice(3, 5), 16),
    parseInt(color2RGB.slice(5, 7), 16),
  ]);
  const contrast = APCAcontrast(luminance1, luminance2);
  // console.log(
  //   `APCA Contrast between ${color1RGB} and ${color2RGB}: ${contrast}`,
  // );
  return contrast;
}

export function getBestContrastColor(colorValue: string): string {
  const textDefaultColors = TwTheme.extend.colors.text;

  // Assuming veryLight and veryDark are adjusted to meet most cases
  const veryLightLuminance = 0.85; // Example luminance value
  const veryDarkLuminance = 0.15; // Example luminance value

  const inputHSL = hsl(color(colorValue));

  // Generate very light and very dark versions of the input color
  const veryLightColor = hsl(
    inputHSL.h,
    inputHSL.s,
    veryLightLuminance,
  ).formatRgb();
  const veryDarkColor = hsl(
    inputHSL.h,
    inputHSL.s,
    veryDarkLuminance,
  ).formatRgb();

  // console.log(
  //   `Generated Colors: Very Light - ${veryLightColor}, Very Dark - ${veryDarkColor}`,
  // );

  // Calculate APCA contrast scores for the generated colors
  const veryLightContrast = calculateApcaContrast(colorValue, veryLightColor);
  const veryDarkContrast = calculateApcaContrast(colorValue, veryDarkColor);

  let chosenColor = '';
  let meetsAPCA = false;
  let isFallback = false;

  // APCA thresholds: Typically, a contrast value of 60 or more is needed for text readability.
  const apcaThreshold = 60;

  // Choose the color that meets APCA contrast minimums, if any
  if (
    Math.abs(veryLightContrast) >= apcaThreshold ||
    Math.abs(veryDarkContrast) >= apcaThreshold
  ) {
    chosenColor =
      Math.abs(veryLightContrast) > Math.abs(veryDarkContrast)
        ? veryLightColor
        : veryDarkColor;
    meetsAPCA = true;
  } else {
    // Fallback strategy: Try different predefined high-contrast colors
    const fallbackColors = [textDefaultColors.light, textDefaultColors.dark];
    let bestContrast = 0;

    fallbackColors.forEach(fallbackColor => {
      const contrast = Math.abs(
        calculateApcaContrast(colorValue, fallbackColor),
      );
      // console.log(`Fallback Color: ${fallbackColor}, Contrast: ${contrast}`);
      if (contrast > bestContrast) {
        bestContrast = contrast;
        chosenColor = fallbackColor;
      }
    });

    meetsAPCA = bestContrast >= apcaThreshold;
    isFallback = true;
  }

  // Ensure chosenColor is defined
  if (!chosenColor) {
    chosenColor = textDefaultColors.light; // Default to a known high-contrast color
    isFallback = true;
    meetsAPCA = calculateApcaContrast(colorValue, chosenColor) >= apcaThreshold;
  }

  // Visual confirmation in the console
  // console.log(
  //   `%c Original ${colorValue} %c Chosen ${chosenColor} (APCA: ${meetsAPCA ? 'Yes' : 'No'}) ${isFallback ? ' (Fallback)' : ''} `,
  //   `background: ${colorValue}; color: ${chosenColor}; padding: 2px 5px; border-radius: 2px;`,
  //   `${meetsAPCA ? textDefaultColors.dark : textDefaultColors.light}; padding: 2px 5px; border-radius: 2px;`,
  // );

  return chosenColor;
}

// Modified getQualitativeColor function to use best contrast color
export const getQualitativeColor: (
  key: string,
  paletteId: QualitativePaletteId,
) => [string, string] = _.memoize(
  (key: string, paletteId: QualitativePaletteId) => {
    const pid = paletteId ?? defaultQualitativePaletteKey;
    const colors: string[] =
      qualitativePaletteMap[pid] ?? defaultQualitativeColors;
    const colorsCount = _.size(colors);
    const colorIndex = qualitativePaletteIndexes[pid] ?? 0;
    qualitativePaletteIndexes[pid] = colorIndex + 1;
    const colorBase = colors[colorIndex % colorsCount];
    if (_.isNull(color)) {
      console.log('[Color Helper] Color is nil', colorsCount, colorIndex);
    }
    // Text Color
    const textColor = getBestContrastColor(colorBase); // Use best contrast color
    // console.log('getQualitativeColor textColor', textColor);
    return [colorBase, textColor];
  },
  (...args) => _.join(args, '-'),
);

export function getColorDarker(colorValue: string, darkness = 1): string {
  const fallbackColor = '#000000'; // Define your fallback color here
  try {
    // Ensure colorValue is defined and not null by using a fallback color if necessary
    const safeColorValue = colorValue || fallbackColor;
    const resultColor = color(safeColorValue)?.darker(darkness).toString();
    return resultColor ? resultColor : fallbackColor; // Use resultColor if it's valid, else fallbackColor
  } catch (ex) {
    console.error(
      `[Color Helper] Can't get darker version of color: ${colorValue}`,
      ex,
    );
    return fallbackColor; // Return fallback color in case of error
  }
}

export function applyColorOpacity(colorValue: string, opacity = 0.8): string {
  const fallbackColor = '#000000'; // Define your fallback color here

  try {
    const safeColorValue = colorValue || fallbackColor;
    const resultColor = color(safeColorValue).copy({ opacity }).toString();
    return resultColor ? resultColor : fallbackColor; // Use resultColor if it's valid, else fallbackColor
  } catch (ex) {
    console.error(
      `[Color Helper] Can't change opacity of color: ${colorValue}`,
      ex,
    );
    return fallbackColor; // Return fallback color in case of error
  }
}

type ColorManipulationOptions = {
  opacity?: number;
  darkness?: number;
  lightness?: number;
  saturation?: number;
  overrideColor?: string;
  desaturate?: boolean;
};

export function manipulateColor(
  colorValue: string,
  options: ColorManipulationOptions,
): string {
  try {
    let initialColor = options.overrideColor
      ? color(options.overrideColor)
      : color(colorValue);
    let hslColor = hsl(initialColor); // Convert to HSL at the beginning

    if (options.desaturate) {
      hslColor.s = 0;
    }

    if (options.opacity !== undefined) {
      hslColor.opacity = options.opacity;
    }

    if (options.darkness !== undefined) {
      hslColor.l = hslColor.l * (1 / (1 + options.darkness));
    }

    if (options.lightness !== undefined) {
      hslColor.l += options.lightness;
    }

    if (options.saturation !== undefined) {
      hslColor.s = options.saturation;
    }

    return hslColor + ''; // Convert HSL back to string format, which will be RGB
  } catch (ex) {
    console.error(`Can't manipulate color: ${colorValue}`, ex);
    return colorValue;
  }
}
