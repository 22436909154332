import {
  AssignmentPolicyFragment,
  LocationFilterType,
  SimulationItemFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import {
  FilterFieldConfigBase,
  FilterFieldDataType,
  FilterFieldEditorType,
} from '../../common/types';
import { Formatter } from '../../common/useFormatter';
import i18n from '../../i18n';

export const AP_FALLBACK_RULE_ID = 'FALLBACK';

export function getLocationFilterConfigCommon(
  t: TFunction<'simulation'> = i18n.t,
): FilterFieldConfigBase<LocationFilterType>[] {
  return [
    {
      type: LocationFilterType.PLANE,
      title: t(`Floor`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.AREA,
      title: t(`Area`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },

    {
      type: LocationFilterType.ACCESS_AISLE,
      title: t(`Aisle (Access)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.StringRange,
    },
    {
      type: LocationFilterType.AISLE,
      title: t(`Aisle`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.StringRange,
    },
    {
      type: LocationFilterType.LEVEL,
      title: t(`Location level`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.StringRange,
    },
    {
      type: LocationFilterType.BAY_LOCATION_ORDER,
      title: t(`Bay location order`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.BAY_TYPE,
      title: t(`Racking Type (Bay)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.RACKING_TYPE,
      title: t(`Location Type`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.USAGE_TYPE,
      title: t(`Usage Type`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.MHTYPE,
      title: t(`MHE Type`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.CONGESTION_ZONE,
      title: t(`Work Zone`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },

    {
      type: LocationFilterType.BAY,
      title: t(`Bay`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.StringRange,
    },
    {
      type: LocationFilterType.SIDE,
      title: t(`Side`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: LocationFilterType.INDEX_FROM_FRONT,
      title: t(`Index from Front`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: LocationFilterType.DEPTH_FROM_FRONT,
      title: t(`Depth from Front`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: LocationFilterType.DEPTH_POSITION,
      title: t(`Depth Position`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: LocationFilterType.HEIGHT_FROM_FLOOR,
      title: t(`Height from floor`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: LocationFilterType.PORTAL_X,
      title: t(`X`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: LocationFilterType.PORTAL_Y,
      title: t(`Y`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },

    {
      type: LocationFilterType.LOCATION,
      title: t(`Location`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.StringRange,
    },
  ];
}

export function getProductFilterConfigCommon(
  t: TFunction<'simulation'> = i18n.t,
  formatter: Formatter = null,
): FilterFieldConfigBase<SimulationItemFilterType>[] {
  return [
    {
      type: SimulationItemFilterType.CONSIGNEE,
      title: t(`Client (Consignee)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.SKU,
      title: t(`Item`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.SKU_GROUP,
      title: t(`Item Group`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.SUB_GROUP,
      title: t(`Item Sub Group`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.TRANSPORT_CLASS,
      title: t(`Transport Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.STOCK_CATEGORY,
      title: t(`Stock Category`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.STORAGE_CLASS,
      title: t(`Storage Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.POLLUTION_CLASS,
      title: t(`Pollution Class`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.MultipleCheck,
    },
    {
      type: SimulationItemFilterType.NET_WEIGHT,
      title: t(`Weight`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatWeight,
    },
    {
      type: SimulationItemFilterType.LENGTH,
      title: t(`Length`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: SimulationItemFilterType.WIDTH,
      title: t(`Width`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: SimulationItemFilterType.HEIGHT,
      title: t(`Height`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatDistance,
    },
    {
      type: SimulationItemFilterType.VOLUME,
      title: t(`Volume`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatVolume,
    },
    {
      type: SimulationItemFilterType.CUMULATIVE_PERCENT_RANK,
      title: t(`Order Line (ABC %)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.ABCRange,
      dataType: FilterFieldDataType.NumberRange,
      format: formatter?.formatShare,
      rangeMinValue: 0,
      rangeMaxValue: 1,
      isStatic: true,
      nullOptionTitle: t(`Dead stock`, { ns: 'simulation' }),
    },
    {
      type: SimulationItemFilterType.TOTAL_ORDER_LINE_COUNT,
      title: t(`Visits (OL. Count)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: SimulationItemFilterType.TOTAL_QUANTITY_ORDERED,
      title: t(`Ordered Base UOM qty`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },

    {
      type: SimulationItemFilterType.WEEKLY_DAYS_WITH_ORDERS_MAX,
      title: t(`Weekday Count (Max.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: SimulationItemFilterType.WEEKLY_DAYS_WITH_ORDERS_AVG,
      title: t(`Weekday Count (Avg.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: SimulationItemFilterType.DAILY_ORDER_LINE_COUNT_MAX,
      title: t(`Daily Pick Count (Max.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: SimulationItemFilterType.DAILY_ORDER_LINE_COUNT_AVG,
      title: t(`Daily Pick Count (Avg.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: SimulationItemFilterType.WEEKLY_ORDER_LINE_COUNT_MAX,
      title: t(`Weekly Pick Count (Max.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
    {
      type: SimulationItemFilterType.WEEKLY_ORDER_LINE_COUNT_AVG,
      title: t(`Weekly Pick Count (Avg.)`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.NumberRange,
    },
    {
      type: SimulationItemFilterType.ASSIGNMENT_COUNT,
      title: t(`Assignment Count`, { ns: 'simulation' }),
      editorType: FilterFieldEditorType.SliderRange,
      dataType: FilterFieldDataType.IntegerRange,
    },
  ];
}

export const AssignmentPolicyDefault: AssignmentPolicyFragment = {
  rules: [],
  fallbackLocationsMatch: null,
};
