import {
  ResponsiveSwarmPlot,
  ResponsiveSwarmPlotCanvas,
  SwarmPlotCustomLayer,
} from '@nivo/swarmplot';
import { format } from 'date-fns-tz';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { getQualitativeColor } from '../../common/color.helper';
import InboxZero from '../../components/InboxZero';
import FeedSwarmChartTooltip from '../components/FeedSwarmChartTooltip';
import { FeedLatestEvent } from '../store/datasetQueries/feedLatestEvents';
import { SWARM_PAGE_SIZE } from '../store/feed.default';
import {
  feedAgentsStats,
  feedLatestEvents,
  feedSelectedAgentIds,
  feedSelectedAgentsDataPage,
  feedStatsAggregateBy,
} from '../store/feed.state';
import { FeedGroupByAgentType } from '../store/feed.types';

export const swarmColorBy = ['agent', 'agentType', 'job', 'jobType'] as const;
export type SwarmColorBy = (typeof swarmColorBy)[number];

export type FeedSwarmItem = FeedLatestEvent & {
  color: string;
  id: string;
  date: Date;
  ms: number;
};

export type FeedActivitySwarmProps = {
  colorBy: SwarmColorBy;
  groupBy?: FeedGroupByAgentType;
};

const FeedActivitySwarm: React.FC<FeedActivitySwarmProps> = props => {
  const { t } = useTranslation('feed');
  const events = useRecoilValue(feedLatestEvents);
  const theme = TwTheme.extend.colors.chart;
  const agentStatsAll = useRecoilValue(feedAgentsStats);
  const selectedAgents = useRecoilValue(feedSelectedAgentIds);
  const selectedPage = useRecoilValue(feedSelectedAgentsDataPage);
  const aggregateBy = useRecoilValue(feedStatsAggregateBy);

  const selectedAgentsSet = new Set(selectedAgents);
  const showAll = _.isEmpty(selectedAgents);

  const agentsAll = _.map(agentStatsAll, a => a.agentId).filter(
    a => showAll || selectedAgentsSet.has(a),
  );

  const agentsVisible = showAll
    ? _.slice(
        agentsAll,
        (selectedPage - 1) * SWARM_PAGE_SIZE,
        selectedPage * SWARM_PAGE_SIZE,
      )
    : agentsAll;

  const agentsVisibleSet = new Set(agentsVisible);

  function getColorKey(e: FeedLatestEvent) {
    switch (props.colorBy) {
      case 'job':
        return e.jobId;
      case 'jobType':
        return e.eventProcessType;
      case 'agent':
        return e.agentId;
    }
  }
  //chart data
  const data: FeedSwarmItem[] = _(events)
    .filter(e => agentsVisibleSet.has(e[aggregateBy]))
    .map(e => ({
      ...e,
      color: getQualitativeColor(getColorKey(e), 'dimension')[0],
      id: `${e.eventId}-${e.agentId}-${e.eventEndTime}`,
      date: e.eventEndTime,
      ms: e.eventEndTime.getTime(),
    }))
    .value();

  const minQuantity = _.minBy(data, e => e.quantity)?.quantity ?? 1;
  const maxQuantity = _.maxBy(data, e => e.quantity)?.quantity ?? 1;

  const maxTime =
    _.maxBy(data, e => e.eventEndTime)?.eventEndTime.getTime() ?? 0;
  const minTime =
    _.minBy(data, e => e.eventEndTime)?.eventEndTime.getTime() ?? 0;

  const deviceCount = _.size(agentsVisible);
  const durationScaleFactor = (3600 * 1000) / (maxTime - minTime);
  const bubbleMaxSizeBase =
    deviceCount > 20 ? 9 : deviceCount > 10 ? 10 : deviceCount > 5 ? 12 : 8;
  const bubbleMaxSize = Math.min(
    16,
    Math.max(bubbleMaxSizeBase * durationScaleFactor, 2),
  );
  const bubbleMinSize = Math.min(bubbleMaxSize, 5);

  const SwarmPlot =
    _.size(data) > 1000 ? ResponsiveSwarmPlotCanvas : ResponsiveSwarmPlot;

  const LatestLine: SwarmPlotCustomLayer<FeedSwarmItem> = ({
    innerHeight,
    xScale,
  }) => {
    const theme = TwTheme.extend.colors.chart;
    const lineX = xScale(maxTime + 100);
    const lineHeight = innerHeight;
    const textOffset = -15;
    const circleRadius = 7;
    const offsetPointer = 8;

    return (
      <g transform={`translate(${lineX}, 0)`}>
        <line y1={0} y2={lineHeight} stroke={theme.ticks} strokeWidth={3} />
        {/* Top Circle */}
        {/* <circle cx={0} cy={0} r={circleRadius} fill={theme.ticks} /> */}
        {/* Bottom Circle */}
        {/* <circle cx={0} cy={lineHeight} r={circleRadius} fill={theme.ticks} /> */}

        {/* Top Triangle (Now Pointing Up) */}
        <polygon
          points={`-${circleRadius},-${circleRadius * 1.5} ${circleRadius},-${circleRadius * 1.5} 0,0`}
          fill={theme.ticks}
        />

        {/* Bottom Triangle (Now Pointing Down) */}
        <polygon
          points={`-${circleRadius},${lineHeight - offsetPointer + circleRadius * 1.5} ${circleRadius},${lineHeight - offsetPointer + circleRadius * 1.5} 0,${lineHeight - offsetPointer}`}
          fill={theme.ticks}
        />

        <text
          x={0}
          y={textOffset} // lineHeight + textOffset to place at the bottom of the line
          textAnchor="middle" // Center the text
          style={{ fill: theme.text, fontSize: 10 }}
        >
          {t`Latest`}
        </text>
      </g>
    );
  };

  const hasData = _.size(data) > 0;
  if (!hasData) {
    return <InboxZero selfCenter message={t`No events`} />;
  }
  return (
    <SwarmPlot
      data={data}
      groupBy={aggregateBy}
      groups={agentsVisible}
      value="ms"
      valueFormat={ms => new Date(ms).toDateString()}
      valueScale={{
        type: 'linear',
        min: minTime,
        max: maxTime,
        reverse: false,
      }}
      size={{
        key: 'quantity',
        values: [minQuantity, maxQuantity],
        sizes: [bubbleMinSize, bubbleMaxSize],
      }}
      layout="horizontal"
      forceStrength={0.4}
      simulationIterations={80}
      borderColor={{
        from: 'color',
        modifiers: [
          ['darker', 0.6],
          ['opacity', 0.5],
        ],
      }}
      colors={d => {
        return d.data.color;
      }}
      margin={{ top: 40, right: 40, bottom: 100, left: 140 }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Time',
        legendPosition: 'middle',
        legendOffset: 46,
        format: ms => format(new Date(ms), 'HH:mm'),
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        truncateTickAt: 14,
      }}
      enableGridX={true}
      tooltip={d => <FeedSwarmChartTooltip datum={d} />}
      layers={[LatestLine, 'grid', 'axes', 'circles', 'annotations', 'mesh']}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 11,
              fill: theme.ticks,
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fill: theme.legend,
            },
          },
        },
        grid: {
          line: {
            stroke: theme.grid,
            strokeWidth: 0.3,
          },
        },
        crosshair: {
          line: {
            stroke: theme.crosshair,
            strokeWidth: 3,
            // simulate line will dash stroke when index is even
            strokeDasharray: '3, 3',
          },
        },
      }}
    />
  );
};

export default FeedActivitySwarm;
