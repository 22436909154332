import { InferResult, QueryCreator, sql } from 'kysely';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { DatasetDatabase } from '../../../feed/store/datasetQueries/queryBuilder/datasetQueryBuilder';
import {
  DATASET_QUERY_VALUE_CONCATENATION,
  DatasetQueryBuilderParams,
} from './datasetQueryBuilder.types';

export const ASSIGNMENT_EXTRA_FIELD_PREFIX = 'asgmnt_extra';

export type AssignmentDataRows = InferResult<
  ReturnType<typeof getAssignmentQueryBuilder>
>;
export type AssignmentDataRow = AssignmentDataRows[number];
export type AssignmentDataColumn = AssignmentDataRow;

export function getAssignmentQueryBuilder<T extends DatasetDatabase>(
  params: DatasetQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___assignment___')
    .where('datasetObjectId', '=', params.datasetId as any)
    .selectAll()
    .select(({ fn }) => [
      sql<string>`${sql.ref('consignee')} || '${sql.raw(DATASET_QUERY_VALUE_CONCATENATION)}' || ${sql.ref('sku')} `.as(
        'skuKey',
      ),

      sql<string>`${sql.ref('locationId')} || '${sql.raw(DATASET_QUERY_VALUE_CONCATENATION)}' || ${sql.ref('consignee')} || '${sql.raw(DATASET_QUERY_VALUE_CONCATENATION)}'|| ${sql.ref('sku')} `.as(
        'assignmentItem',
      ),
      ..._.map(params.extraFields, fd =>
        sql<
          number | string | Date | boolean
        >`json_value(${sql.ref('raw_data')}, 'strict $."${sql.raw(fd.name)}"' returning ${sql.raw(fd.type?.[0])})`.as(
          (fd.alias ?? `asgmntExtra${nanoid(8)}`) as `asgmntExtra${string}`,
        ),
      ),
    ]);
  return at;
}
