import Flatten from '@flatten-js/core';
import {
  AisleSide,
  BayLevel,
  BayLocationOrder,
  LayoutFeatureType,
  LayoutImportConverterAreaFragment,
  LayoutImportConverterBayFragment,
  LayoutImportLevelSummary,
  PlaneAccessSpec,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AisleBuilderSettings,
  AreaBuilderSettings,
  AreaConfiguration,
  AreaConfigurationMap,
  BayRowBuilderSettings,
  BayTypeSettings,
  BaysAlignOption,
  BaysOrderInAisleOption,
  LayoutEntryPoint,
  LocationPatchHolder,
  LocationTypeSettings,
  OrderSettings,
  OrderTypeOption,
  ShelfSettings,
  SpacerAreaSettings,
  SpacerBuilderSettings,
  SpacerPositionOption,
  SpacerTypeOption,
  applyLocationPatch,
} from '@warebee/shared/data-access-layout-import-converter';
import {
  AttachedVisualBay,
  LayoutTransformation,
  VisualAisle,
  VisualBay,
  bindBayLocations,
  generateConnectivityGraphWithAttachedBays,
  getEffectiveBaySize,
  loadSegment,
  mergeBoxes,
  rect,
  savePoint,
} from '@warebee/shared/data-access-layout-manager';
import {
  ConnectivityGraph,
  DEFAULT_BAY_TYPE,
} from '@warebee/shared/engine-model';
import { LayerConfig } from 'konva/lib/Layer';
import _, { isEmpty } from 'lodash';
import naturalCompare from 'string-natural-compare';
import {
  ConvertedAisle,
  ConvertedArea,
  ConvertedBay,
  ConvertedLocation,
} from '../converter.model';
import {
  ConvertedAisleFeature,
  ConvertedAreaFeature,
  ConvertedBayFeature,
  ConvertedLocationFeature,
} from '../converter.serializable.model';
import { DefaultAisleBuilderSetting } from './converter.defaults';
import {
  ConverterAreaIntersection,
  LayoutImportConverterData,
} from './converter.types';
import {
  TransformParams,
  getTransformationMatrix,
  transformShape,
} from './transformation.helper';

const { unify, intersect } = Flatten.BooleanOperations;

type BayWithSize = LayoutImportConverterBayFragment & {
  effectiveWidth: number;
  effectiveDepth: number;
};

export type ConvertAreaParams = {
  areaData: LayoutImportConverterAreaFragment;
  builder: AreaBuilderSettings;
  bayTypes: Record<string, BayTypeSettings>;
  locationTypes: Record<string, LocationTypeSettings>;
  shelvesSettings: Record<string, Record<number, ShelfSettings>>;
};

// function getEffectiveLocationDepth(
//   loc: {
//     locationRackingType: string;
//     locationLength: number;
//   },
//   locationTypes: Record<string, LocationTypeSettings>,
// ) {
//   const effectiveDepthGap =
//     (
//       locationTypes[loc.locationRackingType] ||
//       locationTypes[DEFAULT_LOCATION_RACKING_TYPE] ||
//       {}
//     ).gapDepth || 0;
//   return loc.locationLength + effectiveDepthGap;
// }

function getSizedBay(
  bay: LayoutImportConverterBayFragment,
  areaBuilder: AreaBuilderSettings,
  bayTypes: Record<string, BayTypeSettings>,
  locationTypes: Record<string, LocationTypeSettings>,
): BayWithSize {
  // const effectiveFrameProfile =
  //   bayTypes[bay.bayType ?? DEFAULT_BAY_TYPE]?.verticalFrameProfile || 0;
  // const effectiveWidth2 = Math.max(
  //   ...bay.levels.map(level => level.usedWidth + 2 * effectiveFrameProfile),
  // );

  // const effectiveDepth2 = Math.max(
  //   ...bay.locations.map(loc => getEffectiveLocationDepth(loc, locationTypes)),
  // );

  const [effectiveWidth, effectiveDepth] = getEffectiveBaySize(
    bay.locations,
    bayTypes[bay.bayType ?? DEFAULT_BAY_TYPE] || {},
    locationTypes,
  );

  return {
    ...bay,
    effectiveWidth,
    effectiveDepth,
  };
}

type CreateBaysParams = {
  area: string;
  bays: BayWithSize[];
  aisleId: string;
  bayRowSettings: BayRowBuilderSettings;
  startPoint: [number, number];
  side: AisleSide;
  namePrefix: string;
  bayTypes: Record<string, BayTypeSettings>;
  spacers: Record<string, SpacerBuilderSettings[]>;
  tunnelBays: Record<string, boolean>;
  tunnelMinWidth: number;
  locationTypes: Record<string, LocationTypeSettings>;
  bayFromY: number;
  bayRowToY: number;
  addAutoSpacers?: boolean;
  locationOrder: BayLocationOrder;
  shelvesSettings: Record<string, Record<number, ShelfSettings>>;
};

function createRow(params: CreateBaysParams): {
  bays: ConvertedBay[];
  aisles: ConvertedAisle[];
  rowShape: Flatten.Polygon;
} {
  const baysResult: ConvertedBay[] = [];
  const aislesResult: ConvertedAisle[] = [];
  // eslint-disable-next-line prefer-const
  let [bayX, bayY] = params.startPoint;

  // add auto spacer before bay's row , if needed
  const firstBay = _.head(params.bays);
  if (
    firstBay &&
    params.addAutoSpacers &&
    Math.abs(params.bayFromY - bayY) > 0.01
  ) {
    const dX = params.side === AisleSide.RIGHT ? 0 : -firstBay.effectiveDepth;

    const autoSpacer = new ConvertedAisle(
      `${params.area}- ${params.namePrefix}-${params.side}-before`,
      rect(
        [bayX + dX, params.bayFromY],
        [firstBay.effectiveDepth, bayY - params.bayFromY],
      ),
      `${params.namePrefix}-${params.side}`,
      false,
    );
    autoSpacer.isAutoSpacer = true;
    aislesResult.push(autoSpacer);
  }

  params.bays.forEach(bay => {
    const spacersBefore = _.filter(
      params.spacers?.[bay.bayId],
      spacer =>
        spacer.position ===
        (params.side === AisleSide.LEFT
          ? SpacerPositionOption.Left
          : SpacerPositionOption.Right),
    );
    const spacersAfter = _.filter(
      params.spacers?.[bay.bayId],
      spacer =>
        spacer.position ===
        (params.side === AisleSide.LEFT
          ? SpacerPositionOption.Right
          : SpacerPositionOption.Left),
    );
    spacersBefore.forEach(spacer => {
      const offsetX = params.side === AisleSide.RIGHT ? 0 : -bay.effectiveDepth;

      const spacerAisle = new ConvertedAisle(
        spacer.id,
        rect([bayX + offsetX, bayY], [bay.effectiveDepth, spacer.width]),
        spacer.title,
        spacer.type === SpacerTypeOption.Block,
      );
      spacerAisle.isSpacer = true;
      aislesResult.push(spacerAisle);
      bayY += spacer.width;
    });
    const frontEdgeBase = Flatten.segment(
      Flatten.point(bayX, bayY),
      Flatten.point(bayX, bayY + bay.effectiveWidth),
    );
    const effectiveFrontEdge =
      params.side === AisleSide.LEFT ? frontEdgeBase : frontEdgeBase.reverse();
    const frontEdge = Flatten.vector(
      effectiveFrontEdge.end.x - effectiveFrontEdge.start.x,
      effectiveFrontEdge.end.y - effectiveFrontEdge.start.y,
    );

    bayY += bay.effectiveWidth;

    const baySettings = params.bayTypes[bay.bayType ?? DEFAULT_BAY_TYPE];

    const bayLevelsMap = _.keyBy(bay.levels, l => l.level);
    const bayLevelsWithLocations = _.map(bay.levels, l => l.level);
    const maxLevel = _.last(bayLevelsWithLocations);

    const shelvingType = _.join(bayLevelsWithLocations, '-');

    const levelsWithHeights = _(params.shelvesSettings?.[shelvingType])
      .filter(l => l.minHeight > 0)
      .sortBy(l => l.level)
      .value();

    const levelsWithHeightsMap = _.keyBy(levelsWithHeights, l => l.level);

    const bayLevelsWithMinHeights = _.map(levelsWithHeights, l => l.level);

    const actualLevels = _([
      ..._.filter(bayLevelsWithMinHeights, l => l <= maxLevel),
      ...bayLevelsWithLocations,
    ])
      .uniq()
      .sort((a, b) => a - b)
      .value();

    const minLevel = _.head(actualLevels);

    const bottomLevelLocationsCount = _(bay.locations)
      .filter(l => l.locationLevel === minLevel)
      .size();

    const bottomLevelActiveWidth = _(bay.locations)
      .filter(l => l.locationLevel === minLevel && l.locationStatus)
      .reduce((acc, l) => acc + l.locationWidth, 0);

    const hasPassEvaluated =
      // WB-385: Only one level or two-levels should never be a tunnel Aisle
      minLevel < maxLevel &&
      // Tunnel aisle cannot have location count >3 on bottom layer
      bottomLevelLocationsCount <= 3 &&
      // Ground level should have unoccupied space more than provided  "tunnelMinWidth" param
      bay.effectiveWidth - bottomLevelActiveWidth > params.tunnelMinWidth &&
      // Ground level should have unoccupied space more than half of bay effective width
      bottomLevelActiveWidth / bay.effectiveWidth < 0.5;

    const hasPath = params.tunnelBays?.[bay.bayId] ?? hasPassEvaluated;

    const heightsByLevel = _.map(actualLevels, l =>
      Math.max(
        bayLevelsMap[l]?.height ?? 0,
        levelsWithHeightsMap[l]?.minHeight ?? 0,
      ),
    );

    const bayLevelsAll: BayLevel[] = _.map(
      heightsByLevel,
      (levelHeight, index) => {
        return {
          level: actualLevels[index],
          levelHeight: levelHeight,
          heightFromFloor: _(heightsByLevel).take(index).sum(),
        };
      },
    );
    const bayLevelsAllMap = _.keyBy(bayLevelsAll, l => l.level);

    const maxBayLevel = _.last(bayLevelsAll);

    const newBay = new ConvertedBay(
      bay.bayId,
      bay.bayType,
      effectiveFrontEdge.start,
      frontEdge,
      bay.effectiveWidth,
      bay.effectiveDepth,
      params.locationOrder,
      hasPath,
      params.namePrefix + ' ' + bay.bay,
      maxBayLevel.heightFromFloor + maxBayLevel.levelHeight,
      bayLevelsAll.filter(l => l.levelHeight > 0),
      params.bayTypes[bay.bayType ?? DEFAULT_BAY_TYPE],
    );

    newBay.locations = bindBayLocations(
      newBay,
      bay.locations,
      params.bayTypes,
      params.locationTypes,
    );
    newBay.aisleId = params.aisleId;

    baysResult.push(newBay);

    spacersAfter.forEach(spacer => {
      const offsetX = params.side === AisleSide.RIGHT ? 0 : -bay.effectiveDepth;
      const spacerAisle = new ConvertedAisle(
        spacer.id,
        rect([bayX + offsetX, bayY], [bay.effectiveDepth, spacer.width]),
        spacer.title,
        spacer.type === SpacerTypeOption.Block,
      );
      spacerAisle.isSpacer = true;
      aislesResult.push(spacerAisle);
      bayY += spacer.width;
    });
  });

  // add AutoSpacer at bay row end if needed
  const lastBay = _.last(params.bays);
  if (
    lastBay &&
    params.addAutoSpacers &&
    Math.abs(params.bayRowToY - bayY) > 0.01
  ) {
    const dX = params.side === AisleSide.RIGHT ? 0 : -lastBay.effectiveDepth;

    const autoSpacer = new ConvertedAisle(
      `${params.area}-${params.namePrefix}-${params.side}-after`,
      rect(
        [bayX + dX, bayY],
        [lastBay.effectiveDepth, params.bayRowToY - bayY],
      ),
      `${params.aisleId}-${params.side}`,
      false,
    );
    autoSpacer.isAutoSpacer = true;
    aislesResult.push(autoSpacer);
  }

  return {
    bays: baysResult,
    aisles: aislesResult,
    rowShape: getAreaShape(aislesResult, baysResult),
  };
}

function getAisleBuilder(
  aisleId: string,
  aisleSettings: Record<string, AisleBuilderSettings>,
): AisleBuilderSettings {
  const aisleBuilder = aisleSettings?.[aisleId];

  return (
    aisleBuilder ?? {
      ...DefaultAisleBuilderSetting,
      aisleId,
    }
  );
}
export function addPointsToArea(
  bays: ConvertedBay[],
  spacers: ConvertedAisle[],
) {
  const firstBay = _.first(bays)?.shape?.box;
  const firstSpacer = _.first(spacers)?.shape?.box;
  const lastBay = _.last(bays)?.shape?.box;
  const lastSpacer = _.last(spacers)?.shape?.box;

  const firstBox = firstSpacer?.ymin < firstBay?.ymin ? firstSpacer : firstBay;
  const lastBox = lastSpacer?.ymax > lastBay?.ymax ? lastSpacer : lastBay;

  return {
    top: lastBox
      ? ([
          [lastBox.xmin, lastBox.ymax],
          [lastBox.xmax, lastBox.ymax],
        ] as [number, number][])
      : ([] as [number, number][]),
    bottom: firstBox
      ? ([
          [firstBox.xmin, firstBox.ymin],
          [firstBox.xmax, firstBox.ymin],
        ] as [number, number][])
      : ([] as [number, number][]),
  };
}

export const getBottomAccessAisleId = id => `${id}-bottom-access-aisle`;
export const getTopAccessAisleId = id => `${id}-top-access-aisle`;

export function convertArea(params: ConvertAreaParams): ConvertedArea {
  const aisles: ConvertedAisle[] = [];
  const bays: ConvertedBay[] = [];
  const { areaData: area, builder } = params;

  //console.time('convertArea');
  //console.time('convertAreaCOMMON');
  //console.profile('Convert area: ' + area.area);
  const aisleSettings = { ...builder.aisleSettings };

  const bottomAccessAisleId = getBottomAccessAisleId(area.area);
  const topAccessAisleId = getTopAccessAisleId(area.area);
  const bottomAisleWidth =
    builder.aisleSettings?.[bottomAccessAisleId]?.width ?? builder.aisleWidth;
  const topAisleWidth =
    builder.aisleSettings?.[topAccessAisleId]?.width ?? builder.aisleWidth;

  let baseX = 0;
  let sizeY = 0;
  const baseY = builder.hasBottomAccessAisle ? bottomAisleWidth : 0;

  const areaPointsLeft: [number, number][] = [];
  const areaPointsRight: [number, number][] = [];
  let areaPointsTop: [number, number][] = [];
  let areaPointsBottom: [number, number][] = [];

  const mergedAisles = [];
  const aislesDictionary = _.keyBy(area.aisles, a => a.aisleId);

  const effectiveAisles = area.aisles
    .map(aisle => {
      //Skip aisle if it was merged
      if (_.includes(mergedAisles, aisle.aisleId)) return null;

      const sourceAisleBuilder = getAisleBuilder(aisle.aisleId, aisleSettings);

      const mergeSettings = _.find(
        params.builder.mergeAisles,
        merge => merge[0] === aisle.aisleId || merge[1] === aisle.aisleId,
      );

      if (mergeSettings) {
        const aisleToMergeId =
          mergeSettings[0] === aisle.aisleId
            ? mergeSettings[1]
            : mergeSettings[0];

        const aisleToMerge = aislesDictionary[aisleToMergeId];
        if (_.isNil(aisleToMerge)) {
          console.error(
            `Merge error: Target aisle not found: source ${aisle.aisleId}, target:${aisleToMergeId}`,
          );
          return;
        }
        const aisleSides = _.map(aisle.sides, s => s.side);
        const aisleToMergeSides = _.map(aisleToMerge.sides, s => s.side);

        const canMerge =
          aisleSides.length === 1 &&
          aisleToMergeSides.length === 1 &&
          _.isEmpty(_.intersection(aisleSides, aisleToMergeSides));

        if (!canMerge) {
          console.error(
            `Aisles ${aisle.aisleId} and ${aisleToMergeId} cannot be merged: bay side restriction`,
          );
        }

        const aisleToMergeBuilder = getAisleBuilder(
          aisleToMergeId,
          aisleSettings,
        );

        const newAisleWidth =
          _.isNil(sourceAisleBuilder.width) ||
          _.isNil(aisleToMergeBuilder.width)
            ? undefined
            : sourceAisleBuilder.width + aisleToMergeBuilder.width;

        const newAisleId = `${aisle.aisleId}${aisleToMergeId}`;
        const newAisleTitle = `${aisle.aisle}-${aisleToMerge.aisle}`;

        const leftRowSettings =
          aisleSides[0] === AisleSide.LEFT
            ? sourceAisleBuilder.bayRowSettings[AisleSide.LEFT]
            : aisleToMergeBuilder.bayRowSettings[AisleSide.LEFT];
        const rightRowSettings =
          aisleSides[0] === AisleSide.RIGHT
            ? sourceAisleBuilder.bayRowSettings[AisleSide.RIGHT]
            : aisleToMergeBuilder.bayRowSettings[AisleSide.RIGHT];

        const newAisleSettings: AisleBuilderSettings = _.assign(
          {
            aisleId: newAisleId,
            width: newAisleWidth,
            bayRowSettings: {
              [AisleSide.LEFT]: leftRowSettings,
              [AisleSide.RIGHT]: rightRowSettings,
            },
          },
          getAisleBuilder(newAisleId, aisleSettings),
        );
        aisleSettings[newAisleId] = newAisleSettings;

        aisle = {
          ...aisle,
          aisleId: newAisleId,
          aisle: newAisleTitle,
          sides: [...aisle.sides, ...aisleToMerge.sides],
        };
        mergedAisles.push(aisleToMerge.aisleId);
      }

      const aisleBuilder = getAisleBuilder(aisle.aisleId, aisleSettings);

      // Apply bay order
      // first we apply area settings, after individual bay row setting commutatively

      const isLeftReversed =
        params.builder.baysOrder === BaysOrderInAisleOption.ReverseParallel ||
        params.builder.baysOrder === BaysOrderInAisleOption.RoundRight;

      let leftBays = (
        aisle.sides.find(s => s.side === AisleSide.LEFT)?.bays ?? []
      ).sort((b1, b2) => naturalCompare(b1.bayId, b2.bayId));

      if (!_.isEmpty(leftBays) && isLeftReversed) {
        leftBays = _.reverse([...leftBays]);
      }
      let index = 0;
      leftBays = _.sortBy(leftBays, bay =>
        getFeatureOrderIndex(
          bay.bayId,
          index++,
          leftBays.length,
          aisleBuilder.bayRowSettings?.[AisleSide.LEFT],
        ),
      );

      const isRightReversed =
        params.builder.baysOrder === BaysOrderInAisleOption.ReverseParallel ||
        params.builder.baysOrder === BaysOrderInAisleOption.RoundLeft;

      let rightBays = (
        aisle.sides.find(s => s.side === AisleSide.RIGHT)?.bays ?? []
      ).sort((b1, b2) => naturalCompare(b1.bayId, b2.bayId));

      if (!_.isEmpty(rightBays) && isRightReversed) {
        rightBays = _.reverse([...rightBays]);
      }
      index = 0;
      rightBays = _.sortBy(rightBays, bay =>
        getFeatureOrderIndex(
          bay.bayId,
          index++,
          rightBays.length,
          aisleBuilder.bayRowSettings?.[AisleSide.RIGHT],
        ),
      );

      let leftBaysWidth = 0;
      let rightBaysWidth = 0;
      let leftBaysDepth = 0;
      let rightBaysDepth = 0;

      const leftSizedBays = _.map(leftBays, b => {
        const sizedBay = getSizedBay(
          b,
          builder,
          params.bayTypes,
          params.locationTypes,
        );
        const spacersWidth = _.sumBy(builder.spacers?.[b.bayId], s => s.width);
        leftBaysWidth += sizedBay.effectiveWidth + spacersWidth;
        leftBaysDepth = Math.max(leftBaysDepth, sizedBay.effectiveDepth);
        return sizedBay;
      });

      const rightSizedBays = _.map(rightBays, b => {
        const sizedBay = getSizedBay(
          b,
          builder,
          params.bayTypes,
          params.locationTypes,
        );
        const spacersWidth = _.sumBy(builder.spacers?.[b.bayId], s => s.width);
        rightBaysWidth += sizedBay.effectiveWidth + spacersWidth;
        rightBaysDepth = Math.max(rightBaysDepth, sizedBay.effectiveDepth);
        return sizedBay;
      });

      return {
        ...aisle,
        leftBays: leftSizedBays,
        rightBays: rightSizedBays,
        leftBaysWidth,
        rightBaysWidth,
        aisleLength: Math.max(
          leftBaysWidth,
          rightBaysWidth,
          builder.minAreaLength ?? 0,
        ),
        leftBaysDepth,
        rightBaysDepth,
        mergeId:
          leftBaysWidth === 0 || rightBaysWidth === 0 ? aisle.aisleId : null,
        unmergeId: mergeSettings ? mergeSettings[0] : null,
      };
    })
    .filter(aisle => !!aisle);

  const maxAisleLength = _.max(effectiveAisles.map(a => a.aisleLength));
  sizeY += maxAisleLength;

  const aisleByAlphaNum = effectiveAisles.sort((a1, a2) =>
    naturalCompare(a1.aisleId, a2.aisleId),
  );
  let sortIndex = 0;
  const sortedEffectiveAisles = _(aisleByAlphaNum)
    .sortBy(aisle =>
      getFeatureOrderIndex(
        aisle.aisle,
        sortIndex++,
        effectiveAisles.length,
        builder.aisleOrder,
      ),
    )
    .value();

  const aislesCount = sortedEffectiveAisles.length;
  sortedEffectiveAisles.forEach((effectiveAisle, aisleIndex) => {
    const { aisleId, aisle: aisleTitle, leftBays, rightBays } = effectiveAisle;

    const aisleConfig = getAisleBuilder(aisleId, aisleSettings);
    const shouldStretchAisle =
      builder.maximizeAisleLength ||
      (builder.hasBottomAccessAisle &&
        builder.baysAlign === BaysAlignOption.Top) ||
      (builder.hasTopAccessAisle &&
        builder.baysAlign === BaysAlignOption.Bottom);

    const aisleLength = shouldStretchAisle
      ? maxAisleLength
      : effectiveAisle.aisleLength;

    const aisleVerticalMargin =
      builder.baysAlign === BaysAlignOption.Top
        ? maxAisleLength - aisleLength
        : 0;
    if (leftBays) {
      const bayRowSettings = aisleConfig.bayRowSettings[AisleSide.LEFT];
      const rowVerticalMargin =
        (bayRowSettings.align ?? builder.baysAlign) === BaysAlignOption.Bottom
          ? 0
          : aisleLength - effectiveAisle.leftBaysWidth;
      const bayRowY = baseY + aisleVerticalMargin + rowVerticalMargin;

      const rowResult = createRow({
        area: area.area,
        bays: effectiveAisle.leftBays,
        aisleId,
        side: AisleSide.LEFT,
        bayRowSettings,
        bayTypes: params.bayTypes,
        shelvesSettings: params.shelvesSettings,
        namePrefix: aisleTitle,
        startPoint: [baseX + effectiveAisle.leftBaysDepth, bayRowY],
        spacers: builder.spacers,
        tunnelBays: builder.tunnelBays,
        tunnelMinWidth: builder.minimalTunnelWidth,
        locationTypes: params.locationTypes,
        bayFromY: baseY + aisleVerticalMargin,
        bayRowToY: baseY + aisleLength,
        addAutoSpacers: builder.useAutoSpacers,
        locationOrder:
          aisleConfig?.locationOrderSettings?.[AisleSide.LEFT] ??
          BayLocationOrder.LTR,
      });
      bays.push(...rowResult.bays);
      aisles.push(...rowResult.aisles);

      if (rowResult.rowShape) {
        const { xmin, xmax, ymin, ymax } = rowResult.rowShape.box;
        if (aisleIndex === 0) {
          // const bayPoints = _.flatten(
          //   rowResult.bays.map(b => [b.shape.vertices[1], b.shape.vertices[2]]),
          // );
          // const spacerPoints = _.flatten(
          //   rowResult.aisles.map(a => [
          //     a.shape.box.toPoints()[0],
          //     a.shape.box.toPoints()[1],
          //   ]),
          // );
          // const points = _.sortBy([...bayPoints, ...spacerPoints], p => p.y).map(
          //   p => [p.x, p.y] as [number, number],
          // );
          areaPointsLeft.push([xmin, ymin], [xmin, ymax]);
        }

        //const newAreaPoints = addPointsToArea(rowResult.bays, rowResult.aisles);
        areaPointsTop.push([xmin, ymax], [xmax, ymax]);
        areaPointsBottom.push([xmin, ymin], [xmax, ymin]);
      }
      baseX += effectiveAisle.leftBaysDepth;
    }

    const aisleMinX = baseX;
    const aisleMinY = baseY + aisleVerticalMargin;
    const aisleW = aisleConfig.width || builder.aisleWidth;
    const aisleH = aisleLength;
    const aisleMaxX = aisleMinX + aisleW;
    const aisleMaxY = aisleMinY + aisleH;

    areaPointsTop.push([aisleMinX, aisleMaxY], [aisleMaxX, aisleMaxY]);
    areaPointsBottom.push([aisleMinX, aisleMinY], [aisleMaxX, aisleMinY]);

    const convertedAisle = new ConvertedAisle(
      aisleId,
      rect([aisleMinX, aisleMinY], [aisleW, aisleH]),
      aisleTitle,
    );
    convertedAisle.mergeId = effectiveAisle.mergeId;
    convertedAisle.unmergeId = effectiveAisle.unmergeId;
    convertedAisle.leftBays = _.map(leftBays, b => b.bayId);
    convertedAisle.rightBays = _.map(rightBays, b => b.bayId);
    aisles.push(convertedAisle);

    baseX += aisleConfig.width || builder.aisleWidth;

    if (rightBays) {
      const bayRowSettings = aisleConfig.bayRowSettings[AisleSide.RIGHT];
      const rowVerticalMargin =
        (bayRowSettings.align ?? builder.baysAlign) === BaysAlignOption.Bottom
          ? 0
          : aisleLength - effectiveAisle.rightBaysWidth;
      const bayRowY = baseY + aisleVerticalMargin + rowVerticalMargin;
      const rowResult = createRow({
        area: area.area,
        bays: rightBays,
        aisleId,
        side: AisleSide.RIGHT,
        bayRowSettings,
        bayTypes: params.bayTypes,
        shelvesSettings: params.shelvesSettings,
        namePrefix: aisleTitle,
        startPoint: [baseX, bayRowY],
        spacers: builder.spacers,
        tunnelBays: builder.tunnelBays,
        tunnelMinWidth: builder.minimalTunnelWidth,
        locationTypes: params.locationTypes,
        bayFromY: baseY + aisleVerticalMargin,
        bayRowToY: baseY + aisleLength,
        addAutoSpacers: builder.useAutoSpacers,
        locationOrder:
          aisleConfig?.locationOrderSettings?.[AisleSide.RIGHT] ??
          BayLocationOrder.RTL,
      });
      bays.push(...rowResult.bays);
      aisles.push(...rowResult.aisles);

      if (rowResult.rowShape) {
        const { xmin, xmax, ymin, ymax } = rowResult.rowShape.box;
        if (aisleIndex === aislesCount - 1) {
          // const bayPoints = _.flatten(
          //   rowResult.bays.map(b => [b.shape.vertices[1], b.shape.vertices[2]]),
          // );
          // const spacerPoints = _.flatten(
          //   rowResult.aisles.map(a => [
          //     a.shape.box.toPoints()[0],
          //     a.shape.box.toPoints()[1],
          //   ]),
          // );
          // const points = _.sortBy([...bayPoints, ...spacerPoints], p => p.y).map(
          //   p => [p.x, p.y] as [number, number],
          // );
          areaPointsRight.push([xmax, ymax], [xmax, ymin]);
        }

        //const newAreaPoints = addPointsToArea(rowResult.bays, rowResult.aisles);
        areaPointsTop.push([xmin, ymax], [xmax, ymax]);
        areaPointsBottom.push([xmin, ymin], [xmax, ymin]);
      }

      // if (aisleIndex === aislesCount - 1) {
      //   const bayPoints = _.flatten(
      //     rowResult.bays.map(b => [b.shape.vertices[1], b.shape.vertices[2]]),
      //   );
      //   const spacerPoints = _.flatten(
      //     rowResult.aisles.map(a => [
      //       a.shape.box.toPoints()[0],
      //       a.shape.box.toPoints()[1],
      //     ]),
      //   );
      //   const points = _.sortBy([...bayPoints, ...spacerPoints], p => -p.y).map(
      //     p => [p.x, p.y] as [number, number],
      //   );
      //   areaPointsRight.push(...points);
      // }

      // const newAreaPoints = addPointsToArea(rowResult.bays, rowResult.aisles);
      // areaPointsTop.push(...newAreaPoints.top);
      // areaPointsBottom.push(...newAreaPoints.bottom);

      baseX += effectiveAisle.rightBaysDepth;
    }
  });

  aisles.forEach((aisle, i) => {
    if (i === 0) return;
    const prevAisle = aisles[i - 1];
    if (prevAisle.shape.box.xmax === aisle.shape.box.xmin) {
      const length = aisle.shape.box.ymax - aisle.shape.box.ymin;
      const prevLength = prevAisle.shape.box.ymax - prevAisle.shape.box.ymin;
      if (length >= prevLength) {
        prevAisle.stickyRight = true;
      } else {
        aisle.stickyLeft = true;
      }
    }
  });

  if (builder.hasBottomAccessAisle) {
    sizeY += bottomAisleWidth;
    aisles.push(
      new ConvertedAisle(
        bottomAccessAisleId,
        rect([0, 0], [baseX, bottomAisleWidth]),
        'Access aisle (Bottom)',
      ),
    );
    areaPointsBottom = [
      [0, 0],
      [baseX, 0],
    ];
  }

  if (builder.hasTopAccessAisle) {
    sizeY += topAisleWidth;
    aisles.push(
      new ConvertedAisle(
        topAccessAisleId,
        rect([0, baseY + maxAisleLength], [baseX, topAisleWidth]),
        'Access aisle (Top)',
      ),
    );
    areaPointsTop = [
      [0, baseY + maxAisleLength + topAisleWidth],
      [baseX, baseY + maxAisleLength + topAisleWidth],
    ];
  }

  const mainAccessAisleId = builder.hasBottomAccessAisle
    ? bottomAccessAisleId
    : builder.hasTopAccessAisle
      ? topAccessAisleId
      : aisles[0]?.id;

  //console.timeEnd('convertAreaCOMMON');
  //console.time('generateConnectivityGraphWithAttachedBays');
  const cg = generateConnectivityGraphWithAttachedBays(aisles, bays);

  //console.timeEnd('generateConnectivityGraphWithAttachedBays');
  const bounds = mergeBoxes([...aisles, ...bays].map(o => o.shape.box));
  //console.time('create shape');
  const areaPoints = [
    ...areaPointsLeft,
    ...areaPointsTop,
    ...areaPointsRight,
    ...areaPointsBottom.reverse(),
  ];
  // const shape2 = new Flatten.Polygon(areaPoints);
  //console.log('areaShapePoints', areaPoints);
  const shape = getAreaShape(aisles, bays);
  //console.timeEnd('create shape');
  //console.profileEnd('Convert area: ' + area.area);
  //console.timeEnd('convertArea');

  // console.time('Calculate levels');
  const levels = _(bays)
    .map(b => _.map(b.levels, l => l.level))
    .flatten()
    .uniq()
    .sort()
    .value();
  // console.timeEnd('Calculate levels');

  return {
    id: area.area,
    aisles,
    bays,
    bounds,
    aislePortals: cg.aislePortals,
    bayPortals: cg.bayPortals,
    startAisleId: mainAccessAisleId,
    endAisleId: mainAccessAisleId,
    size: [baseX, sizeY],
    shape: shape,
    levels,
  };
}

export function createSpacerArea(setting: SpacerAreaSettings): ConvertedArea {
  const aisle = new ConvertedAisle(
    `${setting.id}-aisle`,
    rect([0, 0], [setting.width, setting.height]),
    setting.title,
    setting.type === SpacerTypeOption.Block,
    setting.type.toString(),
  );

  return {
    id: setting.id,
    aisles: [aisle],
    bays: [],
    bounds: aisle.shape.box,
    aislePortals: [],
    bayPortals: [],
    startAisleId: aisle.id,
    endAisleId: aisle.id,
    size: [setting.width, setting.height],
    shape: aisle.shape,
    levels: [],
  };
}

export const getLayerConfig = (
  areaConfig: AreaConfiguration,
  area: ConvertedAreaFeature,
): LayerConfig => ({
  x: areaConfig.x + area.size[0] / 2,
  y: areaConfig.y + area.size[1] / 2,
  rotation: areaConfig.rotation,
  offsetX: area.size[0] / 2,
  offsetY: area.size[1] / 2,
  scaleX: areaConfig.flipX ? -1 : 1,
  scaleY: areaConfig.flipY ? -1 : 1,
});

export function getSpacerId(bayId: string, type: SpacerPositionOption) {
  return `${bayId}-${type}`;
}

export function getFeatureOrderIndex(
  featureId: string,
  index: number,
  len: number,
  builder: OrderSettings,
) {
  if (!builder) return index;

  let orderedIndex = -1;

  switch (builder.orderType) {
    case OrderTypeOption.Shifted:
      return (len + index - (builder.shift % len)) % len;

    case OrderTypeOption.Reverse:
      return len - index;

    case OrderTypeOption.Custom:
      orderedIndex = builder.customOrder?.indexOf(featureId) ?? -1;
      return orderedIndex > -1 ? orderedIndex : len + index;

    default:
      return index;
  }
}

type calculateBaySizeParams = {
  bay: LayoutImportConverterBayFragment;
  locationPatch: LocationPatchHolder[];
  locationTypeMap: Record<string, LocationTypeSettings>;
  locationTypeDefault: Omit<LocationTypeSettings, 'typeId'>;
  bayModel: BayTypeSettings;
};

export function calculateBaySize(
  params: calculateBaySizeParams,
): LayoutImportConverterBayFragment {
  const sizeOf = l => {
    return (
      params.locationTypeMap[l.locationRackingType] ||
      params.locationTypeDefault
    );
  };

  const { bay } = params;

  const pathByLocId = _.keyBy(
    params.locationPatch.map(lp => lp.patch),
    patch => patch.locationId,
  );
  const locs = bay.locations.map(l =>
    applyLocationPatch(l, pathByLocId[l.locationId]),
  );

  // TODO should use calculateBayLevelHeights from data-access-layout-import-converter instead
  let depth = 0;
  const levels: LayoutImportLevelSummary[] = bay.levels.map(l => {
    const locByLevel = locs.filter(loc => loc.locationLevel === l.level);
    const maxHeightLoc = _.maxBy(
      locByLevel,
      l => l.locationHeight + sizeOf(l).gapHeight,
    );

    const [effectiveWidth, effectiveDepth] = getEffectiveBaySize(
      locByLevel,
      params.bayModel,
      params.locationTypeMap,
    );

    depth = Math.max(depth, effectiveDepth);
    return {
      level: l.level,
      locationCount: locByLevel.length,
      usedWidth: effectiveWidth,
      height:
        maxHeightLoc.locationHeight +
        sizeOf(maxHeightLoc).gapHeight +
        params.bayModel.shelfHeight,
    };
  });
  return {
    ...bay,
    levels,
    depth,
    locations: locs,
  };
}

export type ModifyLocationsParams = {
  data: LayoutImportConverterData;
  locationPatches: Record<string, LocationPatchHolder>;
  locationTypeMap: Record<string, LocationTypeSettings>;
  bayModelMap: Record<string, BayTypeSettings>;
  locationTypeDefault: Omit<LocationTypeSettings, 'typeId'>;
};

export function applyLocationSettings(
  params: ModifyLocationsParams,
): LayoutImportConverterData {
  return {
    ...params.data,
    areas: params.data.areas.map(area => ({
      ...area,
      aisles: area.aisles.map(a => ({
        ...a,
        sides: a.sides.map(s => ({
          ...s,
          bays: s.bays.map(b =>
            calculateBaySize({
              bay: b,
              locationTypeMap: params.locationTypeMap,
              locationPatch: [],
              //  _.values(params.locationPatches).filter(
              //   lp => lp.bayId === b.bayId,
              // ),
              locationTypeDefault: params.locationTypeDefault,
              bayModel: params.bayModelMap[b.bayType ?? DEFAULT_BAY_TYPE],
            }),
          ),
        })),
      })),
    })),
  };
}

export function getAreaShape(
  aisles: VisualAisle[],
  bays: VisualBay[],
): Flatten.Polygon {
  if (_.isEmpty(aisles) && _.isEmpty(bays)) return null;
  return [...aisles, ...bays]
    .map(item => item.shape)
    .map(shape => {
      // normally, feature polygons should be generated with CW orientation
      // check orientation just in case it's not so
      if ([...shape.faces][0].orientation() !== Flatten.ORIENTATION.CW) {
        return shape.reverse();
      } else {
        return shape;
      }
    })
    .reduce(unify);
}

export function checkIntersection(
  areas: ConvertedArea[],
  areaConfig: AreaConfigurationMap,
): ConverterAreaIntersection[] {
  try {
    console.time('checkIntersection');
    const areasShapes = areas.map(area =>
      transformShape(area.shape, areaConfig[area.id]),
    );
    //return areasShapes;

    const intersections = areasShapes
      // area shapes have correct orientation, no need to check and fix orientation here
      .map((v, i) => areasShapes.slice(i + 1).map(w => [v, w]))
      .flat()
      .map(([a1, a2]) => {
        const overlapPoly = intersect(a1, a2);
        //const de9im = relate(a1, a2) as any as Flatten.DE9IM;
        //return de9im.I2I as any as Flatten.Polygon;
        if (overlapPoly?.faces?.size > 0 && overlapPoly.area() !== 0) {
          const result: ConverterAreaIntersection = {
            area1Id: 'TODO Area1 ID',
            area2Id: 'TODO Area2 Id',
            shape: overlapPoly,
          };
          return result;
        } else {
          return null;
        }
      })
      .flat()
      .filter(item => !_.isNil(item));
    console.timeLog('checkIntersection');
    console.timeEnd('checkIntersection');
    console.log(
      'INTERSECTIONS',
      intersections
        .flatMap(is => is.shape.faces)
        .map(f => f.svg())
        .join(' '),
    );

    return intersections;
  } catch (ex) {
    console.error(ex);
  }
}

export interface ConfiguredArea {
  area: ConvertedAreaFeature;
  config: AreaConfiguration;
}

export interface MergeAreasResult {
  // area: VisualPlaneMap;
  aisles: ConvertedAisle[];
  bays: AttachedVisualBay[];
  connectivityGraph: ConnectivityGraph;
  terminalSpec?: PlaneAccessSpec;
}

export function findEntryPoint(
  //{ aisles }: VisualAreaMap,
  cg: ConnectivityGraph,
  src?: LayoutEntryPoint,
): PlaneAccessSpec | null {
  if (src) {
    console.log('has entry point: %o', src);
    const entryAislePortal = cg.aislePortals.find(
      p => p.aisleId1 === src.Id1 && p.aisleId2 === src.Id2,
    );
    if (entryAislePortal) {
      return {
        aisleId: entryAislePortal.aisleId1,
        position: savePoint(loadSegment(entryAislePortal.coords).middle()),
      };
    } else {
      const entryBayPortal = cg.bayPortals.find(
        p => p.aisleId === src.Id1 && p.bayId === src.Id2,
      );
      if (entryBayPortal) {
        return {
          aisleId: entryBayPortal.aisleId,
          position: savePoint(loadSegment(entryBayPortal.coords).middle()),
        };
      }
    }
  }

  return null;
}

export interface MergeableArea {
  area: ConvertedAreaFeature;
  transformation: LayoutTransformation;
}

function prepareAreaTransformation({
  area,
  config,
}: ConfiguredArea): MergeableArea {
  const transformParams: TransformParams = {
    center: Flatten.point(area.size[0] / 2, area.size[1] / 2),
    offset: Flatten.point(config.x, config.y),
    flipX: config.flipX,
    flipY: config.flipY,
    rotation: config.rotation,
  };

  const transformation = getTransformationMatrix(transformParams);

  return { area, transformation };
}

export function transformAndMergeAreas(
  areas: MergeableArea[],
): [VisualAisle[], AttachedVisualBay[]] {
  const mergedAisles: ConvertedAisle[] = [];
  const mergedBays: AttachedVisualBay[] = [];

  console.time('transform');

  for (const { area, transformation } of areas) {
    const aisles = area.aisles.map(a =>
      aisleFeatureToVisual(a).transform(transformation),
    );
    const bays = area.bays.map(bay => {
      const attachedBay: AttachedVisualBay = bayFeatureToVisual(bay).transform(
        transformation,
      ) as any;
      attachedBay.aisleId = bay.aisleId;
      return attachedBay;
    });
    mergedAisles.push(...aisles);
    mergedBays.push(...bays);
  }

  // const vm = new VisualPlaneMap(mergedAisles, mergedBays);

  console.timeEnd('transform');

  return [mergedAisles, mergedBays];

  // return vm;
}

export function mergeAreas(
  areas: ConfiguredArea[],
  layoutEntryPoint?: LayoutEntryPoint,
): MergeAreasResult {
  const [aisles, bays] = transformAndMergeAreas(
    areas.map(prepareAreaTransformation),
  );

  console.time('generateConnectivityGraph');
  const cg = generateConnectivityGraphWithAttachedBays(aisles, bays);
  console.timeEnd('generateConnectivityGraph');

  return {
    aisles,
    bays,
    connectivityGraph: cg,
    terminalSpec: layoutEntryPoint
      ? findEntryPoint(cg, layoutEntryPoint)
      : null,
  };
}

export function locationToFeature(
  loc: ConvertedLocation,
): ConvertedLocationFeature {
  //cloning object takes  20 % of performance , so object is mutated here
  const newLoc = loc as any as ConvertedLocationFeature;
  newLoc.locationSide = loc.baySide;
  newLoc.shape = {
    type: 'Polygon',
    coordinates: [loc.shape.vertices.map(p => [p.x, p.y])],
  };
  newLoc.portals = _.map(loc.portalSpecs, ps => ({
    type: ps.type,
    aisleId: `${loc.warehouseArea}-${ps.aisleId}`,
    position: null,
  }));

  return newLoc;
}

export function aisleToFeature(aisle: ConvertedAisle): ConvertedAisleFeature {
  const {
    id,
    title,
    stickyLeft,
    stickyRight,
    leftBays,
    rightBays,
    mergeId,
    unmergeId,
    isSpacer,
    isAutoSpacer,
  } = aisle;

  return {
    type: LayoutFeatureType.AISLE,
    id,
    title,
    stickyLeft,
    stickyRight,
    leftBays,
    rightBays,
    mergeId,
    unmergeId,
    isSpacer,
    isAutoSpacer,
    shape: {
      type: 'Polygon',
      coordinates: [aisle.shape.vertices.map(p => [p.x, p.y])],
    },
    navigable: !aisle.nonNavigable,
  };
}

export function aisleFeatureToVisual(
  aisle: ConvertedAisleFeature,
): VisualAisle {
  const {
    id,
    title,
    stickyLeft,
    stickyRight,
    leftBays,
    rightBays,
    mergeId,
    unmergeId,
    isSpacer,
    isAutoSpacer,
  } = aisle;

  let aisleType: string;
  if (isSpacer || isAutoSpacer) {
    aisleType = 'SPACER';
  } else if (isEmpty(leftBays) && isEmpty(rightBays)) {
    aisleType = 'ACCESS';
  } else {
    aisleType = 'AISLE';
  }

  return VisualAisle.load({
    id,
    title,
    nonNavigable: !aisle.navigable,
    points: aisle.shape.coordinates[0].map(p => ({ x: p[0], y: p[1] })),
    aisleType,
  });
}

export function bayToFeature(bay: ConvertedBay): ConvertedBayFeature {
  const {
    id,
    title,
    width,
    height,
    depth,
    locationOrder,
    bayType,
    position,
    frontEdge,
    aisleId,
  } = bay;
  return {
    id,
    title,
    aisleId,
    navigable: bay.hasPass,
    type: LayoutFeatureType.BAY,
    shelvingType: bay.getShelvingType(),
    position: [position.x, position.y],
    frontEdge: [frontEdge.x, frontEdge.y],

    bayAisles: [
      {
        aisle: {
          id: aisleId,
          //title:
        },
      },
    ],
    details: {
      position: _.pick(position, ['x', 'y']),
      frontEdge: _.pick(frontEdge, ['x', 'y']),
      width,
      height,
      depth,
      locationOrder,
      bayType,
      levels: bay.levels,
    },
    shape: {
      type: 'Polygon',
      coordinates: [bay.shape.vertices.map(p => [p.x, p.y])],
    },
    locations: bay.locations.map(locationToFeature),
  };
}

export function bayFeatureToVisual(bay: ConvertedBayFeature): VisualBay {
  const { id, title, position, frontEdge } = bay;
  const { width, height, depth, locationOrder, bayType, levels } = bay.details;

  return VisualBay.load({
    id,
    title,
    position: { x: position[0], y: position[1] },
    frontEdge: { x: frontEdge[0], y: frontEdge[1] },
    width,
    depth,
    locationOrder,
    hasPass: bay.navigable,
    height,
    levels,
    bayType,
  });
}

export function areaToFeature(area: ConvertedArea): ConvertedAreaFeature {
  const box = _.flatten(area.shape.box.toPoints().map(p => [p.x, p.y]));

  const { id, aislePortals, bayPortals, startAisleId, endAisleId, size } = area;
  return {
    id,
    aislePortals,
    bayPortals,
    startAisleId,
    endAisleId,
    size,

    aisles: area.aisles.map(aisleToFeature),
    bays: area.bays.map(bayToFeature),

    boundingBox: box as GeoJSON.BBox,
    outlineShape: {
      type: 'Polygon',
      coordinates: [area.shape.vertices.map(p => [p.x, p.y])],
    },
    levels: area.levels,
  };
}
