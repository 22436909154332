import { MeasurementSystem } from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
// import * as Icon from '../../../components/icons';
import i18n from '../../../i18n';
import { AisleSizeSpec, AisleSpec } from './viewer.types';

export const getAislesSpec = _.memoize((t: TFunction<'app'> = i18n.t) => {
  const specs: AisleSpec[] = [
    {
      id: 'VWA',
      title: t('Area', { ns: 'app' }),
      // icon: <Icon.AisleWidth />,
      size: _.keyBy(
        [
          {
            system: MeasurementSystem.METRIC,
            width: [380, null],
          },
          {
            system: MeasurementSystem.IMPERIAL,
            width: [140, null],
          },
        ],
        'system',
      ) as Record<MeasurementSystem, AisleSizeSpec>,
    },
    {
      id: 'WA',
      title: t('Regular Aisle', { ns: 'app' }),
      aliases: ['Regular'],
      description:
        'Wide aisle storage — Floor and racking storage — Rack bays, single or multi-position systems — Manual operation — Pedestrian pallet trucks, Counterbalance trucks or Reach trucks — Typical aisle width 2500–4500 mm — Lift height to approx. 12,000 mm — Low to high degree of space utilisation — Medium to high throughput — Low to medium investment costs',
      // icon: 'Icon.Aisle',

      size: _.keyBy(
        [
          {
            system: MeasurementSystem.METRIC,
            width: [271, 380],
          },
          {
            system: MeasurementSystem.IMPERIAL,
            width: [60, 140],
          },
        ],
        'system',
      ) as Record<MeasurementSystem, AisleSizeSpec>,
    },
    {
      id: 'NA',
      title: t('Narrow Aisle', { ns: 'app' }),
      aliases: ['Narrow'],
      // icon: 'Icon.Aisle',

      size: _.keyBy(
        [
          {
            system: MeasurementSystem.METRIC,
            width: [160, 270],
          },
          {
            system: MeasurementSystem.IMPERIAL,
            width: [48, 60],
          },
        ],
        'system',
      ) as Record<MeasurementSystem, AisleSizeSpec>,
    },
    {
      id: 'VNA',
      title: t('Very Narrow Aisle', { ns: 'app' }),
      aliases: ['VNA'],
      // icon: 'Icon.Aisle',
      size: _.keyBy(
        [
          {
            system: MeasurementSystem.METRIC,
            width: [60, 160],
          },
          {
            system: MeasurementSystem.IMPERIAL,
            width: [23, 48],
          },
        ],
        'system',
      ) as Record<MeasurementSystem, AisleSizeSpec>,
    },
    {
      id: 'UNA',
      title: t('Unknown Aisle', { ns: 'app' }),
      // icon: 'Icon.Aisle',
      size: _.keyBy(
        [
          {
            system: MeasurementSystem.METRIC,
            width: [0, 60],
          },
          {
            system: MeasurementSystem.IMPERIAL,
            width: [0, 23],
          },
        ],
        'system',
      ) as Record<MeasurementSystem, AisleSizeSpec>,
    },
  ];
  return _.keyBy(specs, s => s.id);
});
