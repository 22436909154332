import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ANALYTICS_STATUS, HUBSPOT_KEY } from '../common/environmentVariables';
import useHubspotChat from '../common/useHubspotChat';
import { appThemeSelectedId, chatState } from '../store/global.state';
import { whiteLabelConfigs } from '../store/whitelabel.types';
import * as Icon from './icons';

const HubspotChat: React.FC = () => {
  const { t } = useTranslation('app');
  const [showChatX, setShowChatX] = useState<boolean>(false);
  const chatSelectedState = useRecoilValue(chatState);
  const currentThemeId = useRecoilValue(appThemeSelectedId);

  // Always call the hook, regardless of whether chat is enabled
  const {
    isWidgetLoaded,
    hasLoaded,
    activeConversation,
    chatVisible,
    chatButtonVisible,
    chatWindowOpen,
    openHandler,
    closeHandler,
  } = useHubspotChat(HUBSPOT_KEY);

  // Find the config entry where themes.name matches currentThemeId
  const configBrandEntry = Object.entries(whiteLabelConfigs).find(
    ([_, config]) => {
      // console.log('Config theme name:', config.themes?.name);
      return config.themes?.name === currentThemeId;
    },
  );

  // console.log('configEntry', configBrandEntry);
  // console.log(
  //   'configEntry?.[1]?.support?.chatEnabled',
  //   configBrandEntry?.[1]?.support?.chatEnabled,
  // );

  // Move the enabled check before using the hook
  const isChatEnabled =
    // 1. Environment variables check
    ANALYTICS_STATUS === 'true' &&
    HUBSPOT_KEY &&
    // 2. Primary control via recoil state (from Settings)
    chatSelectedState === true &&
    // 3. Brand config check (from URL params or default config)
    configBrandEntry?.[1]?.support?.chatEnabled !== false;

  // Move the return null after all hooks are called
  if (!isChatEnabled) {
    return null;
  }

  return (
    <>
      {chatVisible && (
        <span
          data-component="ChatCloseCircle"
          onClick={closeHandler}
          className={classNames(
            'rounded-full',
            'p-4',
            'absolute bottom-[18px] right-[18px]',
            'z-menu',
            'bg-menu-active text-menu-active-text',
            'hover:scale-120 transform transition duration-200 motion-reduce:transform-none',
            'cursor-pointer',
          )}
        >
          <Icon.Close className={classNames('h-6 w-6')} />
        </span>
      )}

      <div
        dir={'ltr'}
        onClick={chatWindowOpen ? closeHandler : openHandler}
        data-component="ChatButton"
        className={classNames(
          'group',
          'ltr:[direction:ltr] ltr:[writing-mode:vertical-lr] rtl:[direction:ltr] rtl:[writing-mode:vertical-rl]',
          'absolute bottom-32 right-0',
          'z-dropdown_menu rounded-bl-md rounded-tl-md p-0.5',
          'bg-brand-chat/75 text-menu-text group-hover:bg-menu-active group-hover:text-menu-active-text',
          'backdrop-blur-lg backdrop-saturate-150 backdrop-filter',
          'flex items-center',
          'text-sm',
          'cursor-pointer',
        )}
        onMouseEnter={() => {
          setShowChatX(true);
        }}
        onMouseLeave={() => {
          setShowChatX(false);
        }}
      >
        <label
          // onClick={showChatX ? closeHandler : openHandler}
          className="flex-1 rotate-180 cursor-pointer pb-5 pl-0.5 pr-1 pt-5"
        >{t`Need Help?`}</label>
        <span
          data-component="chatAvatar"
          className={classNames(
            'absolute -bottom-6 rounded-full p-0',
            'h-9 w-9',
            'bg-app-panel-dark/60 text-menu-active',
            'border-app-panel-dark border-2',
            'border-menu-active group-hover:bg-menu-active group-hover:text-menu-active-text',
            'hover:scale-120 transform cursor-pointer transition duration-200 motion-reduce:transform-none',
          )}
        >
          <img
            alt="Chat with us"
            className={`object-contain`}
            src="/theme/img/chat-profile-photo.png"
          ></img>
        </span>
      </div>
    </>
  );
};
export default HubspotChat;
