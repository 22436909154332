import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TwTheme } from '../../../Tw';
import { viewerSelectedPlaneId } from '../../layout/viewer/store/viewer.state';
import {
  optimisationSelectedPicklistId,
  optimisationSelectedRoutePart,
} from '../store/optimisation.state';
import { SimulationPicklistRoute } from './SimulationRouteLayer';
import SimulationRouteLayerBase from './SimulationRouteLayerBase';

const routeColor = TwTheme.extend.colors.route;

const OptimisationRouteLayer: React.FC = () => {
  const selectedPicklistId = useRecoilValue(optimisationSelectedPicklistId);
  const routePart = useRecoilValue(optimisationSelectedRoutePart);
  const color = routePart ? routeColor.selected : null;
  if (_.isNil(selectedPicklistId)) return null;
  return (
    <SimulationPicklistRoute
      key={selectedPicklistId}
      picklistId={selectedPicklistId}
      color={color}
      animate={_.isNil(routePart)}
      wpType="after"
    />
  );
};

export default OptimisationRouteLayer;

export const OptimisationRoutePartLayer: React.FC = () => {
  const route = useRecoilValue(optimisationSelectedRoutePart);
  const planeId = useRecoilValue(viewerSelectedPlaneId);

  if (!route || route.areaId !== planeId) return null;
  return (
    <SimulationRouteLayerBase
      waypoints={route.waypoints}
      color={routeColor.hover}
      wpType="after"
      animate={true}
    />
  );
};
